export const AhsLogoNotDesk = () => (
    <svg
        width="120px"
        height="24px"
        viewBox="0 0 235 39"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>FC2FECA7-EDF3-4D14-9E05-A34C1ECB69FC@2x</title>
        <g
            id="Homepage"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="(New)"
                transform="translate(-257.000000, -23.000000)"
                fillRule="nonzero"
            >
                <g
                    id="FD-pro-horizontal-rgb"
                    transform="translate(257.000000, 23.000000)"
                >
                    <path
                        d="M70,29.5 L64.5,29.5 L64.5,19.1 C64.5,16.1 62.1,13.7 59.1,13.7 C56.1,13.7 53.7,16.1 53.7,19.1 L53.7,29.5 L48.2,29.5 L48.2,19.1 C48.2,13.1 53.1,8.2 59.1,8.2 C65.1,8.2 70,13.1 70,19.1 L70,29.5 Z"
                        id="fullLogo_00000088813674705456284840000005015462132986742203_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M35.4,30.1 C29.4,30.1 24.5,25.2 24.5,19.2 C24.5,13.2 29.4,8.3 35.4,8.3 C41.4,8.3 46.3,13.2 46.3,19.2 C46.4,25.2 41.4,30.1 35.4,30.1 Z M35.4,13.7 C32.4,13.7 30,16.1 30,19.1 C30,22.1 32.4,24.5 35.4,24.5 C38.4,24.5 40.8,22.1 40.8,19.1 C40.9,16.1 38.4,13.7 35.4,13.7 Z"
                        id="fullLogo_00000121991911101445712580000008359790423413544099_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M18.7,29.5 L13.2,29.5 L13.2,19.1 C13.2,13.1 18.1,8.2 24.1,8.2 L24.1,13.7 C21.1,13.7 18.7,16.1 18.7,19.1 L18.7,29.5 Z"
                        id="fullLogo_00000087403287560299571430000010779358621726009529_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M10.9,5.5 L12,5.5 L12,0 L10.9,0 C4.9,0 -1.42108547e-14,4.9 -1.42108547e-14,10.9 C-1.42108547e-14,10.9 -1.42108547e-14,21.4 -1.42108547e-14,21.8 L-1.42108547e-14,29.5 L5.5,29.5 L5.5,21.8 C5.5,18.8 7.9,16.4 10.9,16.4 L12,16.4 L12,10.9 L10.9,10.9 C8.9,10.9 7.1,11.4 5.5,12.4 L5.5,11 C5.4,8 7.9,5.5 10.9,5.5 Z"
                        id="fullLogo_00000071519078952731087050000013002328951016509596_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M77.3,19.1 L77.3,14.1 L82.1,14.1 L82.1,8.6 L77.3,8.6 L77.3,3 L71.8,3 L71.8,19.6 L71.8,19.6 C72.1,25.4 76.9,30 82.8,30 L82.8,24.5 C79.8,24.6 77.3,22.1 77.3,19.1 Z"
                        id="fullLogo_00000147199494275708152370000014588039931546938008_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M63,22.4 C63,23.4 62.2,24.1 61.3,24.1 C60.3,24.1 59.6,23.3 59.6,22.4 C59.6,21.4 60.4,20.7 61.3,20.7 C62.2,20.7 63,21.5 63,22.4 Z"
                        id="fullLogo_00000037669830521098777050000005153614873094413497_"
                        fill="#91877F"
                    ></path>
                    <path
                        d="M160.4,29.5 L154.9,29.5 L154.9,19.1 C154.9,13.1 159.8,8.2 165.8,8.2 L165.8,13.7 C162.8,13.7 160.4,16.1 160.4,19.1 L160.4,29.5 Z"
                        id="fullLogo_00000016794920341032686790000005028435755875908770_"
                        fill="#91877F"
                    ></path>
                    <path
                        d="M106.2,3 L100.7,3 L100.7,9.6 C99.1,8.7 97.2,8.1 95.3,8.1 C89.3,8.1 84.4,13 84.4,19 C84.4,25 89.3,29.9 95.3,29.9 C101.1,29.9 105.9,25.3 106.2,19.5 L106.2,19.5 L106.2,3 Z M95.3,24.6 C92.3,24.6 89.9,22.2 89.9,19.2 C89.9,16.2 92.3,13.8 95.3,13.8 C98.3,13.8 100.7,16.2 100.7,19.2 L100.7,19.4 C100.7,22.2 98.3,24.6 95.3,24.6 Z"
                        id="fullLogo_00000163069727821245273670000016677545163784744596_"
                        fill="#91877F"
                    ></path>
                    <path
                        d="M119,30.1 C113,30.1 108.1,25.2 108.1,19.2 C108.1,13.2 113,8.3 119,8.3 C125,8.3 129.9,13.2 129.9,19.2 C129.9,25.2 125,30.1 119,30.1 Z M119,13.7 C116,13.7 113.6,16.1 113.6,19.1 C113.6,22.1 116,24.5 119,24.5 C122,24.5 124.4,22.1 124.4,19.1 C124.4,16.1 122,13.7 119,13.7 Z"
                        id="fullLogo_00000144310770530875098440000004865123334855286656_"
                        fill="#91877F"
                    ></path>
                    <path
                        d="M142.2,30.1 C136.2,30.1 131.3,25.2 131.3,19.2 C131.3,13.2 136.2,8.3 142.2,8.3 C148.2,8.3 153.1,13.2 153.1,19.2 C153.1,25.2 148.2,30.1 142.2,30.1 Z M142.2,13.7 C139.2,13.7 136.8,16.1 136.8,19.1 C136.8,22.1 139.2,24.5 142.2,24.5 C145.2,24.5 147.6,22.1 147.6,19.1 C147.6,16.1 145.2,13.7 142.2,13.7 Z"
                        id="fullLogo_00000101085423662126531210000005939265022201759131_"
                        fill="#91877F"
                    ></path>
                    <path
                        d="M164,27.8 L164,27.8 C164,26.8 164.8,25.9 165.9,25.9 C167,25.9 167.8,26.8 167.8,27.8 L167.8,27.8 C167.8,28.8 167,29.7 165.9,29.7 C164.8,29.7 164,28.8 164,27.8 Z M167.5,27.8 L167.5,27.8 C167.5,26.9 166.8,26.1 165.8,26.1 C164.9,26.1 164.1,26.9 164.1,27.8 L164.1,27.8 C164.1,28.7 164.8,29.5 165.8,29.5 C166.8,29.5 167.5,28.7 167.5,27.8 Z M165.1,26.8 L166,26.8 C166.4,26.8 166.7,27 166.7,27.4 C166.7,27.7 166.5,27.9 166.3,28 L166.8,28.7 L166.4,28.7 L165.9,28 L165.5,28 L165.5,28.7 L165.1,28.7 L165.1,26.8 L165.1,26.8 Z M165.9,27.7 C166.2,27.7 166.3,27.6 166.3,27.4 C166.3,27.2 166.2,27.1 165.9,27.1 L165.4,27.1 L165.4,27.7 L165.9,27.7 L165.9,27.7 Z"
                        id="Shape"
                        fill="#91877F"
                    ></path>
                    <path
                        d="M223.7,30.1 C217.7,30.1 212.8,25.2 212.8,19.2 C212.8,13.2 217.7,8.3 223.7,8.3 C229.7,8.3 234.6,13.2 234.6,19.2 C234.7,25.2 229.8,30.1 223.7,30.1 Z M223.7,13.7 C220.7,13.7 218.3,16.1 218.3,19.1 C218.3,22.1 220.7,24.5 223.7,24.5 C226.7,24.5 229.1,22.1 229.1,19.1 C229.2,16.1 226.7,13.7 223.7,13.7 Z"
                        id="fullLogo_00000154399445123781994070000005528087344949592708_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M207,29.5 L201.5,29.5 L201.5,19.1 C201.5,13.1 206.4,8.2 212.4,8.2 L212.4,13.7 C209.4,13.7 207,16.1 207,19.1 L207,29.5 Z"
                        id="fullLogo_00000049209824697901995120000016887934546703487117_"
                        fill="#F34113"
                    ></path>
                    <path
                        d="M177.6,38.2 L183.1,38.2 L183.1,28.6 C184.7,29.5 186.6,30.1 188.5,30.1 C194.5,30.1 199.4,25.2 199.4,19.2 C199.4,13.2 194.5,8.3 188.5,8.3 C182.7,8.3 177.9,12.9 177.6,18.7 L177.6,18.7 L177.6,38.2 Z M188.6,13.7 C191.6,13.7 194,16.1 194,19.1 C194,22.1 191.6,24.5 188.6,24.5 C185.6,24.5 183.2,22.1 183.2,19.1 L183.2,18.9 C183.2,16.1 185.6,13.7 188.6,13.7 Z"
                        id="fullLogo_00000071560414520798161620000000074462707013965448_"
                        fill="#F34113"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);
