import { odmarketservicespb } from '../../protobuf/models/odmarketservicespb';
import { getBaseServiceFactory, getProtoBase64Value } from '../base';
import { endpoints, SKU_CONSTANTS } from '../../shared/constants';
import { ISurCharges } from '../../interfaces/zesty';
import * as Sentry from '@sentry/nextjs';
interface ISkusRequest {
    marketIds: string[];
    jobTypeId: string;
    userRole: string;
    serviceTypeIds: string[];
}

export interface ISku {
    skuLegacyId: string;
    skuId: string;
    name: string;
    skuPrice: number;
    shortDescription: string;
    keywords: Array<string>;
    skuCategoryLegacyId: string;
    skuCategoryName: string;
    serviceTypeId: string;
    serviceTypeName: string;
}
export async function getSKUSClient(request: {
    marketIds: string[];
    serviceTypeIds: string[];
}) {
    const { userRole, residentialRepairID } = SKU_CONSTANTS;
    const skusRequest: ISkusRequest = {
        ...request,
        userRole,
        jobTypeId: residentialRepairID,
    };

    const base64Params = getProtoBase64Value(
        skusRequest,
        odmarketservicespb.AvailableSkusQueryRequest,
    );

    const getSKUSServiceClient = getBaseServiceFactory({
        protoRequestModel: odmarketservicespb.AvailableSkusQueryRequest,
        protoResponseModel: odmarketservicespb.AvailableSkusResponse,
        endpoint: endpoints.skusClient,
    });
    return new Promise<Array<ISku> | []>((resolve, reject) => {
        getSKUSServiceClient({ query: { proto_body: base64Params } })
            .then((response) => {
                if (response?.skuPage) {
                    const {
                        skuPage: { skus },
                    } = response;
                    resolve(skus as unknown as Array<ISku>);
                }
                resolve([]);
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    extra: {
                        offender: 'getSKUSClient',
                    },
                });
                reject([]);
            });
    });
}

export function matchSectionSurCharges(
    fetchedSkus: Array<ISku>,
    sectionInfo: ISurCharges,
) {
    const sectionSurCharges: ISurCharges = {
        specialsSectionSurCharge: '',
        hiwSectionSurCharge: '',
    };
    for (const property in sectionInfo) {
        const matched = fetchedSkus.find(
            (sku) =>
                sku.skuLegacyId == sectionInfo[property as keyof ISurCharges],
        );
        if (matched) {
            sectionSurCharges[property as keyof ISurCharges] = Math.floor(
                (matched.skuPrice as number) / 100,
            ).toString();
        }
    }
    return sectionSurCharges;
}
