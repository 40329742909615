import { APPT_CANCEL_OTHER_REASON_ID, endpoints } from '../../shared/constants';
import * as Sentry from '@sentry/nextjs';
import { getLegacyHeader } from '../base';
import { ITimeSlot } from '../../interfaces/booking';
import { format } from 'date-fns';

export interface IRescheduleApptArgs {
    appointmentId: string;
    date: Date;
    timeSlot: ITimeSlot;
    userLegacyId: string;
}

export interface ICancelApptArgs {
    appointmentId: string;
    reasonId: string;
    otherReasonText?: string;
    userLegacyId: string;
}

export async function getAppointment({
    appointmentId,
    userLegacyId,
}: {
    appointmentId: string;
    userLegacyId: string;
}) {
    const payload = {
        method: 'getAppointment',
        id: 1,
        jsonrpc: '2.0',
        params: [appointmentId],
    };

    try {
        const response = await fetch(endpoints.appointment.getAppointment, {
            method: 'POST',
            headers: getLegacyHeader(userLegacyId),
            body: JSON.stringify(payload),
        });
        const responseData = await response.json();
        return responseData.result;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getAppointment',
            },
        });
    }
}

export async function getAppointments({
    jobId,
    userLegacyId,
    statuses,
}: {
    jobId: string;
    userLegacyId: string;
    statuses: string[];
}) {
    const payload = {
        jsonrpc: '2.0',
        method: 'getAppointments',
        id: 1,
        params: [
            {
                job: jobId,
                status: statuses,
            },
        ],
    };

    try {
        const response = await fetch(endpoints.appointment.getAppointments, {
            method: 'POST',
            headers: getLegacyHeader(userLegacyId),
            body: JSON.stringify(payload),
        });
        const responseData = await response.json();
        return responseData;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getAppointments',
            },
        });
    }
}

export async function rescheduleAppointment(req: IRescheduleApptArgs) {
    const payload = {
        jsonrpc: '2.0',
        params: [
            {
                booking: {
                    id: req.appointmentId,
                    appointmentTime: `${format(req.date, 'yyyy-MM-dd')} ${
                        req.timeSlot.startTime
                    }`,
                    allocationTypeObj: '5',
                },
                request: {
                    type: '3',
                },
                jobType: '1',
            },
        ],
        method: 'rescheduleAppointment',
        id: 1,
    };

    try {
        await fetch(endpoints.appointment.reschedule, {
            method: 'POST',
            headers: getLegacyHeader(req.userLegacyId),
            body: JSON.stringify(payload),
        });
    } catch (err) {
        console.log('in err', err);
        Sentry.captureException(err, {
            extra: {
                offender: 'rescheduleAppointment',
            },
        });
    }
}

export async function cancelAppointment(req: ICancelApptArgs) {
    const otherReasonText =
        req.reasonId === APPT_CANCEL_OTHER_REASON_ID
            ? req.otherReasonText || ''
            : '';

    const payload = {
        jsonrpc: '2.0',
        params: [
            req.appointmentId,
            null,
            null,
            Number(req.reasonId),
            otherReasonText,
        ],
        method: 'cancelAppointment',
        id: 1,
    };

    try {
        await fetch(endpoints.appointment.cancel, {
            method: 'POST',
            headers: getLegacyHeader(req.userLegacyId),
            body: JSON.stringify(payload),
        });
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'cancelAppointment',
            },
        });
    }
}
