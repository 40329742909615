import { LegalLinksKeys } from '../../interfaces/shared';

type ILegalLinks = {
    [key in LegalLinksKeys]: {
        zestySlug: string;
        pageTitle: string;
        pageDescription: string;
    };
};

export const LEGAL_LINKS: ILegalLinks = {
    'terms-of-use': {
        zestySlug: 'terms-of-use',
        pageTitle: 'Terms of Use',
        pageDescription: 'Terms of Use',
    },
    'frontdoorpro-guarantee': {
        zestySlug: 'guarantee-agreement',
        pageTitle: 'ProConnect Guarantee',
        pageDescription: 'ProConnect Guarantee',
    },
    'frontdoorpro-agreement': {
        zestySlug: 'membership-agreement',
        pageTitle: 'Agreement',
        pageDescription:
            'ProConnect Membership Benefits and Privileges Agreement',
    },
    'low-price-guarantee': {
        zestySlug: 'low-price-guarantee',
        pageTitle: 'Low Price Guarantee',
        pageDescription: 'Low Price Guarantee',
    },
    'privacy-policy': {
        zestySlug: 'privacy-policy',
        pageTitle: 'ProConnect Consumer Privacy Policy',
        pageDescription: 'ProConnect Consumer Privacy Policy',
    },
    'contractor-licenses': {
        zestySlug: 'licenses',
        pageTitle: 'Licensing Information',
        pageDescription: 'Licensing Information',
    },
};

export const privacyPolicyLink =
    'https://www.frontdoor.com/legal/privacy-policy';
