/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const odmarketservicespb = $root.odmarketservicespb = (() => {

    /**
     * Namespace odmarketservicespb.
     * @exports odmarketservicespb
     * @namespace
     */
    const odmarketservicespb = {};

    odmarketservicespb.MarketByZipCodeResponse = (function() {

        /**
         * Properties of a MarketByZipCodeResponse.
         * @memberof odmarketservicespb
         * @interface IMarketByZipCodeResponse
         * @property {common.Status|null} [status] MarketByZipCodeResponse status
         * @property {odmarketservicespb.IMarket|null} [market] MarketByZipCodeResponse market
         * @property {odmarketservicespb.IMarket|null} [superMarket] MarketByZipCodeResponse superMarket
         * @property {Array.<odmarketservicespb.IServiceType>|null} [serviceTypes] MarketByZipCodeResponse serviceTypes
         */

        /**
         * Constructs a new MarketByZipCodeResponse.
         * @memberof odmarketservicespb
         * @classdesc Represents a MarketByZipCodeResponse.
         * @implements IMarketByZipCodeResponse
         * @constructor
         * @param {odmarketservicespb.IMarketByZipCodeResponse=} [properties] Properties to set
         */
        function MarketByZipCodeResponse(properties) {
            this.serviceTypes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MarketByZipCodeResponse status.
         * @member {common.Status} status
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @instance
         */
        MarketByZipCodeResponse.prototype.status = 0;

        /**
         * MarketByZipCodeResponse market.
         * @member {odmarketservicespb.IMarket|null|undefined} market
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @instance
         */
        MarketByZipCodeResponse.prototype.market = null;

        /**
         * MarketByZipCodeResponse superMarket.
         * @member {odmarketservicespb.IMarket|null|undefined} superMarket
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @instance
         */
        MarketByZipCodeResponse.prototype.superMarket = null;

        /**
         * MarketByZipCodeResponse serviceTypes.
         * @member {Array.<odmarketservicespb.IServiceType>} serviceTypes
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @instance
         */
        MarketByZipCodeResponse.prototype.serviceTypes = $util.emptyArray;

        /**
         * Creates a new MarketByZipCodeResponse instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {odmarketservicespb.IMarketByZipCodeResponse=} [properties] Properties to set
         * @returns {odmarketservicespb.MarketByZipCodeResponse} MarketByZipCodeResponse instance
         */
        MarketByZipCodeResponse.create = function create(properties) {
            return new MarketByZipCodeResponse(properties);
        };

        /**
         * Encodes the specified MarketByZipCodeResponse message. Does not implicitly {@link odmarketservicespb.MarketByZipCodeResponse.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {odmarketservicespb.IMarketByZipCodeResponse} message MarketByZipCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarketByZipCodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.market != null && Object.hasOwnProperty.call(message, "market"))
                $root.odmarketservicespb.Market.encode(message.market, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.superMarket != null && Object.hasOwnProperty.call(message, "superMarket"))
                $root.odmarketservicespb.Market.encode(message.superMarket, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.serviceTypes != null && message.serviceTypes.length)
                for (let i = 0; i < message.serviceTypes.length; ++i)
                    $root.odmarketservicespb.ServiceType.encode(message.serviceTypes[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MarketByZipCodeResponse message, length delimited. Does not implicitly {@link odmarketservicespb.MarketByZipCodeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {odmarketservicespb.IMarketByZipCodeResponse} message MarketByZipCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarketByZipCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MarketByZipCodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.MarketByZipCodeResponse} MarketByZipCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarketByZipCodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.MarketByZipCodeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.market = $root.odmarketservicespb.Market.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.superMarket = $root.odmarketservicespb.Market.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.serviceTypes && message.serviceTypes.length))
                        message.serviceTypes = [];
                    message.serviceTypes.push($root.odmarketservicespb.ServiceType.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MarketByZipCodeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.MarketByZipCodeResponse} MarketByZipCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarketByZipCodeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MarketByZipCodeResponse message.
         * @function verify
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MarketByZipCodeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.market != null && message.hasOwnProperty("market")) {
                let error = $root.odmarketservicespb.Market.verify(message.market);
                if (error)
                    return "market." + error;
            }
            if (message.superMarket != null && message.hasOwnProperty("superMarket")) {
                let error = $root.odmarketservicespb.Market.verify(message.superMarket);
                if (error)
                    return "superMarket." + error;
            }
            if (message.serviceTypes != null && message.hasOwnProperty("serviceTypes")) {
                if (!Array.isArray(message.serviceTypes))
                    return "serviceTypes: array expected";
                for (let i = 0; i < message.serviceTypes.length; ++i) {
                    let error = $root.odmarketservicespb.ServiceType.verify(message.serviceTypes[i]);
                    if (error)
                        return "serviceTypes." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MarketByZipCodeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.MarketByZipCodeResponse} MarketByZipCodeResponse
         */
        MarketByZipCodeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.MarketByZipCodeResponse)
                return object;
            let message = new $root.odmarketservicespb.MarketByZipCodeResponse();
            switch (object.status) {
            case "STATUS_UNDEFINED":
            case 0:
                message.status = 0;
                break;
            case "SUCCESS":
            case 1:
                message.status = 1;
                break;
            case "FAIL":
            case 2:
                message.status = 2;
                break;
            }
            if (object.market != null) {
                if (typeof object.market !== "object")
                    throw TypeError(".odmarketservicespb.MarketByZipCodeResponse.market: object expected");
                message.market = $root.odmarketservicespb.Market.fromObject(object.market);
            }
            if (object.superMarket != null) {
                if (typeof object.superMarket !== "object")
                    throw TypeError(".odmarketservicespb.MarketByZipCodeResponse.superMarket: object expected");
                message.superMarket = $root.odmarketservicespb.Market.fromObject(object.superMarket);
            }
            if (object.serviceTypes) {
                if (!Array.isArray(object.serviceTypes))
                    throw TypeError(".odmarketservicespb.MarketByZipCodeResponse.serviceTypes: array expected");
                message.serviceTypes = [];
                for (let i = 0; i < object.serviceTypes.length; ++i) {
                    if (typeof object.serviceTypes[i] !== "object")
                        throw TypeError(".odmarketservicespb.MarketByZipCodeResponse.serviceTypes: object expected");
                    message.serviceTypes[i] = $root.odmarketservicespb.ServiceType.fromObject(object.serviceTypes[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MarketByZipCodeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @static
         * @param {odmarketservicespb.MarketByZipCodeResponse} message MarketByZipCodeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MarketByZipCodeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.serviceTypes = [];
            if (options.defaults) {
                object.status = options.enums === String ? "STATUS_UNDEFINED" : 0;
                object.market = null;
                object.superMarket = null;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.common.Status[message.status] : message.status;
            if (message.market != null && message.hasOwnProperty("market"))
                object.market = $root.odmarketservicespb.Market.toObject(message.market, options);
            if (message.superMarket != null && message.hasOwnProperty("superMarket"))
                object.superMarket = $root.odmarketservicespb.Market.toObject(message.superMarket, options);
            if (message.serviceTypes && message.serviceTypes.length) {
                object.serviceTypes = [];
                for (let j = 0; j < message.serviceTypes.length; ++j)
                    object.serviceTypes[j] = $root.odmarketservicespb.ServiceType.toObject(message.serviceTypes[j], options);
            }
            return object;
        };

        /**
         * Converts this MarketByZipCodeResponse to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.MarketByZipCodeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MarketByZipCodeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MarketByZipCodeResponse;
    })();

    odmarketservicespb.ServiceTypesByMarketResponse = (function() {

        /**
         * Properties of a ServiceTypesByMarketResponse.
         * @memberof odmarketservicespb
         * @interface IServiceTypesByMarketResponse
         * @property {common.Status|null} [status] ServiceTypesByMarketResponse status
         * @property {Array.<odmarketservicespb.IServiceType>|null} [serviceTypes] ServiceTypesByMarketResponse serviceTypes
         */

        /**
         * Constructs a new ServiceTypesByMarketResponse.
         * @memberof odmarketservicespb
         * @classdesc Represents a ServiceTypesByMarketResponse.
         * @implements IServiceTypesByMarketResponse
         * @constructor
         * @param {odmarketservicespb.IServiceTypesByMarketResponse=} [properties] Properties to set
         */
        function ServiceTypesByMarketResponse(properties) {
            this.serviceTypes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceTypesByMarketResponse status.
         * @member {common.Status} status
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @instance
         */
        ServiceTypesByMarketResponse.prototype.status = 0;

        /**
         * ServiceTypesByMarketResponse serviceTypes.
         * @member {Array.<odmarketservicespb.IServiceType>} serviceTypes
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @instance
         */
        ServiceTypesByMarketResponse.prototype.serviceTypes = $util.emptyArray;

        /**
         * Creates a new ServiceTypesByMarketResponse instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {odmarketservicespb.IServiceTypesByMarketResponse=} [properties] Properties to set
         * @returns {odmarketservicespb.ServiceTypesByMarketResponse} ServiceTypesByMarketResponse instance
         */
        ServiceTypesByMarketResponse.create = function create(properties) {
            return new ServiceTypesByMarketResponse(properties);
        };

        /**
         * Encodes the specified ServiceTypesByMarketResponse message. Does not implicitly {@link odmarketservicespb.ServiceTypesByMarketResponse.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {odmarketservicespb.IServiceTypesByMarketResponse} message ServiceTypesByMarketResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceTypesByMarketResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.serviceTypes != null && message.serviceTypes.length)
                for (let i = 0; i < message.serviceTypes.length; ++i)
                    $root.odmarketservicespb.ServiceType.encode(message.serviceTypes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ServiceTypesByMarketResponse message, length delimited. Does not implicitly {@link odmarketservicespb.ServiceTypesByMarketResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {odmarketservicespb.IServiceTypesByMarketResponse} message ServiceTypesByMarketResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceTypesByMarketResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServiceTypesByMarketResponse message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.ServiceTypesByMarketResponse} ServiceTypesByMarketResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceTypesByMarketResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.ServiceTypesByMarketResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    if (!(message.serviceTypes && message.serviceTypes.length))
                        message.serviceTypes = [];
                    message.serviceTypes.push($root.odmarketservicespb.ServiceType.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServiceTypesByMarketResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.ServiceTypesByMarketResponse} ServiceTypesByMarketResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceTypesByMarketResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServiceTypesByMarketResponse message.
         * @function verify
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServiceTypesByMarketResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.serviceTypes != null && message.hasOwnProperty("serviceTypes")) {
                if (!Array.isArray(message.serviceTypes))
                    return "serviceTypes: array expected";
                for (let i = 0; i < message.serviceTypes.length; ++i) {
                    let error = $root.odmarketservicespb.ServiceType.verify(message.serviceTypes[i]);
                    if (error)
                        return "serviceTypes." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ServiceTypesByMarketResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.ServiceTypesByMarketResponse} ServiceTypesByMarketResponse
         */
        ServiceTypesByMarketResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.ServiceTypesByMarketResponse)
                return object;
            let message = new $root.odmarketservicespb.ServiceTypesByMarketResponse();
            switch (object.status) {
            case "STATUS_UNDEFINED":
            case 0:
                message.status = 0;
                break;
            case "SUCCESS":
            case 1:
                message.status = 1;
                break;
            case "FAIL":
            case 2:
                message.status = 2;
                break;
            }
            if (object.serviceTypes) {
                if (!Array.isArray(object.serviceTypes))
                    throw TypeError(".odmarketservicespb.ServiceTypesByMarketResponse.serviceTypes: array expected");
                message.serviceTypes = [];
                for (let i = 0; i < object.serviceTypes.length; ++i) {
                    if (typeof object.serviceTypes[i] !== "object")
                        throw TypeError(".odmarketservicespb.ServiceTypesByMarketResponse.serviceTypes: object expected");
                    message.serviceTypes[i] = $root.odmarketservicespb.ServiceType.fromObject(object.serviceTypes[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ServiceTypesByMarketResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @static
         * @param {odmarketservicespb.ServiceTypesByMarketResponse} message ServiceTypesByMarketResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceTypesByMarketResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.serviceTypes = [];
            if (options.defaults)
                object.status = options.enums === String ? "STATUS_UNDEFINED" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.common.Status[message.status] : message.status;
            if (message.serviceTypes && message.serviceTypes.length) {
                object.serviceTypes = [];
                for (let j = 0; j < message.serviceTypes.length; ++j)
                    object.serviceTypes[j] = $root.odmarketservicespb.ServiceType.toObject(message.serviceTypes[j], options);
            }
            return object;
        };

        /**
         * Converts this ServiceTypesByMarketResponse to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.ServiceTypesByMarketResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceTypesByMarketResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServiceTypesByMarketResponse;
    })();

    odmarketservicespb.Market = (function() {

        /**
         * Properties of a Market.
         * @memberof odmarketservicespb
         * @interface IMarket
         * @property {string|null} [marketId] Market marketId
         * @property {number|null} [marketLegacyId] Market marketLegacyId
         * @property {string|null} [marketName] Market marketName
         */

        /**
         * Constructs a new Market.
         * @memberof odmarketservicespb
         * @classdesc Represents a Market.
         * @implements IMarket
         * @constructor
         * @param {odmarketservicespb.IMarket=} [properties] Properties to set
         */
        function Market(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Market marketId.
         * @member {string} marketId
         * @memberof odmarketservicespb.Market
         * @instance
         */
        Market.prototype.marketId = "";

        /**
         * Market marketLegacyId.
         * @member {number} marketLegacyId
         * @memberof odmarketservicespb.Market
         * @instance
         */
        Market.prototype.marketLegacyId = 0;

        /**
         * Market marketName.
         * @member {string} marketName
         * @memberof odmarketservicespb.Market
         * @instance
         */
        Market.prototype.marketName = "";

        /**
         * Creates a new Market instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.Market
         * @static
         * @param {odmarketservicespb.IMarket=} [properties] Properties to set
         * @returns {odmarketservicespb.Market} Market instance
         */
        Market.create = function create(properties) {
            return new Market(properties);
        };

        /**
         * Encodes the specified Market message. Does not implicitly {@link odmarketservicespb.Market.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.Market
         * @static
         * @param {odmarketservicespb.IMarket} message Market message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Market.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.marketId != null && Object.hasOwnProperty.call(message, "marketId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.marketId);
            if (message.marketLegacyId != null && Object.hasOwnProperty.call(message, "marketLegacyId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.marketLegacyId);
            if (message.marketName != null && Object.hasOwnProperty.call(message, "marketName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.marketName);
            return writer;
        };

        /**
         * Encodes the specified Market message, length delimited. Does not implicitly {@link odmarketservicespb.Market.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.Market
         * @static
         * @param {odmarketservicespb.IMarket} message Market message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Market.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Market message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.Market
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.Market} Market
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Market.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.Market();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.marketLegacyId = reader.int32();
                    break;
                case 3:
                    message.marketName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Market message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.Market
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.Market} Market
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Market.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Market message.
         * @function verify
         * @memberof odmarketservicespb.Market
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Market.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.marketId != null && message.hasOwnProperty("marketId"))
                if (!$util.isString(message.marketId))
                    return "marketId: string expected";
            if (message.marketLegacyId != null && message.hasOwnProperty("marketLegacyId"))
                if (!$util.isInteger(message.marketLegacyId))
                    return "marketLegacyId: integer expected";
            if (message.marketName != null && message.hasOwnProperty("marketName"))
                if (!$util.isString(message.marketName))
                    return "marketName: string expected";
            return null;
        };

        /**
         * Creates a Market message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.Market
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.Market} Market
         */
        Market.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.Market)
                return object;
            let message = new $root.odmarketservicespb.Market();
            if (object.marketId != null)
                message.marketId = String(object.marketId);
            if (object.marketLegacyId != null)
                message.marketLegacyId = object.marketLegacyId | 0;
            if (object.marketName != null)
                message.marketName = String(object.marketName);
            return message;
        };

        /**
         * Creates a plain object from a Market message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.Market
         * @static
         * @param {odmarketservicespb.Market} message Market
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Market.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.marketId = "";
                object.marketLegacyId = 0;
                object.marketName = "";
            }
            if (message.marketId != null && message.hasOwnProperty("marketId"))
                object.marketId = message.marketId;
            if (message.marketLegacyId != null && message.hasOwnProperty("marketLegacyId"))
                object.marketLegacyId = message.marketLegacyId;
            if (message.marketName != null && message.hasOwnProperty("marketName"))
                object.marketName = message.marketName;
            return object;
        };

        /**
         * Converts this Market to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.Market
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Market.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Market;
    })();

    odmarketservicespb.ServiceType = (function() {

        /**
         * Properties of a ServiceType.
         * @memberof odmarketservicespb
         * @interface IServiceType
         * @property {string|null} [serviceTypeId] ServiceType serviceTypeId
         * @property {string|null} [serviceTypeName] ServiceType serviceTypeName
         * @property {number|null} [serviceTypeLegacyId] ServiceType serviceTypeLegacyId
         * @property {boolean|null} [isVisible] ServiceType isVisible
         * @property {boolean|null} [isComingSoon] ServiceType isComingSoon
         * @property {odmarketservicespb.BookingType|null} [bookingType] ServiceType bookingType
         * @property {number|null} [appointmentDuration] ServiceType appointmentDuration
         */

        /**
         * Constructs a new ServiceType.
         * @memberof odmarketservicespb
         * @classdesc Represents a ServiceType.
         * @implements IServiceType
         * @constructor
         * @param {odmarketservicespb.IServiceType=} [properties] Properties to set
         */
        function ServiceType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceType serviceTypeId.
         * @member {string} serviceTypeId
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.serviceTypeId = "";

        /**
         * ServiceType serviceTypeName.
         * @member {string} serviceTypeName
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.serviceTypeName = "";

        /**
         * ServiceType serviceTypeLegacyId.
         * @member {number} serviceTypeLegacyId
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.serviceTypeLegacyId = 0;

        /**
         * ServiceType isVisible.
         * @member {boolean} isVisible
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.isVisible = false;

        /**
         * ServiceType isComingSoon.
         * @member {boolean} isComingSoon
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.isComingSoon = false;

        /**
         * ServiceType bookingType.
         * @member {odmarketservicespb.BookingType} bookingType
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.bookingType = 0;

        /**
         * ServiceType appointmentDuration.
         * @member {number} appointmentDuration
         * @memberof odmarketservicespb.ServiceType
         * @instance
         */
        ServiceType.prototype.appointmentDuration = 0;

        /**
         * Creates a new ServiceType instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {odmarketservicespb.IServiceType=} [properties] Properties to set
         * @returns {odmarketservicespb.ServiceType} ServiceType instance
         */
        ServiceType.create = function create(properties) {
            return new ServiceType(properties);
        };

        /**
         * Encodes the specified ServiceType message. Does not implicitly {@link odmarketservicespb.ServiceType.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {odmarketservicespb.IServiceType} message ServiceType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.serviceTypeId != null && Object.hasOwnProperty.call(message, "serviceTypeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.serviceTypeId);
            if (message.serviceTypeName != null && Object.hasOwnProperty.call(message, "serviceTypeName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.serviceTypeName);
            if (message.serviceTypeLegacyId != null && Object.hasOwnProperty.call(message, "serviceTypeLegacyId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.serviceTypeLegacyId);
            if (message.isVisible != null && Object.hasOwnProperty.call(message, "isVisible"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVisible);
            if (message.isComingSoon != null && Object.hasOwnProperty.call(message, "isComingSoon"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isComingSoon);
            if (message.bookingType != null && Object.hasOwnProperty.call(message, "bookingType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.bookingType);
            if (message.appointmentDuration != null && Object.hasOwnProperty.call(message, "appointmentDuration"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.appointmentDuration);
            return writer;
        };

        /**
         * Encodes the specified ServiceType message, length delimited. Does not implicitly {@link odmarketservicespb.ServiceType.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {odmarketservicespb.IServiceType} message ServiceType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceType.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServiceType message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.ServiceType} ServiceType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.ServiceType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.serviceTypeId = reader.string();
                    break;
                case 2:
                    message.serviceTypeName = reader.string();
                    break;
                case 3:
                    message.serviceTypeLegacyId = reader.int32();
                    break;
                case 4:
                    message.isVisible = reader.bool();
                    break;
                case 5:
                    message.isComingSoon = reader.bool();
                    break;
                case 6:
                    message.bookingType = reader.int32();
                    break;
                case 7:
                    message.appointmentDuration = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServiceType message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.ServiceType} ServiceType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceType.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServiceType message.
         * @function verify
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServiceType.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.serviceTypeId != null && message.hasOwnProperty("serviceTypeId"))
                if (!$util.isString(message.serviceTypeId))
                    return "serviceTypeId: string expected";
            if (message.serviceTypeName != null && message.hasOwnProperty("serviceTypeName"))
                if (!$util.isString(message.serviceTypeName))
                    return "serviceTypeName: string expected";
            if (message.serviceTypeLegacyId != null && message.hasOwnProperty("serviceTypeLegacyId"))
                if (!$util.isInteger(message.serviceTypeLegacyId))
                    return "serviceTypeLegacyId: integer expected";
            if (message.isVisible != null && message.hasOwnProperty("isVisible"))
                if (typeof message.isVisible !== "boolean")
                    return "isVisible: boolean expected";
            if (message.isComingSoon != null && message.hasOwnProperty("isComingSoon"))
                if (typeof message.isComingSoon !== "boolean")
                    return "isComingSoon: boolean expected";
            if (message.bookingType != null && message.hasOwnProperty("bookingType"))
                switch (message.bookingType) {
                default:
                    return "bookingType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.appointmentDuration != null && message.hasOwnProperty("appointmentDuration"))
                if (!$util.isInteger(message.appointmentDuration))
                    return "appointmentDuration: integer expected";
            return null;
        };

        /**
         * Creates a ServiceType message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.ServiceType} ServiceType
         */
        ServiceType.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.ServiceType)
                return object;
            let message = new $root.odmarketservicespb.ServiceType();
            if (object.serviceTypeId != null)
                message.serviceTypeId = String(object.serviceTypeId);
            if (object.serviceTypeName != null)
                message.serviceTypeName = String(object.serviceTypeName);
            if (object.serviceTypeLegacyId != null)
                message.serviceTypeLegacyId = object.serviceTypeLegacyId | 0;
            if (object.isVisible != null)
                message.isVisible = Boolean(object.isVisible);
            if (object.isComingSoon != null)
                message.isComingSoon = Boolean(object.isComingSoon);
            switch (object.bookingType) {
            case "BOOKING_TYPE_NOT_SET":
            case 0:
                message.bookingType = 0;
                break;
            case "HARD":
            case 1:
                message.bookingType = 1;
                break;
            case "SOFT":
            case 2:
                message.bookingType = 2;
                break;
            }
            if (object.appointmentDuration != null)
                message.appointmentDuration = object.appointmentDuration | 0;
            return message;
        };

        /**
         * Creates a plain object from a ServiceType message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.ServiceType
         * @static
         * @param {odmarketservicespb.ServiceType} message ServiceType
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.serviceTypeId = "";
                object.serviceTypeName = "";
                object.serviceTypeLegacyId = 0;
                object.isVisible = false;
                object.isComingSoon = false;
                object.bookingType = options.enums === String ? "BOOKING_TYPE_NOT_SET" : 0;
                object.appointmentDuration = 0;
            }
            if (message.serviceTypeId != null && message.hasOwnProperty("serviceTypeId"))
                object.serviceTypeId = message.serviceTypeId;
            if (message.serviceTypeName != null && message.hasOwnProperty("serviceTypeName"))
                object.serviceTypeName = message.serviceTypeName;
            if (message.serviceTypeLegacyId != null && message.hasOwnProperty("serviceTypeLegacyId"))
                object.serviceTypeLegacyId = message.serviceTypeLegacyId;
            if (message.isVisible != null && message.hasOwnProperty("isVisible"))
                object.isVisible = message.isVisible;
            if (message.isComingSoon != null && message.hasOwnProperty("isComingSoon"))
                object.isComingSoon = message.isComingSoon;
            if (message.bookingType != null && message.hasOwnProperty("bookingType"))
                object.bookingType = options.enums === String ? $root.odmarketservicespb.BookingType[message.bookingType] : message.bookingType;
            if (message.appointmentDuration != null && message.hasOwnProperty("appointmentDuration"))
                object.appointmentDuration = message.appointmentDuration;
            return object;
        };

        /**
         * Converts this ServiceType to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.ServiceType
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServiceType;
    })();

    /**
     * BookingType enum.
     * @name odmarketservicespb.BookingType
     * @enum {number}
     * @property {number} BOOKING_TYPE_NOT_SET=0 BOOKING_TYPE_NOT_SET value
     * @property {number} HARD=1 HARD value
     * @property {number} SOFT=2 SOFT value
     */
    odmarketservicespb.BookingType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BOOKING_TYPE_NOT_SET"] = 0;
        values[valuesById[1] = "HARD"] = 1;
        values[valuesById[2] = "SOFT"] = 2;
        return values;
    })();

    odmarketservicespb.AvailableSkusResponse = (function() {

        /**
         * Properties of an AvailableSkusResponse.
         * @memberof odmarketservicespb
         * @interface IAvailableSkusResponse
         * @property {common.Status|null} [status] AvailableSkusResponse status
         * @property {odmarketservicespb.ISkuPage|null} [skuPage] AvailableSkusResponse skuPage
         */

        /**
         * Constructs a new AvailableSkusResponse.
         * @memberof odmarketservicespb
         * @classdesc Represents an AvailableSkusResponse.
         * @implements IAvailableSkusResponse
         * @constructor
         * @param {odmarketservicespb.IAvailableSkusResponse=} [properties] Properties to set
         */
        function AvailableSkusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AvailableSkusResponse status.
         * @member {common.Status} status
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @instance
         */
        AvailableSkusResponse.prototype.status = 0;

        /**
         * AvailableSkusResponse skuPage.
         * @member {odmarketservicespb.ISkuPage|null|undefined} skuPage
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @instance
         */
        AvailableSkusResponse.prototype.skuPage = null;

        /**
         * Creates a new AvailableSkusResponse instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {odmarketservicespb.IAvailableSkusResponse=} [properties] Properties to set
         * @returns {odmarketservicespb.AvailableSkusResponse} AvailableSkusResponse instance
         */
        AvailableSkusResponse.create = function create(properties) {
            return new AvailableSkusResponse(properties);
        };

        /**
         * Encodes the specified AvailableSkusResponse message. Does not implicitly {@link odmarketservicespb.AvailableSkusResponse.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {odmarketservicespb.IAvailableSkusResponse} message AvailableSkusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableSkusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.skuPage != null && Object.hasOwnProperty.call(message, "skuPage"))
                $root.odmarketservicespb.SkuPage.encode(message.skuPage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AvailableSkusResponse message, length delimited. Does not implicitly {@link odmarketservicespb.AvailableSkusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {odmarketservicespb.IAvailableSkusResponse} message AvailableSkusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableSkusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AvailableSkusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.AvailableSkusResponse} AvailableSkusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableSkusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.AvailableSkusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.skuPage = $root.odmarketservicespb.SkuPage.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AvailableSkusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.AvailableSkusResponse} AvailableSkusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableSkusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AvailableSkusResponse message.
         * @function verify
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailableSkusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.skuPage != null && message.hasOwnProperty("skuPage")) {
                let error = $root.odmarketservicespb.SkuPage.verify(message.skuPage);
                if (error)
                    return "skuPage." + error;
            }
            return null;
        };

        /**
         * Creates an AvailableSkusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.AvailableSkusResponse} AvailableSkusResponse
         */
        AvailableSkusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.AvailableSkusResponse)
                return object;
            let message = new $root.odmarketservicespb.AvailableSkusResponse();
            switch (object.status) {
            case "STATUS_UNDEFINED":
            case 0:
                message.status = 0;
                break;
            case "SUCCESS":
            case 1:
                message.status = 1;
                break;
            case "FAIL":
            case 2:
                message.status = 2;
                break;
            }
            if (object.skuPage != null) {
                if (typeof object.skuPage !== "object")
                    throw TypeError(".odmarketservicespb.AvailableSkusResponse.skuPage: object expected");
                message.skuPage = $root.odmarketservicespb.SkuPage.fromObject(object.skuPage);
            }
            return message;
        };

        /**
         * Creates a plain object from an AvailableSkusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @static
         * @param {odmarketservicespb.AvailableSkusResponse} message AvailableSkusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailableSkusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "STATUS_UNDEFINED" : 0;
                object.skuPage = null;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.common.Status[message.status] : message.status;
            if (message.skuPage != null && message.hasOwnProperty("skuPage"))
                object.skuPage = $root.odmarketservicespb.SkuPage.toObject(message.skuPage, options);
            return object;
        };

        /**
         * Converts this AvailableSkusResponse to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.AvailableSkusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailableSkusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AvailableSkusResponse;
    })();

    odmarketservicespb.SkuPage = (function() {

        /**
         * Properties of a SkuPage.
         * @memberof odmarketservicespb
         * @interface ISkuPage
         * @property {odcommonpb.IPaginationInfo|null} [paginationInfo] SkuPage paginationInfo
         * @property {Array.<odmarketservicespb.ISku>|null} [skus] SkuPage skus
         */

        /**
         * Constructs a new SkuPage.
         * @memberof odmarketservicespb
         * @classdesc Represents a SkuPage.
         * @implements ISkuPage
         * @constructor
         * @param {odmarketservicespb.ISkuPage=} [properties] Properties to set
         */
        function SkuPage(properties) {
            this.skus = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkuPage paginationInfo.
         * @member {odcommonpb.IPaginationInfo|null|undefined} paginationInfo
         * @memberof odmarketservicespb.SkuPage
         * @instance
         */
        SkuPage.prototype.paginationInfo = null;

        /**
         * SkuPage skus.
         * @member {Array.<odmarketservicespb.ISku>} skus
         * @memberof odmarketservicespb.SkuPage
         * @instance
         */
        SkuPage.prototype.skus = $util.emptyArray;

        /**
         * Creates a new SkuPage instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {odmarketservicespb.ISkuPage=} [properties] Properties to set
         * @returns {odmarketservicespb.SkuPage} SkuPage instance
         */
        SkuPage.create = function create(properties) {
            return new SkuPage(properties);
        };

        /**
         * Encodes the specified SkuPage message. Does not implicitly {@link odmarketservicespb.SkuPage.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {odmarketservicespb.ISkuPage} message SkuPage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuPage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paginationInfo != null && Object.hasOwnProperty.call(message, "paginationInfo"))
                $root.odcommonpb.PaginationInfo.encode(message.paginationInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.skus != null && message.skus.length)
                for (let i = 0; i < message.skus.length; ++i)
                    $root.odmarketservicespb.Sku.encode(message.skus[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SkuPage message, length delimited. Does not implicitly {@link odmarketservicespb.SkuPage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {odmarketservicespb.ISkuPage} message SkuPage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuPage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SkuPage message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.SkuPage} SkuPage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuPage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.SkuPage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paginationInfo = $root.odcommonpb.PaginationInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.skus && message.skus.length))
                        message.skus = [];
                    message.skus.push($root.odmarketservicespb.Sku.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SkuPage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.SkuPage} SkuPage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuPage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SkuPage message.
         * @function verify
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SkuPage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paginationInfo != null && message.hasOwnProperty("paginationInfo")) {
                let error = $root.odcommonpb.PaginationInfo.verify(message.paginationInfo);
                if (error)
                    return "paginationInfo." + error;
            }
            if (message.skus != null && message.hasOwnProperty("skus")) {
                if (!Array.isArray(message.skus))
                    return "skus: array expected";
                for (let i = 0; i < message.skus.length; ++i) {
                    let error = $root.odmarketservicespb.Sku.verify(message.skus[i]);
                    if (error)
                        return "skus." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SkuPage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.SkuPage} SkuPage
         */
        SkuPage.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.SkuPage)
                return object;
            let message = new $root.odmarketservicespb.SkuPage();
            if (object.paginationInfo != null) {
                if (typeof object.paginationInfo !== "object")
                    throw TypeError(".odmarketservicespb.SkuPage.paginationInfo: object expected");
                message.paginationInfo = $root.odcommonpb.PaginationInfo.fromObject(object.paginationInfo);
            }
            if (object.skus) {
                if (!Array.isArray(object.skus))
                    throw TypeError(".odmarketservicespb.SkuPage.skus: array expected");
                message.skus = [];
                for (let i = 0; i < object.skus.length; ++i) {
                    if (typeof object.skus[i] !== "object")
                        throw TypeError(".odmarketservicespb.SkuPage.skus: object expected");
                    message.skus[i] = $root.odmarketservicespb.Sku.fromObject(object.skus[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SkuPage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.SkuPage
         * @static
         * @param {odmarketservicespb.SkuPage} message SkuPage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SkuPage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.skus = [];
            if (options.defaults)
                object.paginationInfo = null;
            if (message.paginationInfo != null && message.hasOwnProperty("paginationInfo"))
                object.paginationInfo = $root.odcommonpb.PaginationInfo.toObject(message.paginationInfo, options);
            if (message.skus && message.skus.length) {
                object.skus = [];
                for (let j = 0; j < message.skus.length; ++j)
                    object.skus[j] = $root.odmarketservicespb.Sku.toObject(message.skus[j], options);
            }
            return object;
        };

        /**
         * Converts this SkuPage to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.SkuPage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SkuPage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SkuPage;
    })();

    odmarketservicespb.Sku = (function() {

        /**
         * Properties of a Sku.
         * @memberof odmarketservicespb
         * @interface ISku
         * @property {number|Long|null} [skuLegacyId] Sku skuLegacyId
         * @property {string|null} [name] Sku name
         * @property {number|null} [skuPrice] Sku skuPrice
         * @property {string|null} [shortDescription] Sku shortDescription
         * @property {Array.<string>|null} [keywords] Sku keywords
         * @property {number|null} [skuTypeLegacyId] Sku skuTypeLegacyId
         * @property {number|null} [skuCategoryLegacyId] Sku skuCategoryLegacyId
         * @property {string|null} [skuCategoryName] Sku skuCategoryName
         * @property {string|null} [serviceTypeId] Sku serviceTypeId
         * @property {string|null} [serviceTypeName] Sku serviceTypeName
         * @property {boolean|null} [isVisibleConsumer] Sku isVisibleConsumer
         * @property {boolean|null} [isVisiblePro] Sku isVisiblePro
         * @property {Array.<odmarketservicespb.ISkuPrice>|null} [skuPrices] Sku skuPrices
         * @property {odmarketservicespb.ISkuContent|null} [skuContent] Sku skuContent
         * @property {Array.<odmarketservicespb.ISkuAttribute>|null} [skuAttributes] Sku skuAttributes
         * @property {Array.<odmarketservicespb.ISkuPackage>|null} [skuPackages] Sku skuPackages
         */

        /**
         * Constructs a new Sku.
         * @memberof odmarketservicespb
         * @classdesc Represents a Sku.
         * @implements ISku
         * @constructor
         * @param {odmarketservicespb.ISku=} [properties] Properties to set
         */
        function Sku(properties) {
            this.keywords = [];
            this.skuPrices = [];
            this.skuAttributes = [];
            this.skuPackages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Sku skuLegacyId.
         * @member {number|Long} skuLegacyId
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuLegacyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Sku name.
         * @member {string} name
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.name = "";

        /**
         * Sku skuPrice.
         * @member {number} skuPrice
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuPrice = 0;

        /**
         * Sku shortDescription.
         * @member {string} shortDescription
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.shortDescription = "";

        /**
         * Sku keywords.
         * @member {Array.<string>} keywords
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.keywords = $util.emptyArray;

        /**
         * Sku skuTypeLegacyId.
         * @member {number} skuTypeLegacyId
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuTypeLegacyId = 0;

        /**
         * Sku skuCategoryLegacyId.
         * @member {number} skuCategoryLegacyId
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuCategoryLegacyId = 0;

        /**
         * Sku skuCategoryName.
         * @member {string} skuCategoryName
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuCategoryName = "";

        /**
         * Sku serviceTypeId.
         * @member {string} serviceTypeId
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.serviceTypeId = "";

        /**
         * Sku serviceTypeName.
         * @member {string} serviceTypeName
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.serviceTypeName = "";

        /**
         * Sku isVisibleConsumer.
         * @member {boolean} isVisibleConsumer
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.isVisibleConsumer = false;

        /**
         * Sku isVisiblePro.
         * @member {boolean} isVisiblePro
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.isVisiblePro = false;

        /**
         * Sku skuPrices.
         * @member {Array.<odmarketservicespb.ISkuPrice>} skuPrices
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuPrices = $util.emptyArray;

        /**
         * Sku skuContent.
         * @member {odmarketservicespb.ISkuContent|null|undefined} skuContent
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuContent = null;

        /**
         * Sku skuAttributes.
         * @member {Array.<odmarketservicespb.ISkuAttribute>} skuAttributes
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuAttributes = $util.emptyArray;

        /**
         * Sku skuPackages.
         * @member {Array.<odmarketservicespb.ISkuPackage>} skuPackages
         * @memberof odmarketservicespb.Sku
         * @instance
         */
        Sku.prototype.skuPackages = $util.emptyArray;

        /**
         * Creates a new Sku instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {odmarketservicespb.ISku=} [properties] Properties to set
         * @returns {odmarketservicespb.Sku} Sku instance
         */
        Sku.create = function create(properties) {
            return new Sku(properties);
        };

        /**
         * Encodes the specified Sku message. Does not implicitly {@link odmarketservicespb.Sku.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {odmarketservicespb.ISku} message Sku message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sku.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skuLegacyId != null && Object.hasOwnProperty.call(message, "skuLegacyId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.skuLegacyId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.skuPrice != null && Object.hasOwnProperty.call(message, "skuPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.skuPrice);
            if (message.shortDescription != null && Object.hasOwnProperty.call(message, "shortDescription"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortDescription);
            if (message.keywords != null && message.keywords.length)
                for (let i = 0; i < message.keywords.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.keywords[i]);
            if (message.skuTypeLegacyId != null && Object.hasOwnProperty.call(message, "skuTypeLegacyId"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.skuTypeLegacyId);
            if (message.skuCategoryLegacyId != null && Object.hasOwnProperty.call(message, "skuCategoryLegacyId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.skuCategoryLegacyId);
            if (message.skuCategoryName != null && Object.hasOwnProperty.call(message, "skuCategoryName"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.skuCategoryName);
            if (message.serviceTypeId != null && Object.hasOwnProperty.call(message, "serviceTypeId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.serviceTypeId);
            if (message.serviceTypeName != null && Object.hasOwnProperty.call(message, "serviceTypeName"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.serviceTypeName);
            if (message.isVisibleConsumer != null && Object.hasOwnProperty.call(message, "isVisibleConsumer"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isVisibleConsumer);
            if (message.isVisiblePro != null && Object.hasOwnProperty.call(message, "isVisiblePro"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isVisiblePro);
            if (message.skuPrices != null && message.skuPrices.length)
                for (let i = 0; i < message.skuPrices.length; ++i)
                    $root.odmarketservicespb.SkuPrice.encode(message.skuPrices[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.skuContent != null && Object.hasOwnProperty.call(message, "skuContent"))
                $root.odmarketservicespb.SkuContent.encode(message.skuContent, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.skuAttributes != null && message.skuAttributes.length)
                for (let i = 0; i < message.skuAttributes.length; ++i)
                    $root.odmarketservicespb.SkuAttribute.encode(message.skuAttributes[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.skuPackages != null && message.skuPackages.length)
                for (let i = 0; i < message.skuPackages.length; ++i)
                    $root.odmarketservicespb.SkuPackage.encode(message.skuPackages[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Sku message, length delimited. Does not implicitly {@link odmarketservicespb.Sku.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {odmarketservicespb.ISku} message Sku message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sku.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Sku message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.Sku} Sku
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sku.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.Sku();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.skuLegacyId = reader.int64();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.skuPrice = reader.int32();
                    break;
                case 4:
                    message.shortDescription = reader.string();
                    break;
                case 5:
                    if (!(message.keywords && message.keywords.length))
                        message.keywords = [];
                    message.keywords.push(reader.string());
                    break;
                case 6:
                    message.skuTypeLegacyId = reader.int32();
                    break;
                case 7:
                    message.skuCategoryLegacyId = reader.int32();
                    break;
                case 8:
                    message.skuCategoryName = reader.string();
                    break;
                case 9:
                    message.serviceTypeId = reader.string();
                    break;
                case 10:
                    message.serviceTypeName = reader.string();
                    break;
                case 11:
                    message.isVisibleConsumer = reader.bool();
                    break;
                case 12:
                    message.isVisiblePro = reader.bool();
                    break;
                case 13:
                    if (!(message.skuPrices && message.skuPrices.length))
                        message.skuPrices = [];
                    message.skuPrices.push($root.odmarketservicespb.SkuPrice.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.skuContent = $root.odmarketservicespb.SkuContent.decode(reader, reader.uint32());
                    break;
                case 15:
                    if (!(message.skuAttributes && message.skuAttributes.length))
                        message.skuAttributes = [];
                    message.skuAttributes.push($root.odmarketservicespb.SkuAttribute.decode(reader, reader.uint32()));
                    break;
                case 16:
                    if (!(message.skuPackages && message.skuPackages.length))
                        message.skuPackages = [];
                    message.skuPackages.push($root.odmarketservicespb.SkuPackage.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Sku message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.Sku} Sku
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sku.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Sku message.
         * @function verify
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Sku.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.skuLegacyId != null && message.hasOwnProperty("skuLegacyId"))
                if (!$util.isInteger(message.skuLegacyId) && !(message.skuLegacyId && $util.isInteger(message.skuLegacyId.low) && $util.isInteger(message.skuLegacyId.high)))
                    return "skuLegacyId: integer|Long expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.skuPrice != null && message.hasOwnProperty("skuPrice"))
                if (!$util.isInteger(message.skuPrice))
                    return "skuPrice: integer expected";
            if (message.shortDescription != null && message.hasOwnProperty("shortDescription"))
                if (!$util.isString(message.shortDescription))
                    return "shortDescription: string expected";
            if (message.keywords != null && message.hasOwnProperty("keywords")) {
                if (!Array.isArray(message.keywords))
                    return "keywords: array expected";
                for (let i = 0; i < message.keywords.length; ++i)
                    if (!$util.isString(message.keywords[i]))
                        return "keywords: string[] expected";
            }
            if (message.skuTypeLegacyId != null && message.hasOwnProperty("skuTypeLegacyId"))
                if (!$util.isInteger(message.skuTypeLegacyId))
                    return "skuTypeLegacyId: integer expected";
            if (message.skuCategoryLegacyId != null && message.hasOwnProperty("skuCategoryLegacyId"))
                if (!$util.isInteger(message.skuCategoryLegacyId))
                    return "skuCategoryLegacyId: integer expected";
            if (message.skuCategoryName != null && message.hasOwnProperty("skuCategoryName"))
                if (!$util.isString(message.skuCategoryName))
                    return "skuCategoryName: string expected";
            if (message.serviceTypeId != null && message.hasOwnProperty("serviceTypeId"))
                if (!$util.isString(message.serviceTypeId))
                    return "serviceTypeId: string expected";
            if (message.serviceTypeName != null && message.hasOwnProperty("serviceTypeName"))
                if (!$util.isString(message.serviceTypeName))
                    return "serviceTypeName: string expected";
            if (message.isVisibleConsumer != null && message.hasOwnProperty("isVisibleConsumer"))
                if (typeof message.isVisibleConsumer !== "boolean")
                    return "isVisibleConsumer: boolean expected";
            if (message.isVisiblePro != null && message.hasOwnProperty("isVisiblePro"))
                if (typeof message.isVisiblePro !== "boolean")
                    return "isVisiblePro: boolean expected";
            if (message.skuPrices != null && message.hasOwnProperty("skuPrices")) {
                if (!Array.isArray(message.skuPrices))
                    return "skuPrices: array expected";
                for (let i = 0; i < message.skuPrices.length; ++i) {
                    let error = $root.odmarketservicespb.SkuPrice.verify(message.skuPrices[i]);
                    if (error)
                        return "skuPrices." + error;
                }
            }
            if (message.skuContent != null && message.hasOwnProperty("skuContent")) {
                let error = $root.odmarketservicespb.SkuContent.verify(message.skuContent);
                if (error)
                    return "skuContent." + error;
            }
            if (message.skuAttributes != null && message.hasOwnProperty("skuAttributes")) {
                if (!Array.isArray(message.skuAttributes))
                    return "skuAttributes: array expected";
                for (let i = 0; i < message.skuAttributes.length; ++i) {
                    let error = $root.odmarketservicespb.SkuAttribute.verify(message.skuAttributes[i]);
                    if (error)
                        return "skuAttributes." + error;
                }
            }
            if (message.skuPackages != null && message.hasOwnProperty("skuPackages")) {
                if (!Array.isArray(message.skuPackages))
                    return "skuPackages: array expected";
                for (let i = 0; i < message.skuPackages.length; ++i) {
                    let error = $root.odmarketservicespb.SkuPackage.verify(message.skuPackages[i]);
                    if (error)
                        return "skuPackages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Sku message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.Sku} Sku
         */
        Sku.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.Sku)
                return object;
            let message = new $root.odmarketservicespb.Sku();
            if (object.skuLegacyId != null)
                if ($util.Long)
                    (message.skuLegacyId = $util.Long.fromValue(object.skuLegacyId)).unsigned = false;
                else if (typeof object.skuLegacyId === "string")
                    message.skuLegacyId = parseInt(object.skuLegacyId, 10);
                else if (typeof object.skuLegacyId === "number")
                    message.skuLegacyId = object.skuLegacyId;
                else if (typeof object.skuLegacyId === "object")
                    message.skuLegacyId = new $util.LongBits(object.skuLegacyId.low >>> 0, object.skuLegacyId.high >>> 0).toNumber();
            if (object.name != null)
                message.name = String(object.name);
            if (object.skuPrice != null)
                message.skuPrice = object.skuPrice | 0;
            if (object.shortDescription != null)
                message.shortDescription = String(object.shortDescription);
            if (object.keywords) {
                if (!Array.isArray(object.keywords))
                    throw TypeError(".odmarketservicespb.Sku.keywords: array expected");
                message.keywords = [];
                for (let i = 0; i < object.keywords.length; ++i)
                    message.keywords[i] = String(object.keywords[i]);
            }
            if (object.skuTypeLegacyId != null)
                message.skuTypeLegacyId = object.skuTypeLegacyId | 0;
            if (object.skuCategoryLegacyId != null)
                message.skuCategoryLegacyId = object.skuCategoryLegacyId | 0;
            if (object.skuCategoryName != null)
                message.skuCategoryName = String(object.skuCategoryName);
            if (object.serviceTypeId != null)
                message.serviceTypeId = String(object.serviceTypeId);
            if (object.serviceTypeName != null)
                message.serviceTypeName = String(object.serviceTypeName);
            if (object.isVisibleConsumer != null)
                message.isVisibleConsumer = Boolean(object.isVisibleConsumer);
            if (object.isVisiblePro != null)
                message.isVisiblePro = Boolean(object.isVisiblePro);
            if (object.skuPrices) {
                if (!Array.isArray(object.skuPrices))
                    throw TypeError(".odmarketservicespb.Sku.skuPrices: array expected");
                message.skuPrices = [];
                for (let i = 0; i < object.skuPrices.length; ++i) {
                    if (typeof object.skuPrices[i] !== "object")
                        throw TypeError(".odmarketservicespb.Sku.skuPrices: object expected");
                    message.skuPrices[i] = $root.odmarketservicespb.SkuPrice.fromObject(object.skuPrices[i]);
                }
            }
            if (object.skuContent != null) {
                if (typeof object.skuContent !== "object")
                    throw TypeError(".odmarketservicespb.Sku.skuContent: object expected");
                message.skuContent = $root.odmarketservicespb.SkuContent.fromObject(object.skuContent);
            }
            if (object.skuAttributes) {
                if (!Array.isArray(object.skuAttributes))
                    throw TypeError(".odmarketservicespb.Sku.skuAttributes: array expected");
                message.skuAttributes = [];
                for (let i = 0; i < object.skuAttributes.length; ++i) {
                    if (typeof object.skuAttributes[i] !== "object")
                        throw TypeError(".odmarketservicespb.Sku.skuAttributes: object expected");
                    message.skuAttributes[i] = $root.odmarketservicespb.SkuAttribute.fromObject(object.skuAttributes[i]);
                }
            }
            if (object.skuPackages) {
                if (!Array.isArray(object.skuPackages))
                    throw TypeError(".odmarketservicespb.Sku.skuPackages: array expected");
                message.skuPackages = [];
                for (let i = 0; i < object.skuPackages.length; ++i) {
                    if (typeof object.skuPackages[i] !== "object")
                        throw TypeError(".odmarketservicespb.Sku.skuPackages: object expected");
                    message.skuPackages[i] = $root.odmarketservicespb.SkuPackage.fromObject(object.skuPackages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Sku message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.Sku
         * @static
         * @param {odmarketservicespb.Sku} message Sku
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Sku.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.keywords = [];
                object.skuPrices = [];
                object.skuAttributes = [];
                object.skuPackages = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.skuLegacyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.skuLegacyId = options.longs === String ? "0" : 0;
                object.name = "";
                object.skuPrice = 0;
                object.shortDescription = "";
                object.skuTypeLegacyId = 0;
                object.skuCategoryLegacyId = 0;
                object.skuCategoryName = "";
                object.serviceTypeId = "";
                object.serviceTypeName = "";
                object.isVisibleConsumer = false;
                object.isVisiblePro = false;
                object.skuContent = null;
            }
            if (message.skuLegacyId != null && message.hasOwnProperty("skuLegacyId"))
                if (typeof message.skuLegacyId === "number")
                    object.skuLegacyId = options.longs === String ? String(message.skuLegacyId) : message.skuLegacyId;
                else
                    object.skuLegacyId = options.longs === String ? $util.Long.prototype.toString.call(message.skuLegacyId) : options.longs === Number ? new $util.LongBits(message.skuLegacyId.low >>> 0, message.skuLegacyId.high >>> 0).toNumber() : message.skuLegacyId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.skuPrice != null && message.hasOwnProperty("skuPrice"))
                object.skuPrice = message.skuPrice;
            if (message.shortDescription != null && message.hasOwnProperty("shortDescription"))
                object.shortDescription = message.shortDescription;
            if (message.keywords && message.keywords.length) {
                object.keywords = [];
                for (let j = 0; j < message.keywords.length; ++j)
                    object.keywords[j] = message.keywords[j];
            }
            if (message.skuTypeLegacyId != null && message.hasOwnProperty("skuTypeLegacyId"))
                object.skuTypeLegacyId = message.skuTypeLegacyId;
            if (message.skuCategoryLegacyId != null && message.hasOwnProperty("skuCategoryLegacyId"))
                object.skuCategoryLegacyId = message.skuCategoryLegacyId;
            if (message.skuCategoryName != null && message.hasOwnProperty("skuCategoryName"))
                object.skuCategoryName = message.skuCategoryName;
            if (message.serviceTypeId != null && message.hasOwnProperty("serviceTypeId"))
                object.serviceTypeId = message.serviceTypeId;
            if (message.serviceTypeName != null && message.hasOwnProperty("serviceTypeName"))
                object.serviceTypeName = message.serviceTypeName;
            if (message.isVisibleConsumer != null && message.hasOwnProperty("isVisibleConsumer"))
                object.isVisibleConsumer = message.isVisibleConsumer;
            if (message.isVisiblePro != null && message.hasOwnProperty("isVisiblePro"))
                object.isVisiblePro = message.isVisiblePro;
            if (message.skuPrices && message.skuPrices.length) {
                object.skuPrices = [];
                for (let j = 0; j < message.skuPrices.length; ++j)
                    object.skuPrices[j] = $root.odmarketservicespb.SkuPrice.toObject(message.skuPrices[j], options);
            }
            if (message.skuContent != null && message.hasOwnProperty("skuContent"))
                object.skuContent = $root.odmarketservicespb.SkuContent.toObject(message.skuContent, options);
            if (message.skuAttributes && message.skuAttributes.length) {
                object.skuAttributes = [];
                for (let j = 0; j < message.skuAttributes.length; ++j)
                    object.skuAttributes[j] = $root.odmarketservicespb.SkuAttribute.toObject(message.skuAttributes[j], options);
            }
            if (message.skuPackages && message.skuPackages.length) {
                object.skuPackages = [];
                for (let j = 0; j < message.skuPackages.length; ++j)
                    object.skuPackages[j] = $root.odmarketservicespb.SkuPackage.toObject(message.skuPackages[j], options);
            }
            return object;
        };

        /**
         * Converts this Sku to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.Sku
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Sku.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Sku;
    })();

    odmarketservicespb.AvailableSkusQueryRequest = (function() {

        /**
         * Properties of an AvailableSkusQueryRequest.
         * @memberof odmarketservicespb
         * @interface IAvailableSkusQueryRequest
         * @property {Array.<string>|null} [marketIds] AvailableSkusQueryRequest marketIds
         * @property {string|null} [jobTypeId] AvailableSkusQueryRequest jobTypeId
         * @property {string|null} [userRole] AvailableSkusQueryRequest userRole
         * @property {Array.<string>|null} [serviceTypeIds] AvailableSkusQueryRequest serviceTypeIds
         * @property {Array.<number|Long>|null} [skuCategoryLegacyIds] AvailableSkusQueryRequest skuCategoryLegacyIds
         * @property {Array.<number|Long>|null} [skuLegacyIds] AvailableSkusQueryRequest skuLegacyIds
         * @property {Array.<number|Long>|null} [skuTypeLegacyIds] AvailableSkusQueryRequest skuTypeLegacyIds
         * @property {boolean|null} [includePrices] AvailableSkusQueryRequest includePrices
         * @property {boolean|null} [includeContent] AvailableSkusQueryRequest includeContent
         * @property {boolean|null} [includeAttributes] AvailableSkusQueryRequest includeAttributes
         * @property {boolean|null} [includeSkuPackages] AvailableSkusQueryRequest includeSkuPackages
         * @property {number|Long|null} [pageSize] AvailableSkusQueryRequest pageSize
         * @property {number|Long|null} [currentPage] AvailableSkusQueryRequest currentPage
         */

        /**
         * Constructs a new AvailableSkusQueryRequest.
         * @memberof odmarketservicespb
         * @classdesc Represents an AvailableSkusQueryRequest.
         * @implements IAvailableSkusQueryRequest
         * @constructor
         * @param {odmarketservicespb.IAvailableSkusQueryRequest=} [properties] Properties to set
         */
        function AvailableSkusQueryRequest(properties) {
            this.marketIds = [];
            this.serviceTypeIds = [];
            this.skuCategoryLegacyIds = [];
            this.skuLegacyIds = [];
            this.skuTypeLegacyIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AvailableSkusQueryRequest marketIds.
         * @member {Array.<string>} marketIds
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.marketIds = $util.emptyArray;

        /**
         * AvailableSkusQueryRequest jobTypeId.
         * @member {string} jobTypeId
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.jobTypeId = "";

        /**
         * AvailableSkusQueryRequest userRole.
         * @member {string} userRole
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.userRole = "";

        /**
         * AvailableSkusQueryRequest serviceTypeIds.
         * @member {Array.<string>} serviceTypeIds
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.serviceTypeIds = $util.emptyArray;

        /**
         * AvailableSkusQueryRequest skuCategoryLegacyIds.
         * @member {Array.<number|Long>} skuCategoryLegacyIds
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.skuCategoryLegacyIds = $util.emptyArray;

        /**
         * AvailableSkusQueryRequest skuLegacyIds.
         * @member {Array.<number|Long>} skuLegacyIds
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.skuLegacyIds = $util.emptyArray;

        /**
         * AvailableSkusQueryRequest skuTypeLegacyIds.
         * @member {Array.<number|Long>} skuTypeLegacyIds
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.skuTypeLegacyIds = $util.emptyArray;

        /**
         * AvailableSkusQueryRequest includePrices.
         * @member {boolean} includePrices
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.includePrices = false;

        /**
         * AvailableSkusQueryRequest includeContent.
         * @member {boolean} includeContent
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.includeContent = false;

        /**
         * AvailableSkusQueryRequest includeAttributes.
         * @member {boolean} includeAttributes
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.includeAttributes = false;

        /**
         * AvailableSkusQueryRequest includeSkuPackages.
         * @member {boolean} includeSkuPackages
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.includeSkuPackages = false;

        /**
         * AvailableSkusQueryRequest pageSize.
         * @member {number|Long} pageSize
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.pageSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AvailableSkusQueryRequest currentPage.
         * @member {number|Long} currentPage
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         */
        AvailableSkusQueryRequest.prototype.currentPage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new AvailableSkusQueryRequest instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {odmarketservicespb.IAvailableSkusQueryRequest=} [properties] Properties to set
         * @returns {odmarketservicespb.AvailableSkusQueryRequest} AvailableSkusQueryRequest instance
         */
        AvailableSkusQueryRequest.create = function create(properties) {
            return new AvailableSkusQueryRequest(properties);
        };

        /**
         * Encodes the specified AvailableSkusQueryRequest message. Does not implicitly {@link odmarketservicespb.AvailableSkusQueryRequest.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {odmarketservicespb.IAvailableSkusQueryRequest} message AvailableSkusQueryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableSkusQueryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.marketIds != null && message.marketIds.length)
                for (let i = 0; i < message.marketIds.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.marketIds[i]);
            if (message.jobTypeId != null && Object.hasOwnProperty.call(message, "jobTypeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.jobTypeId);
            if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userRole);
            if (message.serviceTypeIds != null && message.serviceTypeIds.length)
                for (let i = 0; i < message.serviceTypeIds.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceTypeIds[i]);
            if (message.skuCategoryLegacyIds != null && message.skuCategoryLegacyIds.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (let i = 0; i < message.skuCategoryLegacyIds.length; ++i)
                    writer.int64(message.skuCategoryLegacyIds[i]);
                writer.ldelim();
            }
            if (message.skuLegacyIds != null && message.skuLegacyIds.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (let i = 0; i < message.skuLegacyIds.length; ++i)
                    writer.int64(message.skuLegacyIds[i]);
                writer.ldelim();
            }
            if (message.skuTypeLegacyIds != null && message.skuTypeLegacyIds.length) {
                writer.uint32(/* id 8, wireType 2 =*/66).fork();
                for (let i = 0; i < message.skuTypeLegacyIds.length; ++i)
                    writer.int64(message.skuTypeLegacyIds[i]);
                writer.ldelim();
            }
            if (message.includePrices != null && Object.hasOwnProperty.call(message, "includePrices"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.includePrices);
            if (message.includeContent != null && Object.hasOwnProperty.call(message, "includeContent"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.includeContent);
            if (message.includeAttributes != null && Object.hasOwnProperty.call(message, "includeAttributes"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.includeAttributes);
            if (message.includeSkuPackages != null && Object.hasOwnProperty.call(message, "includeSkuPackages"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.includeSkuPackages);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.pageSize);
            if (message.currentPage != null && Object.hasOwnProperty.call(message, "currentPage"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.currentPage);
            return writer;
        };

        /**
         * Encodes the specified AvailableSkusQueryRequest message, length delimited. Does not implicitly {@link odmarketservicespb.AvailableSkusQueryRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {odmarketservicespb.IAvailableSkusQueryRequest} message AvailableSkusQueryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableSkusQueryRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AvailableSkusQueryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.AvailableSkusQueryRequest} AvailableSkusQueryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableSkusQueryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.AvailableSkusQueryRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.marketIds && message.marketIds.length))
                        message.marketIds = [];
                    message.marketIds.push(reader.string());
                    break;
                case 2:
                    message.jobTypeId = reader.string();
                    break;
                case 3:
                    message.userRole = reader.string();
                    break;
                case 4:
                    if (!(message.serviceTypeIds && message.serviceTypeIds.length))
                        message.serviceTypeIds = [];
                    message.serviceTypeIds.push(reader.string());
                    break;
                case 5:
                    if (!(message.skuCategoryLegacyIds && message.skuCategoryLegacyIds.length))
                        message.skuCategoryLegacyIds = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.skuCategoryLegacyIds.push(reader.int64());
                    } else
                        message.skuCategoryLegacyIds.push(reader.int64());
                    break;
                case 7:
                    if (!(message.skuLegacyIds && message.skuLegacyIds.length))
                        message.skuLegacyIds = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.skuLegacyIds.push(reader.int64());
                    } else
                        message.skuLegacyIds.push(reader.int64());
                    break;
                case 8:
                    if (!(message.skuTypeLegacyIds && message.skuTypeLegacyIds.length))
                        message.skuTypeLegacyIds = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.skuTypeLegacyIds.push(reader.int64());
                    } else
                        message.skuTypeLegacyIds.push(reader.int64());
                    break;
                case 9:
                    message.includePrices = reader.bool();
                    break;
                case 10:
                    message.includeContent = reader.bool();
                    break;
                case 11:
                    message.includeAttributes = reader.bool();
                    break;
                case 12:
                    message.includeSkuPackages = reader.bool();
                    break;
                case 13:
                    message.pageSize = reader.int64();
                    break;
                case 14:
                    message.currentPage = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AvailableSkusQueryRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.AvailableSkusQueryRequest} AvailableSkusQueryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableSkusQueryRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AvailableSkusQueryRequest message.
         * @function verify
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailableSkusQueryRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.marketIds != null && message.hasOwnProperty("marketIds")) {
                if (!Array.isArray(message.marketIds))
                    return "marketIds: array expected";
                for (let i = 0; i < message.marketIds.length; ++i)
                    if (!$util.isString(message.marketIds[i]))
                        return "marketIds: string[] expected";
            }
            if (message.jobTypeId != null && message.hasOwnProperty("jobTypeId"))
                if (!$util.isString(message.jobTypeId))
                    return "jobTypeId: string expected";
            if (message.userRole != null && message.hasOwnProperty("userRole"))
                if (!$util.isString(message.userRole))
                    return "userRole: string expected";
            if (message.serviceTypeIds != null && message.hasOwnProperty("serviceTypeIds")) {
                if (!Array.isArray(message.serviceTypeIds))
                    return "serviceTypeIds: array expected";
                for (let i = 0; i < message.serviceTypeIds.length; ++i)
                    if (!$util.isString(message.serviceTypeIds[i]))
                        return "serviceTypeIds: string[] expected";
            }
            if (message.skuCategoryLegacyIds != null && message.hasOwnProperty("skuCategoryLegacyIds")) {
                if (!Array.isArray(message.skuCategoryLegacyIds))
                    return "skuCategoryLegacyIds: array expected";
                for (let i = 0; i < message.skuCategoryLegacyIds.length; ++i)
                    if (!$util.isInteger(message.skuCategoryLegacyIds[i]) && !(message.skuCategoryLegacyIds[i] && $util.isInteger(message.skuCategoryLegacyIds[i].low) && $util.isInteger(message.skuCategoryLegacyIds[i].high)))
                        return "skuCategoryLegacyIds: integer|Long[] expected";
            }
            if (message.skuLegacyIds != null && message.hasOwnProperty("skuLegacyIds")) {
                if (!Array.isArray(message.skuLegacyIds))
                    return "skuLegacyIds: array expected";
                for (let i = 0; i < message.skuLegacyIds.length; ++i)
                    if (!$util.isInteger(message.skuLegacyIds[i]) && !(message.skuLegacyIds[i] && $util.isInteger(message.skuLegacyIds[i].low) && $util.isInteger(message.skuLegacyIds[i].high)))
                        return "skuLegacyIds: integer|Long[] expected";
            }
            if (message.skuTypeLegacyIds != null && message.hasOwnProperty("skuTypeLegacyIds")) {
                if (!Array.isArray(message.skuTypeLegacyIds))
                    return "skuTypeLegacyIds: array expected";
                for (let i = 0; i < message.skuTypeLegacyIds.length; ++i)
                    if (!$util.isInteger(message.skuTypeLegacyIds[i]) && !(message.skuTypeLegacyIds[i] && $util.isInteger(message.skuTypeLegacyIds[i].low) && $util.isInteger(message.skuTypeLegacyIds[i].high)))
                        return "skuTypeLegacyIds: integer|Long[] expected";
            }
            if (message.includePrices != null && message.hasOwnProperty("includePrices"))
                if (typeof message.includePrices !== "boolean")
                    return "includePrices: boolean expected";
            if (message.includeContent != null && message.hasOwnProperty("includeContent"))
                if (typeof message.includeContent !== "boolean")
                    return "includeContent: boolean expected";
            if (message.includeAttributes != null && message.hasOwnProperty("includeAttributes"))
                if (typeof message.includeAttributes !== "boolean")
                    return "includeAttributes: boolean expected";
            if (message.includeSkuPackages != null && message.hasOwnProperty("includeSkuPackages"))
                if (typeof message.includeSkuPackages !== "boolean")
                    return "includeSkuPackages: boolean expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize) && !(message.pageSize && $util.isInteger(message.pageSize.low) && $util.isInteger(message.pageSize.high)))
                    return "pageSize: integer|Long expected";
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                if (!$util.isInteger(message.currentPage) && !(message.currentPage && $util.isInteger(message.currentPage.low) && $util.isInteger(message.currentPage.high)))
                    return "currentPage: integer|Long expected";
            return null;
        };

        /**
         * Creates an AvailableSkusQueryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.AvailableSkusQueryRequest} AvailableSkusQueryRequest
         */
        AvailableSkusQueryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.AvailableSkusQueryRequest)
                return object;
            let message = new $root.odmarketservicespb.AvailableSkusQueryRequest();
            if (object.marketIds) {
                if (!Array.isArray(object.marketIds))
                    throw TypeError(".odmarketservicespb.AvailableSkusQueryRequest.marketIds: array expected");
                message.marketIds = [];
                for (let i = 0; i < object.marketIds.length; ++i)
                    message.marketIds[i] = String(object.marketIds[i]);
            }
            if (object.jobTypeId != null)
                message.jobTypeId = String(object.jobTypeId);
            if (object.userRole != null)
                message.userRole = String(object.userRole);
            if (object.serviceTypeIds) {
                if (!Array.isArray(object.serviceTypeIds))
                    throw TypeError(".odmarketservicespb.AvailableSkusQueryRequest.serviceTypeIds: array expected");
                message.serviceTypeIds = [];
                for (let i = 0; i < object.serviceTypeIds.length; ++i)
                    message.serviceTypeIds[i] = String(object.serviceTypeIds[i]);
            }
            if (object.skuCategoryLegacyIds) {
                if (!Array.isArray(object.skuCategoryLegacyIds))
                    throw TypeError(".odmarketservicespb.AvailableSkusQueryRequest.skuCategoryLegacyIds: array expected");
                message.skuCategoryLegacyIds = [];
                for (let i = 0; i < object.skuCategoryLegacyIds.length; ++i)
                    if ($util.Long)
                        (message.skuCategoryLegacyIds[i] = $util.Long.fromValue(object.skuCategoryLegacyIds[i])).unsigned = false;
                    else if (typeof object.skuCategoryLegacyIds[i] === "string")
                        message.skuCategoryLegacyIds[i] = parseInt(object.skuCategoryLegacyIds[i], 10);
                    else if (typeof object.skuCategoryLegacyIds[i] === "number")
                        message.skuCategoryLegacyIds[i] = object.skuCategoryLegacyIds[i];
                    else if (typeof object.skuCategoryLegacyIds[i] === "object")
                        message.skuCategoryLegacyIds[i] = new $util.LongBits(object.skuCategoryLegacyIds[i].low >>> 0, object.skuCategoryLegacyIds[i].high >>> 0).toNumber();
            }
            if (object.skuLegacyIds) {
                if (!Array.isArray(object.skuLegacyIds))
                    throw TypeError(".odmarketservicespb.AvailableSkusQueryRequest.skuLegacyIds: array expected");
                message.skuLegacyIds = [];
                for (let i = 0; i < object.skuLegacyIds.length; ++i)
                    if ($util.Long)
                        (message.skuLegacyIds[i] = $util.Long.fromValue(object.skuLegacyIds[i])).unsigned = false;
                    else if (typeof object.skuLegacyIds[i] === "string")
                        message.skuLegacyIds[i] = parseInt(object.skuLegacyIds[i], 10);
                    else if (typeof object.skuLegacyIds[i] === "number")
                        message.skuLegacyIds[i] = object.skuLegacyIds[i];
                    else if (typeof object.skuLegacyIds[i] === "object")
                        message.skuLegacyIds[i] = new $util.LongBits(object.skuLegacyIds[i].low >>> 0, object.skuLegacyIds[i].high >>> 0).toNumber();
            }
            if (object.skuTypeLegacyIds) {
                if (!Array.isArray(object.skuTypeLegacyIds))
                    throw TypeError(".odmarketservicespb.AvailableSkusQueryRequest.skuTypeLegacyIds: array expected");
                message.skuTypeLegacyIds = [];
                for (let i = 0; i < object.skuTypeLegacyIds.length; ++i)
                    if ($util.Long)
                        (message.skuTypeLegacyIds[i] = $util.Long.fromValue(object.skuTypeLegacyIds[i])).unsigned = false;
                    else if (typeof object.skuTypeLegacyIds[i] === "string")
                        message.skuTypeLegacyIds[i] = parseInt(object.skuTypeLegacyIds[i], 10);
                    else if (typeof object.skuTypeLegacyIds[i] === "number")
                        message.skuTypeLegacyIds[i] = object.skuTypeLegacyIds[i];
                    else if (typeof object.skuTypeLegacyIds[i] === "object")
                        message.skuTypeLegacyIds[i] = new $util.LongBits(object.skuTypeLegacyIds[i].low >>> 0, object.skuTypeLegacyIds[i].high >>> 0).toNumber();
            }
            if (object.includePrices != null)
                message.includePrices = Boolean(object.includePrices);
            if (object.includeContent != null)
                message.includeContent = Boolean(object.includeContent);
            if (object.includeAttributes != null)
                message.includeAttributes = Boolean(object.includeAttributes);
            if (object.includeSkuPackages != null)
                message.includeSkuPackages = Boolean(object.includeSkuPackages);
            if (object.pageSize != null)
                if ($util.Long)
                    (message.pageSize = $util.Long.fromValue(object.pageSize)).unsigned = false;
                else if (typeof object.pageSize === "string")
                    message.pageSize = parseInt(object.pageSize, 10);
                else if (typeof object.pageSize === "number")
                    message.pageSize = object.pageSize;
                else if (typeof object.pageSize === "object")
                    message.pageSize = new $util.LongBits(object.pageSize.low >>> 0, object.pageSize.high >>> 0).toNumber();
            if (object.currentPage != null)
                if ($util.Long)
                    (message.currentPage = $util.Long.fromValue(object.currentPage)).unsigned = false;
                else if (typeof object.currentPage === "string")
                    message.currentPage = parseInt(object.currentPage, 10);
                else if (typeof object.currentPage === "number")
                    message.currentPage = object.currentPage;
                else if (typeof object.currentPage === "object")
                    message.currentPage = new $util.LongBits(object.currentPage.low >>> 0, object.currentPage.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an AvailableSkusQueryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @static
         * @param {odmarketservicespb.AvailableSkusQueryRequest} message AvailableSkusQueryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailableSkusQueryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.marketIds = [];
                object.serviceTypeIds = [];
                object.skuCategoryLegacyIds = [];
                object.skuLegacyIds = [];
                object.skuTypeLegacyIds = [];
            }
            if (options.defaults) {
                object.jobTypeId = "";
                object.userRole = "";
                object.includePrices = false;
                object.includeContent = false;
                object.includeAttributes = false;
                object.includeSkuPackages = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.pageSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pageSize = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.currentPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.currentPage = options.longs === String ? "0" : 0;
            }
            if (message.marketIds && message.marketIds.length) {
                object.marketIds = [];
                for (let j = 0; j < message.marketIds.length; ++j)
                    object.marketIds[j] = message.marketIds[j];
            }
            if (message.jobTypeId != null && message.hasOwnProperty("jobTypeId"))
                object.jobTypeId = message.jobTypeId;
            if (message.userRole != null && message.hasOwnProperty("userRole"))
                object.userRole = message.userRole;
            if (message.serviceTypeIds && message.serviceTypeIds.length) {
                object.serviceTypeIds = [];
                for (let j = 0; j < message.serviceTypeIds.length; ++j)
                    object.serviceTypeIds[j] = message.serviceTypeIds[j];
            }
            if (message.skuCategoryLegacyIds && message.skuCategoryLegacyIds.length) {
                object.skuCategoryLegacyIds = [];
                for (let j = 0; j < message.skuCategoryLegacyIds.length; ++j)
                    if (typeof message.skuCategoryLegacyIds[j] === "number")
                        object.skuCategoryLegacyIds[j] = options.longs === String ? String(message.skuCategoryLegacyIds[j]) : message.skuCategoryLegacyIds[j];
                    else
                        object.skuCategoryLegacyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.skuCategoryLegacyIds[j]) : options.longs === Number ? new $util.LongBits(message.skuCategoryLegacyIds[j].low >>> 0, message.skuCategoryLegacyIds[j].high >>> 0).toNumber() : message.skuCategoryLegacyIds[j];
            }
            if (message.skuLegacyIds && message.skuLegacyIds.length) {
                object.skuLegacyIds = [];
                for (let j = 0; j < message.skuLegacyIds.length; ++j)
                    if (typeof message.skuLegacyIds[j] === "number")
                        object.skuLegacyIds[j] = options.longs === String ? String(message.skuLegacyIds[j]) : message.skuLegacyIds[j];
                    else
                        object.skuLegacyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.skuLegacyIds[j]) : options.longs === Number ? new $util.LongBits(message.skuLegacyIds[j].low >>> 0, message.skuLegacyIds[j].high >>> 0).toNumber() : message.skuLegacyIds[j];
            }
            if (message.skuTypeLegacyIds && message.skuTypeLegacyIds.length) {
                object.skuTypeLegacyIds = [];
                for (let j = 0; j < message.skuTypeLegacyIds.length; ++j)
                    if (typeof message.skuTypeLegacyIds[j] === "number")
                        object.skuTypeLegacyIds[j] = options.longs === String ? String(message.skuTypeLegacyIds[j]) : message.skuTypeLegacyIds[j];
                    else
                        object.skuTypeLegacyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.skuTypeLegacyIds[j]) : options.longs === Number ? new $util.LongBits(message.skuTypeLegacyIds[j].low >>> 0, message.skuTypeLegacyIds[j].high >>> 0).toNumber() : message.skuTypeLegacyIds[j];
            }
            if (message.includePrices != null && message.hasOwnProperty("includePrices"))
                object.includePrices = message.includePrices;
            if (message.includeContent != null && message.hasOwnProperty("includeContent"))
                object.includeContent = message.includeContent;
            if (message.includeAttributes != null && message.hasOwnProperty("includeAttributes"))
                object.includeAttributes = message.includeAttributes;
            if (message.includeSkuPackages != null && message.hasOwnProperty("includeSkuPackages"))
                object.includeSkuPackages = message.includeSkuPackages;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (typeof message.pageSize === "number")
                    object.pageSize = options.longs === String ? String(message.pageSize) : message.pageSize;
                else
                    object.pageSize = options.longs === String ? $util.Long.prototype.toString.call(message.pageSize) : options.longs === Number ? new $util.LongBits(message.pageSize.low >>> 0, message.pageSize.high >>> 0).toNumber() : message.pageSize;
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                if (typeof message.currentPage === "number")
                    object.currentPage = options.longs === String ? String(message.currentPage) : message.currentPage;
                else
                    object.currentPage = options.longs === String ? $util.Long.prototype.toString.call(message.currentPage) : options.longs === Number ? new $util.LongBits(message.currentPage.low >>> 0, message.currentPage.high >>> 0).toNumber() : message.currentPage;
            return object;
        };

        /**
         * Converts this AvailableSkusQueryRequest to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.AvailableSkusQueryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailableSkusQueryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AvailableSkusQueryRequest;
    })();

    odmarketservicespb.SkuPrice = (function() {

        /**
         * Properties of a SkuPrice.
         * @memberof odmarketservicespb
         * @interface ISkuPrice
         * @property {string|null} [marketId] SkuPrice marketId
         * @property {number|null} [minQuantity] SkuPrice minQuantity
         * @property {number|null} [maxQuantity] SkuPrice maxQuantity
         * @property {number|null} [retailPrice] SkuPrice retailPrice
         * @property {number|null} [preDiscountPrice] SkuPrice preDiscountPrice
         * @property {number|null} [wholesalePrice] SkuPrice wholesalePrice
         */

        /**
         * Constructs a new SkuPrice.
         * @memberof odmarketservicespb
         * @classdesc Represents a SkuPrice.
         * @implements ISkuPrice
         * @constructor
         * @param {odmarketservicespb.ISkuPrice=} [properties] Properties to set
         */
        function SkuPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkuPrice marketId.
         * @member {string} marketId
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         */
        SkuPrice.prototype.marketId = "";

        /**
         * SkuPrice minQuantity.
         * @member {number} minQuantity
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         */
        SkuPrice.prototype.minQuantity = 0;

        /**
         * SkuPrice maxQuantity.
         * @member {number} maxQuantity
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         */
        SkuPrice.prototype.maxQuantity = 0;

        /**
         * SkuPrice retailPrice.
         * @member {number} retailPrice
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         */
        SkuPrice.prototype.retailPrice = 0;

        /**
         * SkuPrice preDiscountPrice.
         * @member {number} preDiscountPrice
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         */
        SkuPrice.prototype.preDiscountPrice = 0;

        /**
         * SkuPrice wholesalePrice.
         * @member {number} wholesalePrice
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         */
        SkuPrice.prototype.wholesalePrice = 0;

        /**
         * Creates a new SkuPrice instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {odmarketservicespb.ISkuPrice=} [properties] Properties to set
         * @returns {odmarketservicespb.SkuPrice} SkuPrice instance
         */
        SkuPrice.create = function create(properties) {
            return new SkuPrice(properties);
        };

        /**
         * Encodes the specified SkuPrice message. Does not implicitly {@link odmarketservicespb.SkuPrice.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {odmarketservicespb.ISkuPrice} message SkuPrice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.marketId != null && Object.hasOwnProperty.call(message, "marketId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.marketId);
            if (message.minQuantity != null && Object.hasOwnProperty.call(message, "minQuantity"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minQuantity);
            if (message.maxQuantity != null && Object.hasOwnProperty.call(message, "maxQuantity"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxQuantity);
            if (message.retailPrice != null && Object.hasOwnProperty.call(message, "retailPrice"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.retailPrice);
            if (message.preDiscountPrice != null && Object.hasOwnProperty.call(message, "preDiscountPrice"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.preDiscountPrice);
            if (message.wholesalePrice != null && Object.hasOwnProperty.call(message, "wholesalePrice"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.wholesalePrice);
            return writer;
        };

        /**
         * Encodes the specified SkuPrice message, length delimited. Does not implicitly {@link odmarketservicespb.SkuPrice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {odmarketservicespb.ISkuPrice} message SkuPrice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuPrice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SkuPrice message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.SkuPrice} SkuPrice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.SkuPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.minQuantity = reader.int32();
                    break;
                case 3:
                    message.maxQuantity = reader.int32();
                    break;
                case 4:
                    message.retailPrice = reader.int32();
                    break;
                case 5:
                    message.preDiscountPrice = reader.int32();
                    break;
                case 6:
                    message.wholesalePrice = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SkuPrice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.SkuPrice} SkuPrice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuPrice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SkuPrice message.
         * @function verify
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SkuPrice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.marketId != null && message.hasOwnProperty("marketId"))
                if (!$util.isString(message.marketId))
                    return "marketId: string expected";
            if (message.minQuantity != null && message.hasOwnProperty("minQuantity"))
                if (!$util.isInteger(message.minQuantity))
                    return "minQuantity: integer expected";
            if (message.maxQuantity != null && message.hasOwnProperty("maxQuantity"))
                if (!$util.isInteger(message.maxQuantity))
                    return "maxQuantity: integer expected";
            if (message.retailPrice != null && message.hasOwnProperty("retailPrice"))
                if (!$util.isInteger(message.retailPrice))
                    return "retailPrice: integer expected";
            if (message.preDiscountPrice != null && message.hasOwnProperty("preDiscountPrice"))
                if (!$util.isInteger(message.preDiscountPrice))
                    return "preDiscountPrice: integer expected";
            if (message.wholesalePrice != null && message.hasOwnProperty("wholesalePrice"))
                if (!$util.isInteger(message.wholesalePrice))
                    return "wholesalePrice: integer expected";
            return null;
        };

        /**
         * Creates a SkuPrice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.SkuPrice} SkuPrice
         */
        SkuPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.SkuPrice)
                return object;
            let message = new $root.odmarketservicespb.SkuPrice();
            if (object.marketId != null)
                message.marketId = String(object.marketId);
            if (object.minQuantity != null)
                message.minQuantity = object.minQuantity | 0;
            if (object.maxQuantity != null)
                message.maxQuantity = object.maxQuantity | 0;
            if (object.retailPrice != null)
                message.retailPrice = object.retailPrice | 0;
            if (object.preDiscountPrice != null)
                message.preDiscountPrice = object.preDiscountPrice | 0;
            if (object.wholesalePrice != null)
                message.wholesalePrice = object.wholesalePrice | 0;
            return message;
        };

        /**
         * Creates a plain object from a SkuPrice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.SkuPrice
         * @static
         * @param {odmarketservicespb.SkuPrice} message SkuPrice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SkuPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.marketId = "";
                object.minQuantity = 0;
                object.maxQuantity = 0;
                object.retailPrice = 0;
                object.preDiscountPrice = 0;
                object.wholesalePrice = 0;
            }
            if (message.marketId != null && message.hasOwnProperty("marketId"))
                object.marketId = message.marketId;
            if (message.minQuantity != null && message.hasOwnProperty("minQuantity"))
                object.minQuantity = message.minQuantity;
            if (message.maxQuantity != null && message.hasOwnProperty("maxQuantity"))
                object.maxQuantity = message.maxQuantity;
            if (message.retailPrice != null && message.hasOwnProperty("retailPrice"))
                object.retailPrice = message.retailPrice;
            if (message.preDiscountPrice != null && message.hasOwnProperty("preDiscountPrice"))
                object.preDiscountPrice = message.preDiscountPrice;
            if (message.wholesalePrice != null && message.hasOwnProperty("wholesalePrice"))
                object.wholesalePrice = message.wholesalePrice;
            return object;
        };

        /**
         * Converts this SkuPrice to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.SkuPrice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SkuPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SkuPrice;
    })();

    odmarketservicespb.SkuContent = (function() {

        /**
         * Properties of a SkuContent.
         * @memberof odmarketservicespb
         * @interface ISkuContent
         * @property {string|null} [included] SkuContent included
         * @property {string|null} [excluded] SkuContent excluded
         * @property {string|null} [parts] SkuContent parts
         * @property {string|null} [guarantee] SkuContent guarantee
         * @property {string|null} [brands] SkuContent brands
         */

        /**
         * Constructs a new SkuContent.
         * @memberof odmarketservicespb
         * @classdesc Represents a SkuContent.
         * @implements ISkuContent
         * @constructor
         * @param {odmarketservicespb.ISkuContent=} [properties] Properties to set
         */
        function SkuContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkuContent included.
         * @member {string} included
         * @memberof odmarketservicespb.SkuContent
         * @instance
         */
        SkuContent.prototype.included = "";

        /**
         * SkuContent excluded.
         * @member {string} excluded
         * @memberof odmarketservicespb.SkuContent
         * @instance
         */
        SkuContent.prototype.excluded = "";

        /**
         * SkuContent parts.
         * @member {string} parts
         * @memberof odmarketservicespb.SkuContent
         * @instance
         */
        SkuContent.prototype.parts = "";

        /**
         * SkuContent guarantee.
         * @member {string} guarantee
         * @memberof odmarketservicespb.SkuContent
         * @instance
         */
        SkuContent.prototype.guarantee = "";

        /**
         * SkuContent brands.
         * @member {string} brands
         * @memberof odmarketservicespb.SkuContent
         * @instance
         */
        SkuContent.prototype.brands = "";

        /**
         * Creates a new SkuContent instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {odmarketservicespb.ISkuContent=} [properties] Properties to set
         * @returns {odmarketservicespb.SkuContent} SkuContent instance
         */
        SkuContent.create = function create(properties) {
            return new SkuContent(properties);
        };

        /**
         * Encodes the specified SkuContent message. Does not implicitly {@link odmarketservicespb.SkuContent.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {odmarketservicespb.ISkuContent} message SkuContent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuContent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.included != null && Object.hasOwnProperty.call(message, "included"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.included);
            if (message.excluded != null && Object.hasOwnProperty.call(message, "excluded"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.excluded);
            if (message.parts != null && Object.hasOwnProperty.call(message, "parts"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.parts);
            if (message.guarantee != null && Object.hasOwnProperty.call(message, "guarantee"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.guarantee);
            if (message.brands != null && Object.hasOwnProperty.call(message, "brands"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.brands);
            return writer;
        };

        /**
         * Encodes the specified SkuContent message, length delimited. Does not implicitly {@link odmarketservicespb.SkuContent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {odmarketservicespb.ISkuContent} message SkuContent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuContent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SkuContent message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.SkuContent} SkuContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuContent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.SkuContent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.included = reader.string();
                    break;
                case 2:
                    message.excluded = reader.string();
                    break;
                case 3:
                    message.parts = reader.string();
                    break;
                case 4:
                    message.guarantee = reader.string();
                    break;
                case 5:
                    message.brands = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SkuContent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.SkuContent} SkuContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuContent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SkuContent message.
         * @function verify
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SkuContent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.included != null && message.hasOwnProperty("included"))
                if (!$util.isString(message.included))
                    return "included: string expected";
            if (message.excluded != null && message.hasOwnProperty("excluded"))
                if (!$util.isString(message.excluded))
                    return "excluded: string expected";
            if (message.parts != null && message.hasOwnProperty("parts"))
                if (!$util.isString(message.parts))
                    return "parts: string expected";
            if (message.guarantee != null && message.hasOwnProperty("guarantee"))
                if (!$util.isString(message.guarantee))
                    return "guarantee: string expected";
            if (message.brands != null && message.hasOwnProperty("brands"))
                if (!$util.isString(message.brands))
                    return "brands: string expected";
            return null;
        };

        /**
         * Creates a SkuContent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.SkuContent} SkuContent
         */
        SkuContent.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.SkuContent)
                return object;
            let message = new $root.odmarketservicespb.SkuContent();
            if (object.included != null)
                message.included = String(object.included);
            if (object.excluded != null)
                message.excluded = String(object.excluded);
            if (object.parts != null)
                message.parts = String(object.parts);
            if (object.guarantee != null)
                message.guarantee = String(object.guarantee);
            if (object.brands != null)
                message.brands = String(object.brands);
            return message;
        };

        /**
         * Creates a plain object from a SkuContent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.SkuContent
         * @static
         * @param {odmarketservicespb.SkuContent} message SkuContent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SkuContent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.included = "";
                object.excluded = "";
                object.parts = "";
                object.guarantee = "";
                object.brands = "";
            }
            if (message.included != null && message.hasOwnProperty("included"))
                object.included = message.included;
            if (message.excluded != null && message.hasOwnProperty("excluded"))
                object.excluded = message.excluded;
            if (message.parts != null && message.hasOwnProperty("parts"))
                object.parts = message.parts;
            if (message.guarantee != null && message.hasOwnProperty("guarantee"))
                object.guarantee = message.guarantee;
            if (message.brands != null && message.hasOwnProperty("brands"))
                object.brands = message.brands;
            return object;
        };

        /**
         * Converts this SkuContent to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.SkuContent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SkuContent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SkuContent;
    })();

    odmarketservicespb.SkuAttribute = (function() {

        /**
         * Properties of a SkuAttribute.
         * @memberof odmarketservicespb
         * @interface ISkuAttribute
         * @property {number|null} [attributeKey] SkuAttribute attributeKey
         * @property {string|null} [attributeKeyName] SkuAttribute attributeKeyName
         * @property {string|null} [attributeValue] SkuAttribute attributeValue
         * @property {string|null} [attributeValueDisplayName] SkuAttribute attributeValueDisplayName
         */

        /**
         * Constructs a new SkuAttribute.
         * @memberof odmarketservicespb
         * @classdesc Represents a SkuAttribute.
         * @implements ISkuAttribute
         * @constructor
         * @param {odmarketservicespb.ISkuAttribute=} [properties] Properties to set
         */
        function SkuAttribute(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkuAttribute attributeKey.
         * @member {number} attributeKey
         * @memberof odmarketservicespb.SkuAttribute
         * @instance
         */
        SkuAttribute.prototype.attributeKey = 0;

        /**
         * SkuAttribute attributeKeyName.
         * @member {string} attributeKeyName
         * @memberof odmarketservicespb.SkuAttribute
         * @instance
         */
        SkuAttribute.prototype.attributeKeyName = "";

        /**
         * SkuAttribute attributeValue.
         * @member {string} attributeValue
         * @memberof odmarketservicespb.SkuAttribute
         * @instance
         */
        SkuAttribute.prototype.attributeValue = "";

        /**
         * SkuAttribute attributeValueDisplayName.
         * @member {string} attributeValueDisplayName
         * @memberof odmarketservicespb.SkuAttribute
         * @instance
         */
        SkuAttribute.prototype.attributeValueDisplayName = "";

        /**
         * Creates a new SkuAttribute instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {odmarketservicespb.ISkuAttribute=} [properties] Properties to set
         * @returns {odmarketservicespb.SkuAttribute} SkuAttribute instance
         */
        SkuAttribute.create = function create(properties) {
            return new SkuAttribute(properties);
        };

        /**
         * Encodes the specified SkuAttribute message. Does not implicitly {@link odmarketservicespb.SkuAttribute.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {odmarketservicespb.ISkuAttribute} message SkuAttribute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuAttribute.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attributeKey != null && Object.hasOwnProperty.call(message, "attributeKey"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.attributeKey);
            if (message.attributeKeyName != null && Object.hasOwnProperty.call(message, "attributeKeyName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.attributeKeyName);
            if (message.attributeValue != null && Object.hasOwnProperty.call(message, "attributeValue"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.attributeValue);
            if (message.attributeValueDisplayName != null && Object.hasOwnProperty.call(message, "attributeValueDisplayName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.attributeValueDisplayName);
            return writer;
        };

        /**
         * Encodes the specified SkuAttribute message, length delimited. Does not implicitly {@link odmarketservicespb.SkuAttribute.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {odmarketservicespb.ISkuAttribute} message SkuAttribute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuAttribute.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SkuAttribute message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.SkuAttribute} SkuAttribute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuAttribute.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.SkuAttribute();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.attributeKey = reader.int32();
                    break;
                case 2:
                    message.attributeKeyName = reader.string();
                    break;
                case 3:
                    message.attributeValue = reader.string();
                    break;
                case 4:
                    message.attributeValueDisplayName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SkuAttribute message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.SkuAttribute} SkuAttribute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuAttribute.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SkuAttribute message.
         * @function verify
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SkuAttribute.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attributeKey != null && message.hasOwnProperty("attributeKey"))
                if (!$util.isInteger(message.attributeKey))
                    return "attributeKey: integer expected";
            if (message.attributeKeyName != null && message.hasOwnProperty("attributeKeyName"))
                if (!$util.isString(message.attributeKeyName))
                    return "attributeKeyName: string expected";
            if (message.attributeValue != null && message.hasOwnProperty("attributeValue"))
                if (!$util.isString(message.attributeValue))
                    return "attributeValue: string expected";
            if (message.attributeValueDisplayName != null && message.hasOwnProperty("attributeValueDisplayName"))
                if (!$util.isString(message.attributeValueDisplayName))
                    return "attributeValueDisplayName: string expected";
            return null;
        };

        /**
         * Creates a SkuAttribute message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.SkuAttribute} SkuAttribute
         */
        SkuAttribute.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.SkuAttribute)
                return object;
            let message = new $root.odmarketservicespb.SkuAttribute();
            if (object.attributeKey != null)
                message.attributeKey = object.attributeKey | 0;
            if (object.attributeKeyName != null)
                message.attributeKeyName = String(object.attributeKeyName);
            if (object.attributeValue != null)
                message.attributeValue = String(object.attributeValue);
            if (object.attributeValueDisplayName != null)
                message.attributeValueDisplayName = String(object.attributeValueDisplayName);
            return message;
        };

        /**
         * Creates a plain object from a SkuAttribute message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.SkuAttribute
         * @static
         * @param {odmarketservicespb.SkuAttribute} message SkuAttribute
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SkuAttribute.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.attributeKey = 0;
                object.attributeKeyName = "";
                object.attributeValue = "";
                object.attributeValueDisplayName = "";
            }
            if (message.attributeKey != null && message.hasOwnProperty("attributeKey"))
                object.attributeKey = message.attributeKey;
            if (message.attributeKeyName != null && message.hasOwnProperty("attributeKeyName"))
                object.attributeKeyName = message.attributeKeyName;
            if (message.attributeValue != null && message.hasOwnProperty("attributeValue"))
                object.attributeValue = message.attributeValue;
            if (message.attributeValueDisplayName != null && message.hasOwnProperty("attributeValueDisplayName"))
                object.attributeValueDisplayName = message.attributeValueDisplayName;
            return object;
        };

        /**
         * Converts this SkuAttribute to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.SkuAttribute
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SkuAttribute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SkuAttribute;
    })();

    odmarketservicespb.SkuPackage = (function() {

        /**
         * Properties of a SkuPackage.
         * @memberof odmarketservicespb
         * @interface ISkuPackage
         * @property {number|null} [skuPackageLegacyId] SkuPackage skuPackageLegacyId
         * @property {string|null} [name] SkuPackage name
         */

        /**
         * Constructs a new SkuPackage.
         * @memberof odmarketservicespb
         * @classdesc Represents a SkuPackage.
         * @implements ISkuPackage
         * @constructor
         * @param {odmarketservicespb.ISkuPackage=} [properties] Properties to set
         */
        function SkuPackage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkuPackage skuPackageLegacyId.
         * @member {number} skuPackageLegacyId
         * @memberof odmarketservicespb.SkuPackage
         * @instance
         */
        SkuPackage.prototype.skuPackageLegacyId = 0;

        /**
         * SkuPackage name.
         * @member {string} name
         * @memberof odmarketservicespb.SkuPackage
         * @instance
         */
        SkuPackage.prototype.name = "";

        /**
         * Creates a new SkuPackage instance using the specified properties.
         * @function create
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {odmarketservicespb.ISkuPackage=} [properties] Properties to set
         * @returns {odmarketservicespb.SkuPackage} SkuPackage instance
         */
        SkuPackage.create = function create(properties) {
            return new SkuPackage(properties);
        };

        /**
         * Encodes the specified SkuPackage message. Does not implicitly {@link odmarketservicespb.SkuPackage.verify|verify} messages.
         * @function encode
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {odmarketservicespb.ISkuPackage} message SkuPackage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuPackage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skuPackageLegacyId != null && Object.hasOwnProperty.call(message, "skuPackageLegacyId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.skuPackageLegacyId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SkuPackage message, length delimited. Does not implicitly {@link odmarketservicespb.SkuPackage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {odmarketservicespb.ISkuPackage} message SkuPackage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuPackage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SkuPackage message from the specified reader or buffer.
         * @function decode
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odmarketservicespb.SkuPackage} SkuPackage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuPackage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odmarketservicespb.SkuPackage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.skuPackageLegacyId = reader.int32();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SkuPackage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odmarketservicespb.SkuPackage} SkuPackage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuPackage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SkuPackage message.
         * @function verify
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SkuPackage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.skuPackageLegacyId != null && message.hasOwnProperty("skuPackageLegacyId"))
                if (!$util.isInteger(message.skuPackageLegacyId))
                    return "skuPackageLegacyId: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SkuPackage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odmarketservicespb.SkuPackage} SkuPackage
         */
        SkuPackage.fromObject = function fromObject(object) {
            if (object instanceof $root.odmarketservicespb.SkuPackage)
                return object;
            let message = new $root.odmarketservicespb.SkuPackage();
            if (object.skuPackageLegacyId != null)
                message.skuPackageLegacyId = object.skuPackageLegacyId | 0;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SkuPackage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odmarketservicespb.SkuPackage
         * @static
         * @param {odmarketservicespb.SkuPackage} message SkuPackage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SkuPackage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.skuPackageLegacyId = 0;
                object.name = "";
            }
            if (message.skuPackageLegacyId != null && message.hasOwnProperty("skuPackageLegacyId"))
                object.skuPackageLegacyId = message.skuPackageLegacyId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SkuPackage to JSON.
         * @function toJSON
         * @memberof odmarketservicespb.SkuPackage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SkuPackage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SkuPackage;
    })();

    odmarketservicespb.AvailableSkus = (function() {

        /**
         * Constructs a new AvailableSkus service.
         * @memberof odmarketservicespb
         * @classdesc Represents an AvailableSkus
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function AvailableSkus(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (AvailableSkus.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AvailableSkus;

        /**
         * Creates new AvailableSkus service using the specified rpc implementation.
         * @function create
         * @memberof odmarketservicespb.AvailableSkus
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {AvailableSkus} RPC service. Useful where requests and/or responses are streamed.
         */
        AvailableSkus.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link odmarketservicespb.AvailableSkus#get}.
         * @memberof odmarketservicespb.AvailableSkus
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {odmarketservicespb.AvailableSkusResponse} [response] AvailableSkusResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof odmarketservicespb.AvailableSkus
         * @instance
         * @param {odmarketservicespb.IAvailableSkusQueryRequest} request AvailableSkusQueryRequest message or plain object
         * @param {odmarketservicespb.AvailableSkus.GetCallback} callback Node-style callback called with the error, if any, and AvailableSkusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AvailableSkus.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.odmarketservicespb.AvailableSkusQueryRequest, $root.odmarketservicespb.AvailableSkusResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof odmarketservicespb.AvailableSkus
         * @instance
         * @param {odmarketservicespb.IAvailableSkusQueryRequest} request AvailableSkusQueryRequest message or plain object
         * @returns {Promise<odmarketservicespb.AvailableSkusResponse>} Promise
         * @variation 2
         */

        return AvailableSkus;
    })();

    odmarketservicespb.MarketByZipCode = (function() {

        /**
         * Constructs a new MarketByZipCode service.
         * @memberof odmarketservicespb
         * @classdesc Represents a MarketByZipCode
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MarketByZipCode(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MarketByZipCode.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MarketByZipCode;

        /**
         * Creates new MarketByZipCode service using the specified rpc implementation.
         * @function create
         * @memberof odmarketservicespb.MarketByZipCode
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {MarketByZipCode} RPC service. Useful where requests and/or responses are streamed.
         */
        MarketByZipCode.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link odmarketservicespb.MarketByZipCode#get}.
         * @memberof odmarketservicespb.MarketByZipCode
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {odmarketservicespb.MarketByZipCodeResponse} [response] MarketByZipCodeResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof odmarketservicespb.MarketByZipCode
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {odmarketservicespb.MarketByZipCode.GetCallback} callback Node-style callback called with the error, if any, and MarketByZipCodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketByZipCode.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.google.protobuf.Empty, $root.odmarketservicespb.MarketByZipCodeResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof odmarketservicespb.MarketByZipCode
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<odmarketservicespb.MarketByZipCodeResponse>} Promise
         * @variation 2
         */

        return MarketByZipCode;
    })();

    odmarketservicespb.ServiceTypesByMarket = (function() {

        /**
         * Constructs a new ServiceTypesByMarket service.
         * @memberof odmarketservicespb
         * @classdesc Represents a ServiceTypesByMarket
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ServiceTypesByMarket(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ServiceTypesByMarket.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ServiceTypesByMarket;

        /**
         * Creates new ServiceTypesByMarket service using the specified rpc implementation.
         * @function create
         * @memberof odmarketservicespb.ServiceTypesByMarket
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ServiceTypesByMarket} RPC service. Useful where requests and/or responses are streamed.
         */
        ServiceTypesByMarket.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link odmarketservicespb.ServiceTypesByMarket#get}.
         * @memberof odmarketservicespb.ServiceTypesByMarket
         * @typedef GetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {odmarketservicespb.ServiceTypesByMarketResponse} [response] ServiceTypesByMarketResponse
         */

        /**
         * Calls Get.
         * @function get
         * @memberof odmarketservicespb.ServiceTypesByMarket
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {odmarketservicespb.ServiceTypesByMarket.GetCallback} callback Node-style callback called with the error, if any, and ServiceTypesByMarketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ServiceTypesByMarket.prototype.get = function get(request, callback) {
            return this.rpcCall(get, $root.google.protobuf.Empty, $root.odmarketservicespb.ServiceTypesByMarketResponse, request, callback);
        }, "name", { value: "Get" });

        /**
         * Calls Get.
         * @function get
         * @memberof odmarketservicespb.ServiceTypesByMarket
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<odmarketservicespb.ServiceTypesByMarketResponse>} Promise
         * @variation 2
         */

        return ServiceTypesByMarket;
    })();

    return odmarketservicespb;
})();

export const common = $root.common = (() => {

    /**
     * Namespace common.
     * @exports common
     * @namespace
     */
    const common = {};

    /**
     * Tenant enum.
     * @name common.Tenant
     * @enum {number}
     * @property {number} TENANT_UNDEFINED=0 TENANT_UNDEFINED value
     * @property {number} TEST=1 TEST value
     * @property {number} AHS=2 AHS value
     * @property {number} CD=3 CD value
     * @property {number} HSA=4 HSA value
     * @property {number} OG=5 OG value
     * @property {number} LM=6 LM value
     * @property {number} FRONTDOOR=7 FRONTDOOR value
     * @property {number} AHSPC=8 AHSPC value
     * @property {number} HSAPC=9 HSAPC value
     * @property {number} FTDR=10 FTDR value
     */
    common.Tenant = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TENANT_UNDEFINED"] = 0;
        values[valuesById[1] = "TEST"] = 1;
        values[valuesById[2] = "AHS"] = 2;
        values[valuesById[3] = "CD"] = 3;
        values[valuesById[4] = "HSA"] = 4;
        values[valuesById[5] = "OG"] = 5;
        values[valuesById[6] = "LM"] = 6;
        values[valuesById[7] = "FRONTDOOR"] = 7;
        values[valuesById[8] = "AHSPC"] = 8;
        values[valuesById[9] = "HSAPC"] = 9;
        values[valuesById[10] = "FTDR"] = 10;
        return values;
    })();

    /**
     * Alert enum.
     * @name common.Alert
     * @enum {number}
     * @property {number} ALERTS_UNDEFINED=0 ALERTS_UNDEFINED value
     * @property {number} ALERTS_UNSCHEDULED=1 ALERTS_UNSCHEDULED value
     * @property {number} ALERTS_NEED_STATUS_UPDATE=2 ALERTS_NEED_STATUS_UPDATE value
     * @property {number} ALERTS_OPEN_LONGER_THAN_AVERAGE=3 ALERTS_OPEN_LONGER_THAN_AVERAGE value
     * @property {number} ALERTS_SCHEDULE_NEXT_APPOINTMENT=4 ALERTS_SCHEDULE_NEXT_APPOINTMENT value
     * @property {number} ALERTS_RESUBMIT_REQUEST=5 ALERTS_RESUBMIT_REQUEST value
     * @property {number} ALERTS_TODAYS_APPOINTMENT=6 ALERTS_TODAYS_APPOINTMENT value
     * @property {number} ALERTS_AUTHO_STATUS_UPDATED=7 ALERTS_AUTHO_STATUS_UPDATED value
     */
    common.Alert = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ALERTS_UNDEFINED"] = 0;
        values[valuesById[1] = "ALERTS_UNSCHEDULED"] = 1;
        values[valuesById[2] = "ALERTS_NEED_STATUS_UPDATE"] = 2;
        values[valuesById[3] = "ALERTS_OPEN_LONGER_THAN_AVERAGE"] = 3;
        values[valuesById[4] = "ALERTS_SCHEDULE_NEXT_APPOINTMENT"] = 4;
        values[valuesById[5] = "ALERTS_RESUBMIT_REQUEST"] = 5;
        values[valuesById[6] = "ALERTS_TODAYS_APPOINTMENT"] = 6;
        values[valuesById[7] = "ALERTS_AUTHO_STATUS_UPDATED"] = 7;
        return values;
    })();

    /**
     * Status enum.
     * @name common.Status
     * @enum {number}
     * @property {number} STATUS_UNDEFINED=0 STATUS_UNDEFINED value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} FAIL=2 FAIL value
     */
    common.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATUS_UNDEFINED"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "FAIL"] = 2;
        return values;
    })();

    /**
     * UserRole enum.
     * @name common.UserRole
     * @enum {number}
     * @property {number} USER_ROLE_UNDEFINED=0 USER_ROLE_UNDEFINED value
     * @property {number} CONTRACTOR=1 CONTRACTOR value
     * @property {number} AGENT=2 AGENT value
     * @property {number} ADMIN=3 ADMIN value
     * @property {number} TECHNICIAN=4 TECHNICIAN value
     * @property {number} PARTNER=5 PARTNER value
     * @property {number} CUSTOMER=6 CUSTOMER value
     * @property {number} DEVELOPER=7 DEVELOPER value
     * @property {number} EXTERNAL_PARTNER=8 EXTERNAL_PARTNER value
     */
    common.UserRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_ROLE_UNDEFINED"] = 0;
        values[valuesById[1] = "CONTRACTOR"] = 1;
        values[valuesById[2] = "AGENT"] = 2;
        values[valuesById[3] = "ADMIN"] = 3;
        values[valuesById[4] = "TECHNICIAN"] = 4;
        values[valuesById[5] = "PARTNER"] = 5;
        values[valuesById[6] = "CUSTOMER"] = 6;
        values[valuesById[7] = "DEVELOPER"] = 7;
        values[valuesById[8] = "EXTERNAL_PARTNER"] = 8;
        return values;
    })();

    common.HealthCheckDetail = (function() {

        /**
         * Properties of a HealthCheckDetail.
         * @memberof common
         * @interface IHealthCheckDetail
         * @property {string|null} [componentID] HealthCheckDetail componentID
         * @property {string|null} [type] HealthCheckDetail type
         * @property {number|null} [observedValue] HealthCheckDetail observedValue
         * @property {string|null} [observedUnit] HealthCheckDetail observedUnit
         * @property {string|null} [status] HealthCheckDetail status
         * @property {google.protobuf.ITimestamp|null} [time] HealthCheckDetail time
         * @property {string|null} [output] HealthCheckDetail output
         */

        /**
         * Constructs a new HealthCheckDetail.
         * @memberof common
         * @classdesc Represents a HealthCheckDetail.
         * @implements IHealthCheckDetail
         * @constructor
         * @param {common.IHealthCheckDetail=} [properties] Properties to set
         */
        function HealthCheckDetail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HealthCheckDetail componentID.
         * @member {string} componentID
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.componentID = "";

        /**
         * HealthCheckDetail type.
         * @member {string} type
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.type = "";

        /**
         * HealthCheckDetail observedValue.
         * @member {number} observedValue
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.observedValue = 0;

        /**
         * HealthCheckDetail observedUnit.
         * @member {string} observedUnit
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.observedUnit = "";

        /**
         * HealthCheckDetail status.
         * @member {string} status
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.status = "";

        /**
         * HealthCheckDetail time.
         * @member {google.protobuf.ITimestamp|null|undefined} time
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.time = null;

        /**
         * HealthCheckDetail output.
         * @member {string} output
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.output = "";

        /**
         * Creates a new HealthCheckDetail instance using the specified properties.
         * @function create
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail=} [properties] Properties to set
         * @returns {common.HealthCheckDetail} HealthCheckDetail instance
         */
        HealthCheckDetail.create = function create(properties) {
            return new HealthCheckDetail(properties);
        };

        /**
         * Encodes the specified HealthCheckDetail message. Does not implicitly {@link common.HealthCheckDetail.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail} message HealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.componentID != null && Object.hasOwnProperty.call(message, "componentID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.componentID);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.observedValue != null && Object.hasOwnProperty.call(message, "observedValue"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.observedValue);
            if (message.observedUnit != null && Object.hasOwnProperty.call(message, "observedUnit"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.observedUnit);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.status);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.google.protobuf.Timestamp.encode(message.time, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.output);
            return writer;
        };

        /**
         * Encodes the specified HealthCheckDetail message, length delimited. Does not implicitly {@link common.HealthCheckDetail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail} message HealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckDetail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HealthCheckDetail message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.HealthCheckDetail} HealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.HealthCheckDetail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.componentID = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.observedValue = reader.double();
                    break;
                case 4:
                    message.observedUnit = reader.string();
                    break;
                case 5:
                    message.status = reader.string();
                    break;
                case 6:
                    message.time = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.output = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HealthCheckDetail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.HealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.HealthCheckDetail} HealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckDetail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HealthCheckDetail message.
         * @function verify
         * @memberof common.HealthCheckDetail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HealthCheckDetail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.componentID != null && message.hasOwnProperty("componentID"))
                if (!$util.isString(message.componentID))
                    return "componentID: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            if (message.observedValue != null && message.hasOwnProperty("observedValue"))
                if (typeof message.observedValue !== "number")
                    return "observedValue: number expected";
            if (message.observedUnit != null && message.hasOwnProperty("observedUnit"))
                if (!$util.isString(message.observedUnit))
                    return "observedUnit: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.time != null && message.hasOwnProperty("time")) {
                let error = $root.google.protobuf.Timestamp.verify(message.time);
                if (error)
                    return "time." + error;
            }
            if (message.output != null && message.hasOwnProperty("output"))
                if (!$util.isString(message.output))
                    return "output: string expected";
            return null;
        };

        /**
         * Creates a HealthCheckDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.HealthCheckDetail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.HealthCheckDetail} HealthCheckDetail
         */
        HealthCheckDetail.fromObject = function fromObject(object) {
            if (object instanceof $root.common.HealthCheckDetail)
                return object;
            let message = new $root.common.HealthCheckDetail();
            if (object.componentID != null)
                message.componentID = String(object.componentID);
            if (object.type != null)
                message.type = String(object.type);
            if (object.observedValue != null)
                message.observedValue = Number(object.observedValue);
            if (object.observedUnit != null)
                message.observedUnit = String(object.observedUnit);
            if (object.status != null)
                message.status = String(object.status);
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".common.HealthCheckDetail.time: object expected");
                message.time = $root.google.protobuf.Timestamp.fromObject(object.time);
            }
            if (object.output != null)
                message.output = String(object.output);
            return message;
        };

        /**
         * Creates a plain object from a HealthCheckDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.HealthCheckDetail} message HealthCheckDetail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HealthCheckDetail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.componentID = "";
                object.type = "";
                object.observedValue = 0;
                object.observedUnit = "";
                object.status = "";
                object.time = null;
                object.output = "";
            }
            if (message.componentID != null && message.hasOwnProperty("componentID"))
                object.componentID = message.componentID;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.observedValue != null && message.hasOwnProperty("observedValue"))
                object.observedValue = options.json && !isFinite(message.observedValue) ? String(message.observedValue) : message.observedValue;
            if (message.observedUnit != null && message.hasOwnProperty("observedUnit"))
                object.observedUnit = message.observedUnit;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = $root.google.protobuf.Timestamp.toObject(message.time, options);
            if (message.output != null && message.hasOwnProperty("output"))
                object.output = message.output;
            return object;
        };

        /**
         * Converts this HealthCheckDetail to JSON.
         * @function toJSON
         * @memberof common.HealthCheckDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HealthCheckDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HealthCheckDetail;
    })();

    common.NamedHealthCheckDetail = (function() {

        /**
         * Properties of a NamedHealthCheckDetail.
         * @memberof common
         * @interface INamedHealthCheckDetail
         * @property {string|null} [name] NamedHealthCheckDetail name
         * @property {Array.<common.IHealthCheckDetail>|null} [details] NamedHealthCheckDetail details
         */

        /**
         * Constructs a new NamedHealthCheckDetail.
         * @memberof common
         * @classdesc Represents a NamedHealthCheckDetail.
         * @implements INamedHealthCheckDetail
         * @constructor
         * @param {common.INamedHealthCheckDetail=} [properties] Properties to set
         */
        function NamedHealthCheckDetail(properties) {
            this.details = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NamedHealthCheckDetail name.
         * @member {string} name
         * @memberof common.NamedHealthCheckDetail
         * @instance
         */
        NamedHealthCheckDetail.prototype.name = "";

        /**
         * NamedHealthCheckDetail details.
         * @member {Array.<common.IHealthCheckDetail>} details
         * @memberof common.NamedHealthCheckDetail
         * @instance
         */
        NamedHealthCheckDetail.prototype.details = $util.emptyArray;

        /**
         * Creates a new NamedHealthCheckDetail instance using the specified properties.
         * @function create
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail=} [properties] Properties to set
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail instance
         */
        NamedHealthCheckDetail.create = function create(properties) {
            return new NamedHealthCheckDetail(properties);
        };

        /**
         * Encodes the specified NamedHealthCheckDetail message. Does not implicitly {@link common.NamedHealthCheckDetail.verify|verify} messages.
         * @function encode
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail} message NamedHealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedHealthCheckDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.details != null && message.details.length)
                for (let i = 0; i < message.details.length; ++i)
                    $root.common.HealthCheckDetail.encode(message.details[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NamedHealthCheckDetail message, length delimited. Does not implicitly {@link common.NamedHealthCheckDetail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail} message NamedHealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedHealthCheckDetail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NamedHealthCheckDetail message from the specified reader or buffer.
         * @function decode
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedHealthCheckDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.NamedHealthCheckDetail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.common.HealthCheckDetail.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NamedHealthCheckDetail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedHealthCheckDetail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NamedHealthCheckDetail message.
         * @function verify
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NamedHealthCheckDetail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.details != null && message.hasOwnProperty("details")) {
                if (!Array.isArray(message.details))
                    return "details: array expected";
                for (let i = 0; i < message.details.length; ++i) {
                    let error = $root.common.HealthCheckDetail.verify(message.details[i]);
                    if (error)
                        return "details." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NamedHealthCheckDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail
         */
        NamedHealthCheckDetail.fromObject = function fromObject(object) {
            if (object instanceof $root.common.NamedHealthCheckDetail)
                return object;
            let message = new $root.common.NamedHealthCheckDetail();
            if (object.name != null)
                message.name = String(object.name);
            if (object.details) {
                if (!Array.isArray(object.details))
                    throw TypeError(".common.NamedHealthCheckDetail.details: array expected");
                message.details = [];
                for (let i = 0; i < object.details.length; ++i) {
                    if (typeof object.details[i] !== "object")
                        throw TypeError(".common.NamedHealthCheckDetail.details: object expected");
                    message.details[i] = $root.common.HealthCheckDetail.fromObject(object.details[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NamedHealthCheckDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.NamedHealthCheckDetail} message NamedHealthCheckDetail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NamedHealthCheckDetail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.details = [];
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.details && message.details.length) {
                object.details = [];
                for (let j = 0; j < message.details.length; ++j)
                    object.details[j] = $root.common.HealthCheckDetail.toObject(message.details[j], options);
            }
            return object;
        };

        /**
         * Converts this NamedHealthCheckDetail to JSON.
         * @function toJSON
         * @memberof common.NamedHealthCheckDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NamedHealthCheckDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NamedHealthCheckDetail;
    })();

    common.HealthCheck = (function() {

        /**
         * Properties of a HealthCheck.
         * @memberof common
         * @interface IHealthCheck
         * @property {string|null} [status] HealthCheck status
         * @property {string|null} [version] HealthCheck version
         * @property {string|null} [releaseID] HealthCheck releaseID
         * @property {Array.<string>|null} [notes] HealthCheck notes
         * @property {string|null} [output] HealthCheck output
         * @property {Array.<common.INamedHealthCheckDetail>|null} [details] HealthCheck details
         */

        /**
         * Constructs a new HealthCheck.
         * @memberof common
         * @classdesc Represents a HealthCheck.
         * @implements IHealthCheck
         * @constructor
         * @param {common.IHealthCheck=} [properties] Properties to set
         */
        function HealthCheck(properties) {
            this.notes = [];
            this.details = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HealthCheck status.
         * @member {string} status
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.status = "";

        /**
         * HealthCheck version.
         * @member {string} version
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.version = "";

        /**
         * HealthCheck releaseID.
         * @member {string} releaseID
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.releaseID = "";

        /**
         * HealthCheck notes.
         * @member {Array.<string>} notes
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.notes = $util.emptyArray;

        /**
         * HealthCheck output.
         * @member {string} output
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.output = "";

        /**
         * HealthCheck details.
         * @member {Array.<common.INamedHealthCheckDetail>} details
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.details = $util.emptyArray;

        /**
         * Creates a new HealthCheck instance using the specified properties.
         * @function create
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck=} [properties] Properties to set
         * @returns {common.HealthCheck} HealthCheck instance
         */
        HealthCheck.create = function create(properties) {
            return new HealthCheck(properties);
        };

        /**
         * Encodes the specified HealthCheck message. Does not implicitly {@link common.HealthCheck.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck} message HealthCheck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
            if (message.releaseID != null && Object.hasOwnProperty.call(message, "releaseID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.releaseID);
            if (message.notes != null && message.notes.length)
                for (let i = 0; i < message.notes.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notes[i]);
            if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.output);
            if (message.details != null && message.details.length)
                for (let i = 0; i < message.details.length; ++i)
                    $root.common.NamedHealthCheckDetail.encode(message.details[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HealthCheck message, length delimited. Does not implicitly {@link common.HealthCheck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck} message HealthCheck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HealthCheck message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.HealthCheck} HealthCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.HealthCheck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                case 3:
                    message.releaseID = reader.string();
                    break;
                case 4:
                    if (!(message.notes && message.notes.length))
                        message.notes = [];
                    message.notes.push(reader.string());
                    break;
                case 5:
                    message.output = reader.string();
                    break;
                case 6:
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.common.NamedHealthCheckDetail.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HealthCheck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.HealthCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.HealthCheck} HealthCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HealthCheck message.
         * @function verify
         * @memberof common.HealthCheck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HealthCheck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.releaseID != null && message.hasOwnProperty("releaseID"))
                if (!$util.isString(message.releaseID))
                    return "releaseID: string expected";
            if (message.notes != null && message.hasOwnProperty("notes")) {
                if (!Array.isArray(message.notes))
                    return "notes: array expected";
                for (let i = 0; i < message.notes.length; ++i)
                    if (!$util.isString(message.notes[i]))
                        return "notes: string[] expected";
            }
            if (message.output != null && message.hasOwnProperty("output"))
                if (!$util.isString(message.output))
                    return "output: string expected";
            if (message.details != null && message.hasOwnProperty("details")) {
                if (!Array.isArray(message.details))
                    return "details: array expected";
                for (let i = 0; i < message.details.length; ++i) {
                    let error = $root.common.NamedHealthCheckDetail.verify(message.details[i]);
                    if (error)
                        return "details." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HealthCheck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.HealthCheck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.HealthCheck} HealthCheck
         */
        HealthCheck.fromObject = function fromObject(object) {
            if (object instanceof $root.common.HealthCheck)
                return object;
            let message = new $root.common.HealthCheck();
            if (object.status != null)
                message.status = String(object.status);
            if (object.version != null)
                message.version = String(object.version);
            if (object.releaseID != null)
                message.releaseID = String(object.releaseID);
            if (object.notes) {
                if (!Array.isArray(object.notes))
                    throw TypeError(".common.HealthCheck.notes: array expected");
                message.notes = [];
                for (let i = 0; i < object.notes.length; ++i)
                    message.notes[i] = String(object.notes[i]);
            }
            if (object.output != null)
                message.output = String(object.output);
            if (object.details) {
                if (!Array.isArray(object.details))
                    throw TypeError(".common.HealthCheck.details: array expected");
                message.details = [];
                for (let i = 0; i < object.details.length; ++i) {
                    if (typeof object.details[i] !== "object")
                        throw TypeError(".common.HealthCheck.details: object expected");
                    message.details[i] = $root.common.NamedHealthCheckDetail.fromObject(object.details[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HealthCheck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.HealthCheck
         * @static
         * @param {common.HealthCheck} message HealthCheck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HealthCheck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.notes = [];
                object.details = [];
            }
            if (options.defaults) {
                object.status = "";
                object.version = "";
                object.releaseID = "";
                object.output = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.releaseID != null && message.hasOwnProperty("releaseID"))
                object.releaseID = message.releaseID;
            if (message.notes && message.notes.length) {
                object.notes = [];
                for (let j = 0; j < message.notes.length; ++j)
                    object.notes[j] = message.notes[j];
            }
            if (message.output != null && message.hasOwnProperty("output"))
                object.output = message.output;
            if (message.details && message.details.length) {
                object.details = [];
                for (let j = 0; j < message.details.length; ++j)
                    object.details[j] = $root.common.NamedHealthCheckDetail.toObject(message.details[j], options);
            }
            return object;
        };

        /**
         * Converts this HealthCheck to JSON.
         * @function toJSON
         * @memberof common.HealthCheck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HealthCheck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HealthCheck;
    })();

    common.HealthCheckResponse = (function() {

        /**
         * Properties of a HealthCheckResponse.
         * @memberof common
         * @interface IHealthCheckResponse
         * @property {common.IHealthCheck|null} [health] HealthCheckResponse health
         */

        /**
         * Constructs a new HealthCheckResponse.
         * @memberof common
         * @classdesc Represents a HealthCheckResponse.
         * @implements IHealthCheckResponse
         * @constructor
         * @param {common.IHealthCheckResponse=} [properties] Properties to set
         */
        function HealthCheckResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HealthCheckResponse health.
         * @member {common.IHealthCheck|null|undefined} health
         * @memberof common.HealthCheckResponse
         * @instance
         */
        HealthCheckResponse.prototype.health = null;

        /**
         * Creates a new HealthCheckResponse instance using the specified properties.
         * @function create
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse=} [properties] Properties to set
         * @returns {common.HealthCheckResponse} HealthCheckResponse instance
         */
        HealthCheckResponse.create = function create(properties) {
            return new HealthCheckResponse(properties);
        };

        /**
         * Encodes the specified HealthCheckResponse message. Does not implicitly {@link common.HealthCheckResponse.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse} message HealthCheckResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.health != null && Object.hasOwnProperty.call(message, "health"))
                $root.common.HealthCheck.encode(message.health, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HealthCheckResponse message, length delimited. Does not implicitly {@link common.HealthCheckResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse} message HealthCheckResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HealthCheckResponse message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheckResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.HealthCheckResponse} HealthCheckResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.HealthCheckResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.health = $root.common.HealthCheck.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HealthCheckResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.HealthCheckResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.HealthCheckResponse} HealthCheckResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HealthCheckResponse message.
         * @function verify
         * @memberof common.HealthCheckResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HealthCheckResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.health != null && message.hasOwnProperty("health")) {
                let error = $root.common.HealthCheck.verify(message.health);
                if (error)
                    return "health." + error;
            }
            return null;
        };

        /**
         * Creates a HealthCheckResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.HealthCheckResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.HealthCheckResponse} HealthCheckResponse
         */
        HealthCheckResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.common.HealthCheckResponse)
                return object;
            let message = new $root.common.HealthCheckResponse();
            if (object.health != null) {
                if (typeof object.health !== "object")
                    throw TypeError(".common.HealthCheckResponse.health: object expected");
                message.health = $root.common.HealthCheck.fromObject(object.health);
            }
            return message;
        };

        /**
         * Creates a plain object from a HealthCheckResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.HealthCheckResponse} message HealthCheckResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HealthCheckResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.health = null;
            if (message.health != null && message.hasOwnProperty("health"))
                object.health = $root.common.HealthCheck.toObject(message.health, options);
            return object;
        };

        /**
         * Converts this HealthCheckResponse to JSON.
         * @function toJSON
         * @memberof common.HealthCheckResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HealthCheckResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HealthCheckResponse;
    })();

    common.Timeslot = (function() {

        /**
         * Properties of a Timeslot.
         * @memberof common
         * @interface ITimeslot
         * @property {google.protobuf.ITimestamp|null} [start] Timeslot start
         * @property {google.protobuf.ITimestamp|null} [end] Timeslot end
         */

        /**
         * Constructs a new Timeslot.
         * @memberof common
         * @classdesc Represents a Timeslot.
         * @implements ITimeslot
         * @constructor
         * @param {common.ITimeslot=} [properties] Properties to set
         */
        function Timeslot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Timeslot start.
         * @member {google.protobuf.ITimestamp|null|undefined} start
         * @memberof common.Timeslot
         * @instance
         */
        Timeslot.prototype.start = null;

        /**
         * Timeslot end.
         * @member {google.protobuf.ITimestamp|null|undefined} end
         * @memberof common.Timeslot
         * @instance
         */
        Timeslot.prototype.end = null;

        /**
         * Creates a new Timeslot instance using the specified properties.
         * @function create
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot=} [properties] Properties to set
         * @returns {common.Timeslot} Timeslot instance
         */
        Timeslot.create = function create(properties) {
            return new Timeslot(properties);
        };

        /**
         * Encodes the specified Timeslot message. Does not implicitly {@link common.Timeslot.verify|verify} messages.
         * @function encode
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot} message Timeslot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Timeslot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.google.protobuf.Timestamp.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.google.protobuf.Timestamp.encode(message.end, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Timeslot message, length delimited. Does not implicitly {@link common.Timeslot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot} message Timeslot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Timeslot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Timeslot message from the specified reader or buffer.
         * @function decode
         * @memberof common.Timeslot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Timeslot} Timeslot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Timeslot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Timeslot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.start = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.end = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Timeslot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Timeslot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Timeslot} Timeslot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Timeslot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Timeslot message.
         * @function verify
         * @memberof common.Timeslot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Timeslot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.google.protobuf.Timestamp.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.google.protobuf.Timestamp.verify(message.end);
                if (error)
                    return "end." + error;
            }
            return null;
        };

        /**
         * Creates a Timeslot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Timeslot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Timeslot} Timeslot
         */
        Timeslot.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Timeslot)
                return object;
            let message = new $root.common.Timeslot();
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".common.Timeslot.start: object expected");
                message.start = $root.google.protobuf.Timestamp.fromObject(object.start);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".common.Timeslot.end: object expected");
                message.end = $root.google.protobuf.Timestamp.fromObject(object.end);
            }
            return message;
        };

        /**
         * Creates a plain object from a Timeslot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Timeslot
         * @static
         * @param {common.Timeslot} message Timeslot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Timeslot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.start = null;
                object.end = null;
            }
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.google.protobuf.Timestamp.toObject(message.start, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.google.protobuf.Timestamp.toObject(message.end, options);
            return object;
        };

        /**
         * Converts this Timeslot to JSON.
         * @function toJSON
         * @memberof common.Timeslot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Timeslot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Timeslot;
    })();

    common.ErrorResponse = (function() {

        /**
         * Properties of an ErrorResponse.
         * @memberof common
         * @interface IErrorResponse
         * @property {Array.<common.IError>|null} [errors] ErrorResponse errors
         */

        /**
         * Constructs a new ErrorResponse.
         * @memberof common
         * @classdesc Represents an ErrorResponse.
         * @implements IErrorResponse
         * @constructor
         * @param {common.IErrorResponse=} [properties] Properties to set
         */
        function ErrorResponse(properties) {
            this.errors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ErrorResponse errors.
         * @member {Array.<common.IError>} errors
         * @memberof common.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.errors = $util.emptyArray;

        /**
         * Creates a new ErrorResponse instance using the specified properties.
         * @function create
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse=} [properties] Properties to set
         * @returns {common.ErrorResponse} ErrorResponse instance
         */
        ErrorResponse.create = function create(properties) {
            return new ErrorResponse(properties);
        };

        /**
         * Encodes the specified ErrorResponse message. Does not implicitly {@link common.ErrorResponse.verify|verify} messages.
         * @function encode
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse} message ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errors != null && message.errors.length)
                for (let i = 0; i < message.errors.length; ++i)
                    $root.common.Error.encode(message.errors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ErrorResponse message, length delimited. Does not implicitly {@link common.ErrorResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse} message ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof common.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.ErrorResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.common.Error.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ErrorResponse message.
         * @function verify
         * @memberof common.ErrorResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ErrorResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errors != null && message.hasOwnProperty("errors")) {
                if (!Array.isArray(message.errors))
                    return "errors: array expected";
                for (let i = 0; i < message.errors.length; ++i) {
                    let error = $root.common.Error.verify(message.errors[i]);
                    if (error)
                        return "errors." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ErrorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.ErrorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.ErrorResponse} ErrorResponse
         */
        ErrorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.common.ErrorResponse)
                return object;
            let message = new $root.common.ErrorResponse();
            if (object.errors) {
                if (!Array.isArray(object.errors))
                    throw TypeError(".common.ErrorResponse.errors: array expected");
                message.errors = [];
                for (let i = 0; i < object.errors.length; ++i) {
                    if (typeof object.errors[i] !== "object")
                        throw TypeError(".common.ErrorResponse.errors: object expected");
                    message.errors[i] = $root.common.Error.fromObject(object.errors[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ErrorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.ErrorResponse
         * @static
         * @param {common.ErrorResponse} message ErrorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ErrorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.errors = [];
            if (message.errors && message.errors.length) {
                object.errors = [];
                for (let j = 0; j < message.errors.length; ++j)
                    object.errors[j] = $root.common.Error.toObject(message.errors[j], options);
            }
            return object;
        };

        /**
         * Converts this ErrorResponse to JSON.
         * @function toJSON
         * @memberof common.ErrorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ErrorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ErrorResponse;
    })();

    common.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof common
         * @interface IError
         * @property {string|null} [message] Error message
         * @property {string|null} [code] Error code
         */

        /**
         * Constructs a new Error.
         * @memberof common
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {common.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error message.
         * @member {string} message
         * @memberof common.Error
         * @instance
         */
        Error.prototype.message = "";

        /**
         * Error code.
         * @member {string} code
         * @memberof common.Error
         * @instance
         */
        Error.prototype.code = "";

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof common.Error
         * @static
         * @param {common.IError=} [properties] Properties to set
         * @returns {common.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link common.Error.verify|verify} messages.
         * @function encode
         * @memberof common.Error
         * @static
         * @param {common.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link common.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Error
         * @static
         * @param {common.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof common.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Error();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof common.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Error)
                return object;
            let message = new $root.common.Error();
            if (object.message != null)
                message.message = String(object.message);
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Error
         * @static
         * @param {common.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.message = "";
                object.code = "";
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof common.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Error;
    })();

    common.Money = (function() {

        /**
         * Properties of a Money.
         * @memberof common
         * @interface IMoney
         * @property {string|null} [currencyCode] Money currencyCode
         * @property {number|Long|null} [units] Money units
         * @property {number|null} [nanos] Money nanos
         */

        /**
         * Constructs a new Money.
         * @memberof common
         * @classdesc Represents a Money.
         * @implements IMoney
         * @constructor
         * @param {common.IMoney=} [properties] Properties to set
         */
        function Money(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Money currencyCode.
         * @member {string} currencyCode
         * @memberof common.Money
         * @instance
         */
        Money.prototype.currencyCode = "";

        /**
         * Money units.
         * @member {number|Long} units
         * @memberof common.Money
         * @instance
         */
        Money.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Money nanos.
         * @member {number} nanos
         * @memberof common.Money
         * @instance
         */
        Money.prototype.nanos = 0;

        /**
         * Creates a new Money instance using the specified properties.
         * @function create
         * @memberof common.Money
         * @static
         * @param {common.IMoney=} [properties] Properties to set
         * @returns {common.Money} Money instance
         */
        Money.create = function create(properties) {
            return new Money(properties);
        };

        /**
         * Encodes the specified Money message. Does not implicitly {@link common.Money.verify|verify} messages.
         * @function encode
         * @memberof common.Money
         * @static
         * @param {common.IMoney} message Money message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Money.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.currencyCode);
            if (message.units != null && Object.hasOwnProperty.call(message, "units"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.units);
            if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.nanos);
            return writer;
        };

        /**
         * Encodes the specified Money message, length delimited. Does not implicitly {@link common.Money.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Money
         * @static
         * @param {common.IMoney} message Money message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Money.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Money message from the specified reader or buffer.
         * @function decode
         * @memberof common.Money
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Money} Money
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Money.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Money();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyCode = reader.string();
                    break;
                case 2:
                    message.units = reader.int64();
                    break;
                case 3:
                    message.nanos = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Money message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Money
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Money} Money
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Money.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Money message.
         * @function verify
         * @memberof common.Money
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Money.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                if (!$util.isString(message.currencyCode))
                    return "currencyCode: string expected";
            if (message.units != null && message.hasOwnProperty("units"))
                if (!$util.isInteger(message.units) && !(message.units && $util.isInteger(message.units.low) && $util.isInteger(message.units.high)))
                    return "units: integer|Long expected";
            if (message.nanos != null && message.hasOwnProperty("nanos"))
                if (!$util.isInteger(message.nanos))
                    return "nanos: integer expected";
            return null;
        };

        /**
         * Creates a Money message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Money
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Money} Money
         */
        Money.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Money)
                return object;
            let message = new $root.common.Money();
            if (object.currencyCode != null)
                message.currencyCode = String(object.currencyCode);
            if (object.units != null)
                if ($util.Long)
                    (message.units = $util.Long.fromValue(object.units)).unsigned = false;
                else if (typeof object.units === "string")
                    message.units = parseInt(object.units, 10);
                else if (typeof object.units === "number")
                    message.units = object.units;
                else if (typeof object.units === "object")
                    message.units = new $util.LongBits(object.units.low >>> 0, object.units.high >>> 0).toNumber();
            if (object.nanos != null)
                message.nanos = object.nanos | 0;
            return message;
        };

        /**
         * Creates a plain object from a Money message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Money
         * @static
         * @param {common.Money} message Money
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Money.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currencyCode = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.units = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.units = options.longs === String ? "0" : 0;
                object.nanos = 0;
            }
            if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                object.currencyCode = message.currencyCode;
            if (message.units != null && message.hasOwnProperty("units"))
                if (typeof message.units === "number")
                    object.units = options.longs === String ? String(message.units) : message.units;
                else
                    object.units = options.longs === String ? $util.Long.prototype.toString.call(message.units) : options.longs === Number ? new $util.LongBits(message.units.low >>> 0, message.units.high >>> 0).toNumber() : message.units;
            if (message.nanos != null && message.hasOwnProperty("nanos"))
                object.nanos = message.nanos;
            return object;
        };

        /**
         * Converts this Money to JSON.
         * @function toJSON
         * @memberof common.Money
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Money.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Money;
    })();

    common.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof common
         * @interface IDate
         * @property {number|null} [year] Date year
         * @property {number|null} [month] Date month
         * @property {number|null} [day] Date day
         */

        /**
         * Constructs a new Date.
         * @memberof common
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {common.IDate=} [properties] Properties to set
         */
        function Date(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Date year.
         * @member {number} year
         * @memberof common.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof common.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof common.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof common.Date
         * @static
         * @param {common.IDate=} [properties] Properties to set
         * @returns {common.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link common.Date.verify|verify} messages.
         * @function encode
         * @memberof common.Date
         * @static
         * @param {common.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
            if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.day);
            return writer;
        };

        /**
         * Encodes the specified Date message, length delimited. Does not implicitly {@link common.Date.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Date
         * @static
         * @param {common.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof common.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Date();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.year = reader.int32();
                    break;
                case 2:
                    message.month = reader.int32();
                    break;
                case 3:
                    message.day = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Date message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Date message.
         * @function verify
         * @memberof common.Date
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Date.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.year != null && message.hasOwnProperty("year"))
                if (!$util.isInteger(message.year))
                    return "year: integer expected";
            if (message.month != null && message.hasOwnProperty("month"))
                if (!$util.isInteger(message.month))
                    return "month: integer expected";
            if (message.day != null && message.hasOwnProperty("day"))
                if (!$util.isInteger(message.day))
                    return "day: integer expected";
            return null;
        };

        /**
         * Creates a Date message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Date
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Date} Date
         */
        Date.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Date)
                return object;
            let message = new $root.common.Date();
            if (object.year != null)
                message.year = object.year | 0;
            if (object.month != null)
                message.month = object.month | 0;
            if (object.day != null)
                message.day = object.day | 0;
            return message;
        };

        /**
         * Creates a plain object from a Date message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Date
         * @static
         * @param {common.Date} message Date
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Date.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.year = 0;
                object.month = 0;
                object.day = 0;
            }
            if (message.year != null && message.hasOwnProperty("year"))
                object.year = message.year;
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = message.month;
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = message.day;
            return object;
        };

        /**
         * Converts this Date to JSON.
         * @function toJSON
         * @memberof common.Date
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Date.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Date;
    })();

    common.GeoLocation = (function() {

        /**
         * Properties of a GeoLocation.
         * @memberof common
         * @interface IGeoLocation
         * @property {number|null} [latitude] GeoLocation latitude
         * @property {number|null} [longitude] GeoLocation longitude
         */

        /**
         * Constructs a new GeoLocation.
         * @memberof common
         * @classdesc Represents a GeoLocation.
         * @implements IGeoLocation
         * @constructor
         * @param {common.IGeoLocation=} [properties] Properties to set
         */
        function GeoLocation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GeoLocation latitude.
         * @member {number} latitude
         * @memberof common.GeoLocation
         * @instance
         */
        GeoLocation.prototype.latitude = 0;

        /**
         * GeoLocation longitude.
         * @member {number} longitude
         * @memberof common.GeoLocation
         * @instance
         */
        GeoLocation.prototype.longitude = 0;

        /**
         * Creates a new GeoLocation instance using the specified properties.
         * @function create
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation=} [properties] Properties to set
         * @returns {common.GeoLocation} GeoLocation instance
         */
        GeoLocation.create = function create(properties) {
            return new GeoLocation(properties);
        };

        /**
         * Encodes the specified GeoLocation message. Does not implicitly {@link common.GeoLocation.verify|verify} messages.
         * @function encode
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation} message GeoLocation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeoLocation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.longitude);
            return writer;
        };

        /**
         * Encodes the specified GeoLocation message, length delimited. Does not implicitly {@link common.GeoLocation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation} message GeoLocation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeoLocation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GeoLocation message from the specified reader or buffer.
         * @function decode
         * @memberof common.GeoLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.GeoLocation} GeoLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeoLocation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.GeoLocation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.latitude = reader.float();
                    break;
                case 2:
                    message.longitude = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GeoLocation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.GeoLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.GeoLocation} GeoLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeoLocation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GeoLocation message.
         * @function verify
         * @memberof common.GeoLocation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GeoLocation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                if (typeof message.latitude !== "number")
                    return "latitude: number expected";
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                if (typeof message.longitude !== "number")
                    return "longitude: number expected";
            return null;
        };

        /**
         * Creates a GeoLocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.GeoLocation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.GeoLocation} GeoLocation
         */
        GeoLocation.fromObject = function fromObject(object) {
            if (object instanceof $root.common.GeoLocation)
                return object;
            let message = new $root.common.GeoLocation();
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            return message;
        };

        /**
         * Creates a plain object from a GeoLocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.GeoLocation
         * @static
         * @param {common.GeoLocation} message GeoLocation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GeoLocation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.latitude = 0;
                object.longitude = 0;
            }
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            return object;
        };

        /**
         * Converts this GeoLocation to JSON.
         * @function toJSON
         * @memberof common.GeoLocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GeoLocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GeoLocation;
    })();

    /**
     * Locale enum.
     * @name common.Locale
     * @enum {number}
     * @property {number} EN=0 EN value
     * @property {number} ES=1 ES value
     */
    common.Locale = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EN"] = 0;
        values[valuesById[1] = "ES"] = 1;
        return values;
    })();

    common.Range_U32 = (function() {

        /**
         * Properties of a Range_U32.
         * @memberof common
         * @interface IRange_U32
         * @property {number|null} [min] Range_U32 min
         * @property {number|null} [max] Range_U32 max
         */

        /**
         * Constructs a new Range_U32.
         * @memberof common
         * @classdesc Represents a Range_U32.
         * @implements IRange_U32
         * @constructor
         * @param {common.IRange_U32=} [properties] Properties to set
         */
        function Range_U32(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Range_U32 min.
         * @member {number} min
         * @memberof common.Range_U32
         * @instance
         */
        Range_U32.prototype.min = 0;

        /**
         * Range_U32 max.
         * @member {number} max
         * @memberof common.Range_U32
         * @instance
         */
        Range_U32.prototype.max = 0;

        /**
         * Creates a new Range_U32 instance using the specified properties.
         * @function create
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32=} [properties] Properties to set
         * @returns {common.Range_U32} Range_U32 instance
         */
        Range_U32.create = function create(properties) {
            return new Range_U32(properties);
        };

        /**
         * Encodes the specified Range_U32 message. Does not implicitly {@link common.Range_U32.verify|verify} messages.
         * @function encode
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32} message Range_U32 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Range_U32.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.min);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.max);
            return writer;
        };

        /**
         * Encodes the specified Range_U32 message, length delimited. Does not implicitly {@link common.Range_U32.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32} message Range_U32 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Range_U32.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Range_U32 message from the specified reader or buffer.
         * @function decode
         * @memberof common.Range_U32
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Range_U32} Range_U32
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Range_U32.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Range_U32();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.min = reader.uint32();
                    break;
                case 2:
                    message.max = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Range_U32 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Range_U32
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Range_U32} Range_U32
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Range_U32.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Range_U32 message.
         * @function verify
         * @memberof common.Range_U32
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Range_U32.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.min != null && message.hasOwnProperty("min"))
                if (!$util.isInteger(message.min))
                    return "min: integer expected";
            if (message.max != null && message.hasOwnProperty("max"))
                if (!$util.isInteger(message.max))
                    return "max: integer expected";
            return null;
        };

        /**
         * Creates a Range_U32 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Range_U32
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Range_U32} Range_U32
         */
        Range_U32.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Range_U32)
                return object;
            let message = new $root.common.Range_U32();
            if (object.min != null)
                message.min = object.min >>> 0;
            if (object.max != null)
                message.max = object.max >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Range_U32 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Range_U32
         * @static
         * @param {common.Range_U32} message Range_U32
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Range_U32.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.min = 0;
                object.max = 0;
            }
            if (message.min != null && message.hasOwnProperty("min"))
                object.min = message.min;
            if (message.max != null && message.hasOwnProperty("max"))
                object.max = message.max;
            return object;
        };

        /**
         * Converts this Range_U32 to JSON.
         * @function toJSON
         * @memberof common.Range_U32
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Range_U32.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Range_U32;
    })();

    common.ApplicationSource = (function() {

        /**
         * Properties of an ApplicationSource.
         * @memberof common
         * @interface IApplicationSource
         * @property {string|null} [name] ApplicationSource name
         * @property {string|null} [version] ApplicationSource version
         * @property {string|null} [authorVersion] ApplicationSource authorVersion
         */

        /**
         * Constructs a new ApplicationSource.
         * @memberof common
         * @classdesc Represents an ApplicationSource.
         * @implements IApplicationSource
         * @constructor
         * @param {common.IApplicationSource=} [properties] Properties to set
         */
        function ApplicationSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApplicationSource name.
         * @member {string} name
         * @memberof common.ApplicationSource
         * @instance
         */
        ApplicationSource.prototype.name = "";

        /**
         * ApplicationSource version.
         * @member {string} version
         * @memberof common.ApplicationSource
         * @instance
         */
        ApplicationSource.prototype.version = "";

        /**
         * ApplicationSource authorVersion.
         * @member {string} authorVersion
         * @memberof common.ApplicationSource
         * @instance
         */
        ApplicationSource.prototype.authorVersion = "";

        /**
         * Creates a new ApplicationSource instance using the specified properties.
         * @function create
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource=} [properties] Properties to set
         * @returns {common.ApplicationSource} ApplicationSource instance
         */
        ApplicationSource.create = function create(properties) {
            return new ApplicationSource(properties);
        };

        /**
         * Encodes the specified ApplicationSource message. Does not implicitly {@link common.ApplicationSource.verify|verify} messages.
         * @function encode
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource} message ApplicationSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplicationSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
            if (message.authorVersion != null && Object.hasOwnProperty.call(message, "authorVersion"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.authorVersion);
            return writer;
        };

        /**
         * Encodes the specified ApplicationSource message, length delimited. Does not implicitly {@link common.ApplicationSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource} message ApplicationSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplicationSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApplicationSource message from the specified reader or buffer.
         * @function decode
         * @memberof common.ApplicationSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.ApplicationSource} ApplicationSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplicationSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.ApplicationSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                case 3:
                    message.authorVersion = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApplicationSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.ApplicationSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.ApplicationSource} ApplicationSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplicationSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApplicationSource message.
         * @function verify
         * @memberof common.ApplicationSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApplicationSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.authorVersion != null && message.hasOwnProperty("authorVersion"))
                if (!$util.isString(message.authorVersion))
                    return "authorVersion: string expected";
            return null;
        };

        /**
         * Creates an ApplicationSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.ApplicationSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.ApplicationSource} ApplicationSource
         */
        ApplicationSource.fromObject = function fromObject(object) {
            if (object instanceof $root.common.ApplicationSource)
                return object;
            let message = new $root.common.ApplicationSource();
            if (object.name != null)
                message.name = String(object.name);
            if (object.version != null)
                message.version = String(object.version);
            if (object.authorVersion != null)
                message.authorVersion = String(object.authorVersion);
            return message;
        };

        /**
         * Creates a plain object from an ApplicationSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.ApplicationSource
         * @static
         * @param {common.ApplicationSource} message ApplicationSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApplicationSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.version = "";
                object.authorVersion = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.authorVersion != null && message.hasOwnProperty("authorVersion"))
                object.authorVersion = message.authorVersion;
            return object;
        };

        /**
         * Converts this ApplicationSource to JSON.
         * @function toJSON
         * @memberof common.ApplicationSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApplicationSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ApplicationSource;
    })();

    common.Pagination = (function() {

        /**
         * Properties of a Pagination.
         * @memberof common
         * @interface IPagination
         * @property {number|null} [currentPage] Pagination currentPage
         * @property {number|null} [itemsPerPage] Pagination itemsPerPage
         * @property {number|null} [totalPages] Pagination totalPages
         * @property {number|null} [totalRecords] Pagination totalRecords
         */

        /**
         * Constructs a new Pagination.
         * @memberof common
         * @classdesc Represents a Pagination.
         * @implements IPagination
         * @constructor
         * @param {common.IPagination=} [properties] Properties to set
         */
        function Pagination(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Pagination currentPage.
         * @member {number} currentPage
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.currentPage = 0;

        /**
         * Pagination itemsPerPage.
         * @member {number} itemsPerPage
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.itemsPerPage = 0;

        /**
         * Pagination totalPages.
         * @member {number} totalPages
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.totalPages = 0;

        /**
         * Pagination totalRecords.
         * @member {number} totalRecords
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.totalRecords = 0;

        /**
         * Creates a new Pagination instance using the specified properties.
         * @function create
         * @memberof common.Pagination
         * @static
         * @param {common.IPagination=} [properties] Properties to set
         * @returns {common.Pagination} Pagination instance
         */
        Pagination.create = function create(properties) {
            return new Pagination(properties);
        };

        /**
         * Encodes the specified Pagination message. Does not implicitly {@link common.Pagination.verify|verify} messages.
         * @function encode
         * @memberof common.Pagination
         * @static
         * @param {common.IPagination} message Pagination message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pagination.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentPage != null && Object.hasOwnProperty.call(message, "currentPage"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currentPage);
            if (message.itemsPerPage != null && Object.hasOwnProperty.call(message, "itemsPerPage"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.itemsPerPage);
            if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPages);
            if (message.totalRecords != null && Object.hasOwnProperty.call(message, "totalRecords"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalRecords);
            return writer;
        };

        /**
         * Encodes the specified Pagination message, length delimited. Does not implicitly {@link common.Pagination.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Pagination
         * @static
         * @param {common.IPagination} message Pagination message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pagination.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Pagination message from the specified reader or buffer.
         * @function decode
         * @memberof common.Pagination
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Pagination} Pagination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pagination.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Pagination();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currentPage = reader.int32();
                    break;
                case 2:
                    message.itemsPerPage = reader.int32();
                    break;
                case 3:
                    message.totalPages = reader.int32();
                    break;
                case 4:
                    message.totalRecords = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Pagination message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Pagination
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Pagination} Pagination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pagination.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Pagination message.
         * @function verify
         * @memberof common.Pagination
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pagination.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                if (!$util.isInteger(message.currentPage))
                    return "currentPage: integer expected";
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                if (!$util.isInteger(message.itemsPerPage))
                    return "itemsPerPage: integer expected";
            if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                if (!$util.isInteger(message.totalPages))
                    return "totalPages: integer expected";
            if (message.totalRecords != null && message.hasOwnProperty("totalRecords"))
                if (!$util.isInteger(message.totalRecords))
                    return "totalRecords: integer expected";
            return null;
        };

        /**
         * Creates a Pagination message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Pagination
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Pagination} Pagination
         */
        Pagination.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Pagination)
                return object;
            let message = new $root.common.Pagination();
            if (object.currentPage != null)
                message.currentPage = object.currentPage | 0;
            if (object.itemsPerPage != null)
                message.itemsPerPage = object.itemsPerPage | 0;
            if (object.totalPages != null)
                message.totalPages = object.totalPages | 0;
            if (object.totalRecords != null)
                message.totalRecords = object.totalRecords | 0;
            return message;
        };

        /**
         * Creates a plain object from a Pagination message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Pagination
         * @static
         * @param {common.Pagination} message Pagination
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pagination.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currentPage = 0;
                object.itemsPerPage = 0;
                object.totalPages = 0;
                object.totalRecords = 0;
            }
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                object.currentPage = message.currentPage;
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                object.itemsPerPage = message.itemsPerPage;
            if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                object.totalPages = message.totalPages;
            if (message.totalRecords != null && message.hasOwnProperty("totalRecords"))
                object.totalRecords = message.totalRecords;
            return object;
        };

        /**
         * Converts this Pagination to JSON.
         * @function toJSON
         * @memberof common.Pagination
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pagination.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Pagination;
    })();

    common.PaginationRequest = (function() {

        /**
         * Properties of a PaginationRequest.
         * @memberof common
         * @interface IPaginationRequest
         * @property {number|null} [currentPage] PaginationRequest currentPage
         * @property {number|null} [itemsPerPage] PaginationRequest itemsPerPage
         */

        /**
         * Constructs a new PaginationRequest.
         * @memberof common
         * @classdesc Represents a PaginationRequest.
         * @implements IPaginationRequest
         * @constructor
         * @param {common.IPaginationRequest=} [properties] Properties to set
         */
        function PaginationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginationRequest currentPage.
         * @member {number} currentPage
         * @memberof common.PaginationRequest
         * @instance
         */
        PaginationRequest.prototype.currentPage = 0;

        /**
         * PaginationRequest itemsPerPage.
         * @member {number} itemsPerPage
         * @memberof common.PaginationRequest
         * @instance
         */
        PaginationRequest.prototype.itemsPerPage = 0;

        /**
         * Creates a new PaginationRequest instance using the specified properties.
         * @function create
         * @memberof common.PaginationRequest
         * @static
         * @param {common.IPaginationRequest=} [properties] Properties to set
         * @returns {common.PaginationRequest} PaginationRequest instance
         */
        PaginationRequest.create = function create(properties) {
            return new PaginationRequest(properties);
        };

        /**
         * Encodes the specified PaginationRequest message. Does not implicitly {@link common.PaginationRequest.verify|verify} messages.
         * @function encode
         * @memberof common.PaginationRequest
         * @static
         * @param {common.IPaginationRequest} message PaginationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaginationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentPage != null && Object.hasOwnProperty.call(message, "currentPage"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentPage);
            if (message.itemsPerPage != null && Object.hasOwnProperty.call(message, "itemsPerPage"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.itemsPerPage);
            return writer;
        };

        /**
         * Encodes the specified PaginationRequest message, length delimited. Does not implicitly {@link common.PaginationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.PaginationRequest
         * @static
         * @param {common.IPaginationRequest} message PaginationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaginationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaginationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof common.PaginationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.PaginationRequest} PaginationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaginationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.PaginationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currentPage = reader.uint32();
                    break;
                case 2:
                    message.itemsPerPage = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PaginationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.PaginationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.PaginationRequest} PaginationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaginationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PaginationRequest message.
         * @function verify
         * @memberof common.PaginationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PaginationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                if (!$util.isInteger(message.currentPage))
                    return "currentPage: integer expected";
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                if (!$util.isInteger(message.itemsPerPage))
                    return "itemsPerPage: integer expected";
            return null;
        };

        /**
         * Creates a PaginationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.PaginationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.PaginationRequest} PaginationRequest
         */
        PaginationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.common.PaginationRequest)
                return object;
            let message = new $root.common.PaginationRequest();
            if (object.currentPage != null)
                message.currentPage = object.currentPage >>> 0;
            if (object.itemsPerPage != null)
                message.itemsPerPage = object.itemsPerPage >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a PaginationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.PaginationRequest
         * @static
         * @param {common.PaginationRequest} message PaginationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaginationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currentPage = 0;
                object.itemsPerPage = 0;
            }
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                object.currentPage = message.currentPage;
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                object.itemsPerPage = message.itemsPerPage;
            return object;
        };

        /**
         * Converts this PaginationRequest to JSON.
         * @function toJSON
         * @memberof common.PaginationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaginationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PaginationRequest;
    })();

    /**
     * USStateAlphaCode enum.
     * @name common.USStateAlphaCode
     * @enum {number}
     * @property {number} UnknownState=0 UnknownState value
     * @property {number} AL=1 AL value
     * @property {number} AK=2 AK value
     * @property {number} AZ=3 AZ value
     * @property {number} AR=4 AR value
     * @property {number} CA=5 CA value
     * @property {number} CO=6 CO value
     * @property {number} CT=7 CT value
     * @property {number} DE=11 DE value
     * @property {number} DC=12 DC value
     * @property {number} FL=13 FL value
     * @property {number} GA=21 GA value
     * @property {number} HI=22 HI value
     * @property {number} ID=23 ID value
     * @property {number} IL=24 IL value
     * @property {number} IN=25 IN value
     * @property {number} IA=26 IA value
     * @property {number} KS=31 KS value
     * @property {number} KY=32 KY value
     * @property {number} LA=33 LA value
     * @property {number} ME=41 ME value
     * @property {number} MD=42 MD value
     * @property {number} MA=43 MA value
     * @property {number} MI=44 MI value
     * @property {number} MN=45 MN value
     * @property {number} MS=46 MS value
     * @property {number} MO=47 MO value
     * @property {number} MT=48 MT value
     * @property {number} NE=51 NE value
     * @property {number} NV=52 NV value
     * @property {number} NH=53 NH value
     * @property {number} NJ=54 NJ value
     * @property {number} NM=55 NM value
     * @property {number} NY=56 NY value
     * @property {number} NC=57 NC value
     * @property {number} ND=58 ND value
     * @property {number} OH=61 OH value
     * @property {number} OK=62 OK value
     * @property {number} OR=63 OR value
     * @property {number} PA=64 PA value
     * @property {number} RI=65 RI value
     * @property {number} SC=66 SC value
     * @property {number} SD=67 SD value
     * @property {number} TN=71 TN value
     * @property {number} TX=72 TX value
     * @property {number} UT=73 UT value
     * @property {number} VT=74 VT value
     * @property {number} VA=75 VA value
     * @property {number} WA=76 WA value
     * @property {number} WV=77 WV value
     * @property {number} WI=78 WI value
     * @property {number} WY=79 WY value
     */
    common.USStateAlphaCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UnknownState"] = 0;
        values[valuesById[1] = "AL"] = 1;
        values[valuesById[2] = "AK"] = 2;
        values[valuesById[3] = "AZ"] = 3;
        values[valuesById[4] = "AR"] = 4;
        values[valuesById[5] = "CA"] = 5;
        values[valuesById[6] = "CO"] = 6;
        values[valuesById[7] = "CT"] = 7;
        values[valuesById[11] = "DE"] = 11;
        values[valuesById[12] = "DC"] = 12;
        values[valuesById[13] = "FL"] = 13;
        values[valuesById[21] = "GA"] = 21;
        values[valuesById[22] = "HI"] = 22;
        values[valuesById[23] = "ID"] = 23;
        values[valuesById[24] = "IL"] = 24;
        values[valuesById[25] = "IN"] = 25;
        values[valuesById[26] = "IA"] = 26;
        values[valuesById[31] = "KS"] = 31;
        values[valuesById[32] = "KY"] = 32;
        values[valuesById[33] = "LA"] = 33;
        values[valuesById[41] = "ME"] = 41;
        values[valuesById[42] = "MD"] = 42;
        values[valuesById[43] = "MA"] = 43;
        values[valuesById[44] = "MI"] = 44;
        values[valuesById[45] = "MN"] = 45;
        values[valuesById[46] = "MS"] = 46;
        values[valuesById[47] = "MO"] = 47;
        values[valuesById[48] = "MT"] = 48;
        values[valuesById[51] = "NE"] = 51;
        values[valuesById[52] = "NV"] = 52;
        values[valuesById[53] = "NH"] = 53;
        values[valuesById[54] = "NJ"] = 54;
        values[valuesById[55] = "NM"] = 55;
        values[valuesById[56] = "NY"] = 56;
        values[valuesById[57] = "NC"] = 57;
        values[valuesById[58] = "ND"] = 58;
        values[valuesById[61] = "OH"] = 61;
        values[valuesById[62] = "OK"] = 62;
        values[valuesById[63] = "OR"] = 63;
        values[valuesById[64] = "PA"] = 64;
        values[valuesById[65] = "RI"] = 65;
        values[valuesById[66] = "SC"] = 66;
        values[valuesById[67] = "SD"] = 67;
        values[valuesById[71] = "TN"] = 71;
        values[valuesById[72] = "TX"] = 72;
        values[valuesById[73] = "UT"] = 73;
        values[valuesById[74] = "VT"] = 74;
        values[valuesById[75] = "VA"] = 75;
        values[valuesById[76] = "WA"] = 76;
        values[valuesById[77] = "WV"] = 77;
        values[valuesById[78] = "WI"] = 78;
        values[valuesById[79] = "WY"] = 79;
        return values;
    })();

    common.AuditTrailEntry = (function() {

        /**
         * Properties of an AuditTrailEntry.
         * @memberof common
         * @interface IAuditTrailEntry
         * @property {google.protobuf.ITimestamp|null} [createdOn] AuditTrailEntry createdOn
         * @property {string|null} [createdBy] AuditTrailEntry createdBy
         * @property {Array.<common.AuditTrailEntry.IAuditTrailEntryDetail>|null} [details] AuditTrailEntry details
         */

        /**
         * Constructs a new AuditTrailEntry.
         * @memberof common
         * @classdesc Represents an AuditTrailEntry.
         * @implements IAuditTrailEntry
         * @constructor
         * @param {common.IAuditTrailEntry=} [properties] Properties to set
         */
        function AuditTrailEntry(properties) {
            this.details = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuditTrailEntry createdOn.
         * @member {google.protobuf.ITimestamp|null|undefined} createdOn
         * @memberof common.AuditTrailEntry
         * @instance
         */
        AuditTrailEntry.prototype.createdOn = null;

        /**
         * AuditTrailEntry createdBy.
         * @member {string} createdBy
         * @memberof common.AuditTrailEntry
         * @instance
         */
        AuditTrailEntry.prototype.createdBy = "";

        /**
         * AuditTrailEntry details.
         * @member {Array.<common.AuditTrailEntry.IAuditTrailEntryDetail>} details
         * @memberof common.AuditTrailEntry
         * @instance
         */
        AuditTrailEntry.prototype.details = $util.emptyArray;

        /**
         * Creates a new AuditTrailEntry instance using the specified properties.
         * @function create
         * @memberof common.AuditTrailEntry
         * @static
         * @param {common.IAuditTrailEntry=} [properties] Properties to set
         * @returns {common.AuditTrailEntry} AuditTrailEntry instance
         */
        AuditTrailEntry.create = function create(properties) {
            return new AuditTrailEntry(properties);
        };

        /**
         * Encodes the specified AuditTrailEntry message. Does not implicitly {@link common.AuditTrailEntry.verify|verify} messages.
         * @function encode
         * @memberof common.AuditTrailEntry
         * @static
         * @param {common.IAuditTrailEntry} message AuditTrailEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuditTrailEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createdOn != null && Object.hasOwnProperty.call(message, "createdOn"))
                $root.google.protobuf.Timestamp.encode(message.createdOn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdBy);
            if (message.details != null && message.details.length)
                for (let i = 0; i < message.details.length; ++i)
                    $root.common.AuditTrailEntry.AuditTrailEntryDetail.encode(message.details[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuditTrailEntry message, length delimited. Does not implicitly {@link common.AuditTrailEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.AuditTrailEntry
         * @static
         * @param {common.IAuditTrailEntry} message AuditTrailEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuditTrailEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuditTrailEntry message from the specified reader or buffer.
         * @function decode
         * @memberof common.AuditTrailEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.AuditTrailEntry} AuditTrailEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuditTrailEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.AuditTrailEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.createdOn = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.createdBy = reader.string();
                    break;
                case 3:
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.common.AuditTrailEntry.AuditTrailEntryDetail.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuditTrailEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.AuditTrailEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.AuditTrailEntry} AuditTrailEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuditTrailEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuditTrailEntry message.
         * @function verify
         * @memberof common.AuditTrailEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuditTrailEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.createdOn != null && message.hasOwnProperty("createdOn")) {
                let error = $root.google.protobuf.Timestamp.verify(message.createdOn);
                if (error)
                    return "createdOn." + error;
            }
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                if (!$util.isString(message.createdBy))
                    return "createdBy: string expected";
            if (message.details != null && message.hasOwnProperty("details")) {
                if (!Array.isArray(message.details))
                    return "details: array expected";
                for (let i = 0; i < message.details.length; ++i) {
                    let error = $root.common.AuditTrailEntry.AuditTrailEntryDetail.verify(message.details[i]);
                    if (error)
                        return "details." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AuditTrailEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.AuditTrailEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.AuditTrailEntry} AuditTrailEntry
         */
        AuditTrailEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.common.AuditTrailEntry)
                return object;
            let message = new $root.common.AuditTrailEntry();
            if (object.createdOn != null) {
                if (typeof object.createdOn !== "object")
                    throw TypeError(".common.AuditTrailEntry.createdOn: object expected");
                message.createdOn = $root.google.protobuf.Timestamp.fromObject(object.createdOn);
            }
            if (object.createdBy != null)
                message.createdBy = String(object.createdBy);
            if (object.details) {
                if (!Array.isArray(object.details))
                    throw TypeError(".common.AuditTrailEntry.details: array expected");
                message.details = [];
                for (let i = 0; i < object.details.length; ++i) {
                    if (typeof object.details[i] !== "object")
                        throw TypeError(".common.AuditTrailEntry.details: object expected");
                    message.details[i] = $root.common.AuditTrailEntry.AuditTrailEntryDetail.fromObject(object.details[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AuditTrailEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.AuditTrailEntry
         * @static
         * @param {common.AuditTrailEntry} message AuditTrailEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuditTrailEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.details = [];
            if (options.defaults) {
                object.createdOn = null;
                object.createdBy = "";
            }
            if (message.createdOn != null && message.hasOwnProperty("createdOn"))
                object.createdOn = $root.google.protobuf.Timestamp.toObject(message.createdOn, options);
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                object.createdBy = message.createdBy;
            if (message.details && message.details.length) {
                object.details = [];
                for (let j = 0; j < message.details.length; ++j)
                    object.details[j] = $root.common.AuditTrailEntry.AuditTrailEntryDetail.toObject(message.details[j], options);
            }
            return object;
        };

        /**
         * Converts this AuditTrailEntry to JSON.
         * @function toJSON
         * @memberof common.AuditTrailEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuditTrailEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        AuditTrailEntry.AuditTrailEntryDetail = (function() {

            /**
             * Properties of an AuditTrailEntryDetail.
             * @memberof common.AuditTrailEntry
             * @interface IAuditTrailEntryDetail
             * @property {string|null} [type] AuditTrailEntryDetail type
             * @property {Object.<string,string>|null} [old] AuditTrailEntryDetail old
             * @property {Object.<string,string>|null} ["new"] AuditTrailEntryDetail new
             */

            /**
             * Constructs a new AuditTrailEntryDetail.
             * @memberof common.AuditTrailEntry
             * @classdesc Represents an AuditTrailEntryDetail.
             * @implements IAuditTrailEntryDetail
             * @constructor
             * @param {common.AuditTrailEntry.IAuditTrailEntryDetail=} [properties] Properties to set
             */
            function AuditTrailEntryDetail(properties) {
                this.old = {};
                this["new"] = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AuditTrailEntryDetail type.
             * @member {string} type
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @instance
             */
            AuditTrailEntryDetail.prototype.type = "";

            /**
             * AuditTrailEntryDetail old.
             * @member {Object.<string,string>} old
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @instance
             */
            AuditTrailEntryDetail.prototype.old = $util.emptyObject;

            /**
             * AuditTrailEntryDetail new.
             * @member {Object.<string,string>} new
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @instance
             */
            AuditTrailEntryDetail.prototype["new"] = $util.emptyObject;

            /**
             * Creates a new AuditTrailEntryDetail instance using the specified properties.
             * @function create
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {common.AuditTrailEntry.IAuditTrailEntryDetail=} [properties] Properties to set
             * @returns {common.AuditTrailEntry.AuditTrailEntryDetail} AuditTrailEntryDetail instance
             */
            AuditTrailEntryDetail.create = function create(properties) {
                return new AuditTrailEntryDetail(properties);
            };

            /**
             * Encodes the specified AuditTrailEntryDetail message. Does not implicitly {@link common.AuditTrailEntry.AuditTrailEntryDetail.verify|verify} messages.
             * @function encode
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {common.AuditTrailEntry.IAuditTrailEntryDetail} message AuditTrailEntryDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuditTrailEntryDetail.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                if (message.old != null && Object.hasOwnProperty.call(message, "old"))
                    for (let keys = Object.keys(message.old), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.old[keys[i]]).ldelim();
                if (message["new"] != null && Object.hasOwnProperty.call(message, "new"))
                    for (let keys = Object.keys(message["new"]), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message["new"][keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AuditTrailEntryDetail message, length delimited. Does not implicitly {@link common.AuditTrailEntry.AuditTrailEntryDetail.verify|verify} messages.
             * @function encodeDelimited
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {common.AuditTrailEntry.IAuditTrailEntryDetail} message AuditTrailEntryDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuditTrailEntryDetail.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AuditTrailEntryDetail message from the specified reader or buffer.
             * @function decode
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {common.AuditTrailEntry.AuditTrailEntryDetail} AuditTrailEntryDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuditTrailEntryDetail.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.AuditTrailEntry.AuditTrailEntryDetail(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.string();
                        break;
                    case 2:
                        if (message.old === $util.emptyObject)
                            message.old = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.old[key] = value;
                        break;
                    case 3:
                        if (message["new"] === $util.emptyObject)
                            message["new"] = {};
                        let end3 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end3) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message["new"][key] = value;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AuditTrailEntryDetail message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {common.AuditTrailEntry.AuditTrailEntryDetail} AuditTrailEntryDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuditTrailEntryDetail.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AuditTrailEntryDetail message.
             * @function verify
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuditTrailEntryDetail.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    if (!$util.isString(message.type))
                        return "type: string expected";
                if (message.old != null && message.hasOwnProperty("old")) {
                    if (!$util.isObject(message.old))
                        return "old: object expected";
                    let key = Object.keys(message.old);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message.old[key[i]]))
                            return "old: string{k:string} expected";
                }
                if (message["new"] != null && message.hasOwnProperty("new")) {
                    if (!$util.isObject(message["new"]))
                        return "new: object expected";
                    let key = Object.keys(message["new"]);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isString(message["new"][key[i]]))
                            return "new: string{k:string} expected";
                }
                return null;
            };

            /**
             * Creates an AuditTrailEntryDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {common.AuditTrailEntry.AuditTrailEntryDetail} AuditTrailEntryDetail
             */
            AuditTrailEntryDetail.fromObject = function fromObject(object) {
                if (object instanceof $root.common.AuditTrailEntry.AuditTrailEntryDetail)
                    return object;
                let message = new $root.common.AuditTrailEntry.AuditTrailEntryDetail();
                if (object.type != null)
                    message.type = String(object.type);
                if (object.old) {
                    if (typeof object.old !== "object")
                        throw TypeError(".common.AuditTrailEntry.AuditTrailEntryDetail.old: object expected");
                    message.old = {};
                    for (let keys = Object.keys(object.old), i = 0; i < keys.length; ++i)
                        message.old[keys[i]] = String(object.old[keys[i]]);
                }
                if (object["new"]) {
                    if (typeof object["new"] !== "object")
                        throw TypeError(".common.AuditTrailEntry.AuditTrailEntryDetail.new: object expected");
                    message["new"] = {};
                    for (let keys = Object.keys(object["new"]), i = 0; i < keys.length; ++i)
                        message["new"][keys[i]] = String(object["new"][keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from an AuditTrailEntryDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @static
             * @param {common.AuditTrailEntry.AuditTrailEntryDetail} message AuditTrailEntryDetail
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuditTrailEntryDetail.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults) {
                    object.old = {};
                    object["new"] = {};
                }
                if (options.defaults)
                    object.type = "";
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = message.type;
                let keys2;
                if (message.old && (keys2 = Object.keys(message.old)).length) {
                    object.old = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.old[keys2[j]] = message.old[keys2[j]];
                }
                if (message["new"] && (keys2 = Object.keys(message["new"])).length) {
                    object["new"] = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object["new"][keys2[j]] = message["new"][keys2[j]];
                }
                return object;
            };

            /**
             * Converts this AuditTrailEntryDetail to JSON.
             * @function toJSON
             * @memberof common.AuditTrailEntry.AuditTrailEntryDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuditTrailEntryDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AuditTrailEntryDetail;
        })();

        return AuditTrailEntry;
    })();

    return common;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (let i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.file && message.file.length))
                            message.file = [];
                        message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (let i = 0; i < message.file.length; ++i) {
                        let error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (let i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (let j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (let i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (let i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (let i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (let i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (let i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message["package"] = reader.string();
                        break;
                    case 3:
                        if (!(message.dependency && message.dependency.length))
                            message.dependency = [];
                        message.dependency.push(reader.string());
                        break;
                    case 10:
                        if (!(message.publicDependency && message.publicDependency.length))
                            message.publicDependency = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.publicDependency.push(reader.int32());
                        } else
                            message.publicDependency.push(reader.int32());
                        break;
                    case 11:
                        if (!(message.weakDependency && message.weakDependency.length))
                            message.weakDependency = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.weakDependency.push(reader.int32());
                        } else
                            message.weakDependency.push(reader.int32());
                        break;
                    case 4:
                        if (!(message.messageType && message.messageType.length))
                            message.messageType = [];
                        message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.service && message.service.length))
                            message.service = [];
                        message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.syntax = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (let i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (let i = 0; i < message.messageType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (let i = 0; i < message.service.length; ++i) {
                        let error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    let error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (let i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (let i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (let i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (let i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (let i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (let j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (let j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (let j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (let j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (let j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (let i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (let i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (let i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (let i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (let i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (let i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (let i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (let i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.field && message.field.length))
                            message.field = [];
                        message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.nestedType && message.nestedType.length))
                            message.nestedType = [];
                        message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.extensionRange && message.extensionRange.length))
                            message.extensionRange = [];
                        message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        if (!(message.oneofDecl && message.oneofDecl.length))
                            message.oneofDecl = [];
                        message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        if (!(message.reservedRange && message.reservedRange.length))
                            message.reservedRange = [];
                        message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                        break;
                    case 10:
                        if (!(message.reservedName && message.reservedName.length))
                            message.reservedName = [];
                        message.reservedName.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (let i = 0; i < message.field.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (let i = 0; i < message.nestedType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (let i = 0; i < message.extensionRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (let i = 0; i < message.oneofDecl.length; ++i) {
                        let error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (let i = 0; i < message.reservedRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (let i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                let message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (let i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (let i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (let i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (let i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (let i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (let i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (let j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (let j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (let j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (let j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (let j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (let j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                return writer;
            };

            /**
             * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.number = reader.int32();
                        break;
                    case 4:
                        message.label = reader.int32();
                        break;
                    case 5:
                        message.type = reader.int32();
                        break;
                    case 6:
                        message.typeName = reader.string();
                        break;
                    case 2:
                        message.extendee = reader.string();
                        break;
                    case 7:
                        message.defaultValue = reader.string();
                        break;
                    case 9:
                        message.oneofIndex = reader.int32();
                        break;
                    case 10:
                        message.jsonName = reader.string();
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (let i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.value && message.value.length))
                            message.value = [];
                        message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (let i = 0; i < message.value.length; ++i) {
                        let error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (let i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (let j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.number = reader.int32();
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (let i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.method && message.method.length))
                            message.method = [];
                        message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (let i = 0; i < message.method.length; ++i) {
                        let error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (let i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (let j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.inputType = reader.string();
                        break;
                    case 3:
                        message.outputType = reader.string();
                        break;
                    case 4:
                        message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.clientStreaming = reader.bool();
                        break;
                    case 6:
                        message.serverStreaming = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             * @property {string|null} [".common.serviceCalls"] FileOptions .common.serviceCalls
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FileOptions .common.serviceCalls.
             * @member {string} .common.serviceCalls
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype[".common.serviceCalls"] = "";

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".common.serviceCalls"] != null && Object.hasOwnProperty.call(message, ".common.serviceCalls"))
                    writer.uint32(/* id 50004, wireType 2 =*/400034).string(message[".common.serviceCalls"]);
                return writer;
            };

            /**
             * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.javaPackage = reader.string();
                        break;
                    case 8:
                        message.javaOuterClassname = reader.string();
                        break;
                    case 10:
                        message.javaMultipleFiles = reader.bool();
                        break;
                    case 20:
                        message.javaGenerateEqualsAndHash = reader.bool();
                        break;
                    case 27:
                        message.javaStringCheckUtf8 = reader.bool();
                        break;
                    case 9:
                        message.optimizeFor = reader.int32();
                        break;
                    case 11:
                        message.goPackage = reader.string();
                        break;
                    case 16:
                        message.ccGenericServices = reader.bool();
                        break;
                    case 17:
                        message.javaGenericServices = reader.bool();
                        break;
                    case 18:
                        message.pyGenericServices = reader.bool();
                        break;
                    case 23:
                        message.deprecated = reader.bool();
                        break;
                    case 31:
                        message.ccEnableArenas = reader.bool();
                        break;
                    case 36:
                        message.objcClassPrefix = reader.string();
                        break;
                    case 37:
                        message.csharpNamespace = reader.string();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 50004:
                        message[".common.serviceCalls"] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".common.serviceCalls"] != null && message.hasOwnProperty(".common.serviceCalls"))
                    if (!$util.isString(message[".common.serviceCalls"]))
                        return ".common.serviceCalls: string expected";
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                let message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".common.serviceCalls"] != null)
                    message[".common.serviceCalls"] = String(object[".common.serviceCalls"]);
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                    object[".common.serviceCalls"] = "";
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".common.serviceCalls"] != null && message.hasOwnProperty(".common.serviceCalls"))
                    object[".common.serviceCalls"] = message[".common.serviceCalls"];
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.messageSetWireFormat = reader.bool();
                        break;
                    case 2:
                        message.noStandardDescriptorAccessor = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 7:
                        message.mapEntry = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                let message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {boolean|null} [".common.doNotLog"] FieldOptions .common.doNotLog
             * @property {boolean|null} [".common.internal"] FieldOptions .common.internal
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .common.doNotLog.
             * @member {boolean} .common.doNotLog
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".common.doNotLog"] = false;

            /**
             * FieldOptions .common.internal.
             * @member {boolean} .common.internal
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".common.internal"] = false;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".common.doNotLog"] != null && Object.hasOwnProperty.call(message, ".common.doNotLog"))
                    writer.uint32(/* id 50100, wireType 0 =*/400800).bool(message[".common.doNotLog"]);
                if (message[".common.internal"] != null && Object.hasOwnProperty.call(message, ".common.internal"))
                    writer.uint32(/* id 50200, wireType 0 =*/401600).bool(message[".common.internal"]);
                return writer;
            };

            /**
             * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.ctype = reader.int32();
                        break;
                    case 2:
                        message.packed = reader.bool();
                        break;
                    case 6:
                        message.jstype = reader.int32();
                        break;
                    case 5:
                        message.lazy = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 10:
                        message.weak = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 50100:
                        message[".common.doNotLog"] = reader.bool();
                        break;
                    case 50200:
                        message[".common.internal"] = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".common.doNotLog"] != null && message.hasOwnProperty(".common.doNotLog"))
                    if (typeof message[".common.doNotLog"] !== "boolean")
                        return ".common.doNotLog: boolean expected";
                if (message[".common.internal"] != null && message.hasOwnProperty(".common.internal"))
                    if (typeof message[".common.internal"] !== "boolean")
                        return ".common.internal: boolean expected";
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                let message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".common.doNotLog"] != null)
                    message[".common.doNotLog"] = Boolean(object[".common.doNotLog"]);
                if (object[".common.internal"] != null)
                    message[".common.internal"] = Boolean(object[".common.internal"]);
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".common.doNotLog"] = false;
                    object[".common.internal"] = false;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".common.doNotLog"] != null && message.hasOwnProperty(".common.doNotLog"))
                    object[".common.doNotLog"] = message[".common.doNotLog"];
                if (message[".common.internal"] != null && message.hasOwnProperty(".common.internal"))
                    object[".common.internal"] = message[".common.internal"];
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                let message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.allowAlias = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                let message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                let message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             * @property {string|null} [".common.URI"] ServiceOptions .common.URI
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * ServiceOptions .common.URI.
             * @member {string} .common.URI
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype[".common.URI"] = "";

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".common.URI"] != null && Object.hasOwnProperty.call(message, ".common.URI"))
                    writer.uint32(/* id 50001, wireType 2 =*/400010).string(message[".common.URI"]);
                return writer;
            };

            /**
             * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 50001:
                        message[".common.URI"] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".common.URI"] != null && message.hasOwnProperty(".common.URI"))
                    if (!$util.isString(message[".common.URI"]))
                        return ".common.URI: string expected";
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                let message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".common.URI"] != null)
                    message[".common.URI"] = String(object[".common.URI"]);
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.deprecated = false;
                    object[".common.URI"] = "";
                }
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".common.URI"] != null && message.hasOwnProperty(".common.URI"))
                    object[".common.URI"] = message[".common.URI"];
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             * @property {boolean|null} [".common.allowJson"] MethodOptions .common.allowJson
             * @property {string|null} [".common.methodCalls"] MethodOptions .common.methodCalls
             * @property {string|null} [".common.httpMethod"] MethodOptions .common.httpMethod
             * @property {string|null} [".common.methodURI"] MethodOptions .common.methodURI
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MethodOptions .common.allowJson.
             * @member {boolean} .common.allowJson
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.allowJson"] = false;

            /**
             * MethodOptions .common.methodCalls.
             * @member {string} .common.methodCalls
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.methodCalls"] = "";

            /**
             * MethodOptions .common.httpMethod.
             * @member {string} .common.httpMethod
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.httpMethod"] = "";

            /**
             * MethodOptions .common.methodURI.
             * @member {string} .common.methodURI
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".common.methodURI"] = "";

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".common.allowJson"] != null && Object.hasOwnProperty.call(message, ".common.allowJson"))
                    writer.uint32(/* id 50000, wireType 0 =*/400000).bool(message[".common.allowJson"]);
                if (message[".common.methodCalls"] != null && Object.hasOwnProperty.call(message, ".common.methodCalls"))
                    writer.uint32(/* id 50002, wireType 2 =*/400018).string(message[".common.methodCalls"]);
                if (message[".common.httpMethod"] != null && Object.hasOwnProperty.call(message, ".common.httpMethod"))
                    writer.uint32(/* id 50003, wireType 2 =*/400026).string(message[".common.httpMethod"]);
                if (message[".common.methodURI"] != null && Object.hasOwnProperty.call(message, ".common.methodURI"))
                    writer.uint32(/* id 50005, wireType 2 =*/400042).string(message[".common.methodURI"]);
                return writer;
            };

            /**
             * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 50000:
                        message[".common.allowJson"] = reader.bool();
                        break;
                    case 50002:
                        message[".common.methodCalls"] = reader.string();
                        break;
                    case 50003:
                        message[".common.httpMethod"] = reader.string();
                        break;
                    case 50005:
                        message[".common.methodURI"] = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".common.allowJson"] != null && message.hasOwnProperty(".common.allowJson"))
                    if (typeof message[".common.allowJson"] !== "boolean")
                        return ".common.allowJson: boolean expected";
                if (message[".common.methodCalls"] != null && message.hasOwnProperty(".common.methodCalls"))
                    if (!$util.isString(message[".common.methodCalls"]))
                        return ".common.methodCalls: string expected";
                if (message[".common.httpMethod"] != null && message.hasOwnProperty(".common.httpMethod"))
                    if (!$util.isString(message[".common.httpMethod"]))
                        return ".common.httpMethod: string expected";
                if (message[".common.methodURI"] != null && message.hasOwnProperty(".common.methodURI"))
                    if (!$util.isString(message[".common.methodURI"]))
                        return ".common.methodURI: string expected";
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                let message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".common.allowJson"] != null)
                    message[".common.allowJson"] = Boolean(object[".common.allowJson"]);
                if (object[".common.methodCalls"] != null)
                    message[".common.methodCalls"] = String(object[".common.methodCalls"]);
                if (object[".common.httpMethod"] != null)
                    message[".common.httpMethod"] = String(object[".common.httpMethod"]);
                if (object[".common.methodURI"] != null)
                    message[".common.methodURI"] = String(object[".common.methodURI"]);
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.deprecated = false;
                    object[".common.allowJson"] = false;
                    object[".common.methodCalls"] = "";
                    object[".common.httpMethod"] = "";
                    object[".common.methodURI"] = "";
                }
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".common.allowJson"] != null && message.hasOwnProperty(".common.allowJson"))
                    object[".common.allowJson"] = message[".common.allowJson"];
                if (message[".common.methodCalls"] != null && message.hasOwnProperty(".common.methodCalls"))
                    object[".common.methodCalls"] = message[".common.methodCalls"];
                if (message[".common.httpMethod"] != null && message.hasOwnProperty(".common.httpMethod"))
                    object[".common.httpMethod"] = message[".common.httpMethod"];
                if (message[".common.methodURI"] != null && message.hasOwnProperty(".common.methodURI"))
                    object[".common.methodURI"] = message[".common.methodURI"];
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (let i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        if (!(message.name && message.name.length))
                            message.name = [];
                        message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.identifierValue = reader.string();
                        break;
                    case 4:
                        message.positiveIntValue = reader.uint64();
                        break;
                    case 5:
                        message.negativeIntValue = reader.int64();
                        break;
                    case 6:
                        message.doubleValue = reader.double();
                        break;
                    case 7:
                        message.stringValue = reader.bytes();
                        break;
                    case 8:
                        message.aggregateValue = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (let i = 0; i < message.name.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                let message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (let i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (let j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.namePart = reader.string();
                            break;
                        case 2:
                            message.isExtension = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    let message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (let i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.location && message.location.length))
                            message.location = [];
                        message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (let i = 0; i < message.location.length; ++i) {
                        let error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                let message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (let i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (let j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (let i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (let i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            if (!(message.span && message.span.length))
                                message.span = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.span.push(reader.int32());
                            } else
                                message.span.push(reader.int32());
                            break;
                        case 3:
                            message.leadingComments = reader.string();
                            break;
                        case 4:
                            message.trailingComments = reader.string();
                            break;
                        case 6:
                            if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                message.leadingDetachedComments = [];
                            message.leadingDetachedComments.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (let i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    let message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (let i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (let i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (let j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (let j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (let i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.annotation && message.annotation.length))
                            message.annotation = [];
                        message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (let i = 0; i < message.annotation.length; ++i) {
                        let error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                let message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (let i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (let j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (let i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            message.sourceFile = reader.string();
                            break;
                        case 3:
                            message.begin = reader.int32();
                            break;
                        case 4:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    let message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             */
            function Empty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Empty instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             * @returns {google.protobuf.Empty} Empty instance
             */
            Empty.create = function create(properties) {
                return new Empty(properties);
            };

            /**
             * Encodes the specified Empty message. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Empty message, length delimited. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Empty message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Empty();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Empty message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Empty message.
             * @function verify
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Empty.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Empty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Empty} Empty
             */
            Empty.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Empty)
                    return object;
                return new $root.google.protobuf.Empty();
            };

            /**
             * Creates a plain object from an Empty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.Empty} message Empty
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Empty.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Empty to JSON.
             * @function toJSON
             * @memberof google.protobuf.Empty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Empty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Empty;
        })();

        return protobuf;
    })();

    return google;
})();

export const odcommonpb = $root.odcommonpb = (() => {

    /**
     * Namespace odcommonpb.
     * @exports odcommonpb
     * @namespace
     */
    const odcommonpb = {};

    /**
     * Status enum.
     * @name odcommonpb.Status
     * @enum {number}
     * @property {number} STATUS_NOT_SET=0 STATUS_NOT_SET value
     * @property {number} PENDING=1 PENDING value
     * @property {number} ACTIVE=2 ACTIVE value
     * @property {number} INACTIVE=3 INACTIVE value
     * @property {number} EXPIRED=4 EXPIRED value
     * @property {number} AUTHORIZED=5 AUTHORIZED value
     * @property {number} DECLINED=6 DECLINED value
     * @property {number} PAID=7 PAID value
     * @property {number} PARTIAL=8 PARTIAL value
     * @property {number} CANCELLED=9 CANCELLED value
     * @property {number} STARTED=10 STARTED value
     * @property {number} COMPLETED=11 COMPLETED value
     * @property {number} CONFIRMED=12 CONFIRMED value
     * @property {number} EN_ROUTE=13 EN_ROUTE value
     * @property {number} ON_SITE=14 ON_SITE value
     * @property {number} RESCHEDULED=15 RESCHEDULED value
     * @property {number} DELETED=16 DELETED value
     * @property {number} REFUNDED=17 REFUNDED value
     * @property {number} NO_SHOW=18 NO_SHOW value
     * @property {number} OFF_SITE=19 OFF_SITE value
     */
    odcommonpb.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATUS_NOT_SET"] = 0;
        values[valuesById[1] = "PENDING"] = 1;
        values[valuesById[2] = "ACTIVE"] = 2;
        values[valuesById[3] = "INACTIVE"] = 3;
        values[valuesById[4] = "EXPIRED"] = 4;
        values[valuesById[5] = "AUTHORIZED"] = 5;
        values[valuesById[6] = "DECLINED"] = 6;
        values[valuesById[7] = "PAID"] = 7;
        values[valuesById[8] = "PARTIAL"] = 8;
        values[valuesById[9] = "CANCELLED"] = 9;
        values[valuesById[10] = "STARTED"] = 10;
        values[valuesById[11] = "COMPLETED"] = 11;
        values[valuesById[12] = "CONFIRMED"] = 12;
        values[valuesById[13] = "EN_ROUTE"] = 13;
        values[valuesById[14] = "ON_SITE"] = 14;
        values[valuesById[15] = "RESCHEDULED"] = 15;
        values[valuesById[16] = "DELETED"] = 16;
        values[valuesById[17] = "REFUNDED"] = 17;
        values[valuesById[18] = "NO_SHOW"] = 18;
        values[valuesById[19] = "OFF_SITE"] = 19;
        return values;
    })();

    /**
     * ClientType enum.
     * @name odcommonpb.ClientType
     * @enum {number}
     * @property {number} CLIENT_TYPE_NOT_SET=0 CLIENT_TYPE_NOT_SET value
     * @property {number} CONSUMER=1 CONSUMER value
     * @property {number} EMPLOYEE=2 EMPLOYEE value
     * @property {number} CUSTOMER_SERVICE=3 CUSTOMER_SERVICE value
     * @property {number} VENDOR=4 VENDOR value
     * @property {number} CONTACT=5 CONTACT value
     */
    odcommonpb.ClientType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CLIENT_TYPE_NOT_SET"] = 0;
        values[valuesById[1] = "CONSUMER"] = 1;
        values[valuesById[2] = "EMPLOYEE"] = 2;
        values[valuesById[3] = "CUSTOMER_SERVICE"] = 3;
        values[valuesById[4] = "VENDOR"] = 4;
        values[valuesById[5] = "CONTACT"] = 5;
        return values;
    })();

    /**
     * ObjectType enum.
     * @name odcommonpb.ObjectType
     * @enum {number}
     * @property {number} OBJECT_TYPE_NOT_SET=0 OBJECT_TYPE_NOT_SET value
     * @property {number} APPOINTMENT=1 APPOINTMENT value
     * @property {number} JOB=2 JOB value
     * @property {number} INVOICE=3 INVOICE value
     * @property {number} TRAINING=4 TRAINING value
     */
    odcommonpb.ObjectType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OBJECT_TYPE_NOT_SET"] = 0;
        values[valuesById[1] = "APPOINTMENT"] = 1;
        values[valuesById[2] = "JOB"] = 2;
        values[valuesById[3] = "INVOICE"] = 3;
        values[valuesById[4] = "TRAINING"] = 4;
        return values;
    })();

    /**
     * RequestType enum.
     * @name odcommonpb.RequestType
     * @enum {number}
     * @property {number} REQUEST_TYPE_NOT_SET=0 REQUEST_TYPE_NOT_SET value
     * @property {number} REQUEST_TYPE_MOBILE=1 REQUEST_TYPE_MOBILE value
     * @property {number} REQUEST_TYPE_PHONE=2 REQUEST_TYPE_PHONE value
     * @property {number} REQUEST_TYPE_WEB=3 REQUEST_TYPE_WEB value
     * @property {number} REQUEST_TYPE_CHAT_WIDGET=5 REQUEST_TYPE_CHAT_WIDGET value
     * @property {number} REQUEST_TYPE_MOBILE_WEB=6 REQUEST_TYPE_MOBILE_WEB value
     * @property {number} REQUEST_TYPE_MOBILE_ANDROID=10 REQUEST_TYPE_MOBILE_ANDROID value
     * @property {number} REQUEST_TYPE_VENDOR_MOBILE_ANDROID=13 REQUEST_TYPE_VENDOR_MOBILE_ANDROID value
     * @property {number} REQUEST_TYPE_VENDOR_MOBILE_IPHONE=15 REQUEST_TYPE_VENDOR_MOBILE_IPHONE value
     * @property {number} REQUEST_TYPE_PARTNER_INTEGRATION=19 REQUEST_TYPE_PARTNER_INTEGRATION value
     */
    odcommonpb.RequestType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REQUEST_TYPE_NOT_SET"] = 0;
        values[valuesById[1] = "REQUEST_TYPE_MOBILE"] = 1;
        values[valuesById[2] = "REQUEST_TYPE_PHONE"] = 2;
        values[valuesById[3] = "REQUEST_TYPE_WEB"] = 3;
        values[valuesById[5] = "REQUEST_TYPE_CHAT_WIDGET"] = 5;
        values[valuesById[6] = "REQUEST_TYPE_MOBILE_WEB"] = 6;
        values[valuesById[10] = "REQUEST_TYPE_MOBILE_ANDROID"] = 10;
        values[valuesById[13] = "REQUEST_TYPE_VENDOR_MOBILE_ANDROID"] = 13;
        values[valuesById[15] = "REQUEST_TYPE_VENDOR_MOBILE_IPHONE"] = 15;
        values[valuesById[19] = "REQUEST_TYPE_PARTNER_INTEGRATION"] = 19;
        return values;
    })();

    odcommonpb.PaginationInfo = (function() {

        /**
         * Properties of a PaginationInfo.
         * @memberof odcommonpb
         * @interface IPaginationInfo
         * @property {string|null} [nextPageLink] PaginationInfo nextPageLink
         * @property {string|null} [prevPageLink] PaginationInfo prevPageLink
         * @property {number|null} [currentPage] PaginationInfo currentPage
         * @property {number|null} [totalCount] PaginationInfo totalCount
         * @property {number|null} [totalPages] PaginationInfo totalPages
         * @property {number|null} [itemsPerPage] PaginationInfo itemsPerPage
         */

        /**
         * Constructs a new PaginationInfo.
         * @memberof odcommonpb
         * @classdesc Represents a PaginationInfo.
         * @implements IPaginationInfo
         * @constructor
         * @param {odcommonpb.IPaginationInfo=} [properties] Properties to set
         */
        function PaginationInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginationInfo nextPageLink.
         * @member {string} nextPageLink
         * @memberof odcommonpb.PaginationInfo
         * @instance
         */
        PaginationInfo.prototype.nextPageLink = "";

        /**
         * PaginationInfo prevPageLink.
         * @member {string} prevPageLink
         * @memberof odcommonpb.PaginationInfo
         * @instance
         */
        PaginationInfo.prototype.prevPageLink = "";

        /**
         * PaginationInfo currentPage.
         * @member {number} currentPage
         * @memberof odcommonpb.PaginationInfo
         * @instance
         */
        PaginationInfo.prototype.currentPage = 0;

        /**
         * PaginationInfo totalCount.
         * @member {number} totalCount
         * @memberof odcommonpb.PaginationInfo
         * @instance
         */
        PaginationInfo.prototype.totalCount = 0;

        /**
         * PaginationInfo totalPages.
         * @member {number} totalPages
         * @memberof odcommonpb.PaginationInfo
         * @instance
         */
        PaginationInfo.prototype.totalPages = 0;

        /**
         * PaginationInfo itemsPerPage.
         * @member {number} itemsPerPage
         * @memberof odcommonpb.PaginationInfo
         * @instance
         */
        PaginationInfo.prototype.itemsPerPage = 0;

        /**
         * Creates a new PaginationInfo instance using the specified properties.
         * @function create
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {odcommonpb.IPaginationInfo=} [properties] Properties to set
         * @returns {odcommonpb.PaginationInfo} PaginationInfo instance
         */
        PaginationInfo.create = function create(properties) {
            return new PaginationInfo(properties);
        };

        /**
         * Encodes the specified PaginationInfo message. Does not implicitly {@link odcommonpb.PaginationInfo.verify|verify} messages.
         * @function encode
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {odcommonpb.IPaginationInfo} message PaginationInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaginationInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nextPageLink != null && Object.hasOwnProperty.call(message, "nextPageLink"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nextPageLink);
            if (message.prevPageLink != null && Object.hasOwnProperty.call(message, "prevPageLink"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.prevPageLink);
            if (message.currentPage != null && Object.hasOwnProperty.call(message, "currentPage"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currentPage);
            if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalCount);
            if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.totalPages);
            if (message.itemsPerPage != null && Object.hasOwnProperty.call(message, "itemsPerPage"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.itemsPerPage);
            return writer;
        };

        /**
         * Encodes the specified PaginationInfo message, length delimited. Does not implicitly {@link odcommonpb.PaginationInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {odcommonpb.IPaginationInfo} message PaginationInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaginationInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaginationInfo message from the specified reader or buffer.
         * @function decode
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odcommonpb.PaginationInfo} PaginationInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaginationInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odcommonpb.PaginationInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nextPageLink = reader.string();
                    break;
                case 2:
                    message.prevPageLink = reader.string();
                    break;
                case 3:
                    message.currentPage = reader.int32();
                    break;
                case 4:
                    message.totalCount = reader.int32();
                    break;
                case 5:
                    message.totalPages = reader.int32();
                    break;
                case 6:
                    message.itemsPerPage = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PaginationInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odcommonpb.PaginationInfo} PaginationInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaginationInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PaginationInfo message.
         * @function verify
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PaginationInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nextPageLink != null && message.hasOwnProperty("nextPageLink"))
                if (!$util.isString(message.nextPageLink))
                    return "nextPageLink: string expected";
            if (message.prevPageLink != null && message.hasOwnProperty("prevPageLink"))
                if (!$util.isString(message.prevPageLink))
                    return "prevPageLink: string expected";
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                if (!$util.isInteger(message.currentPage))
                    return "currentPage: integer expected";
            if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                if (!$util.isInteger(message.totalCount))
                    return "totalCount: integer expected";
            if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                if (!$util.isInteger(message.totalPages))
                    return "totalPages: integer expected";
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                if (!$util.isInteger(message.itemsPerPage))
                    return "itemsPerPage: integer expected";
            return null;
        };

        /**
         * Creates a PaginationInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odcommonpb.PaginationInfo} PaginationInfo
         */
        PaginationInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.odcommonpb.PaginationInfo)
                return object;
            let message = new $root.odcommonpb.PaginationInfo();
            if (object.nextPageLink != null)
                message.nextPageLink = String(object.nextPageLink);
            if (object.prevPageLink != null)
                message.prevPageLink = String(object.prevPageLink);
            if (object.currentPage != null)
                message.currentPage = object.currentPage | 0;
            if (object.totalCount != null)
                message.totalCount = object.totalCount | 0;
            if (object.totalPages != null)
                message.totalPages = object.totalPages | 0;
            if (object.itemsPerPage != null)
                message.itemsPerPage = object.itemsPerPage | 0;
            return message;
        };

        /**
         * Creates a plain object from a PaginationInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odcommonpb.PaginationInfo
         * @static
         * @param {odcommonpb.PaginationInfo} message PaginationInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaginationInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.nextPageLink = "";
                object.prevPageLink = "";
                object.currentPage = 0;
                object.totalCount = 0;
                object.totalPages = 0;
                object.itemsPerPage = 0;
            }
            if (message.nextPageLink != null && message.hasOwnProperty("nextPageLink"))
                object.nextPageLink = message.nextPageLink;
            if (message.prevPageLink != null && message.hasOwnProperty("prevPageLink"))
                object.prevPageLink = message.prevPageLink;
            if (message.currentPage != null && message.hasOwnProperty("currentPage"))
                object.currentPage = message.currentPage;
            if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                object.totalCount = message.totalCount;
            if (message.totalPages != null && message.hasOwnProperty("totalPages"))
                object.totalPages = message.totalPages;
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                object.itemsPerPage = message.itemsPerPage;
            return object;
        };

        /**
         * Converts this PaginationInfo to JSON.
         * @function toJSON
         * @memberof odcommonpb.PaginationInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaginationInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PaginationInfo;
    })();

    /**
     * UserType enum.
     * @name odcommonpb.UserType
     * @enum {number}
     * @property {number} USER_TYPE_NOT_SET=0 USER_TYPE_NOT_SET value
     * @property {number} USER_TYPE_CONSUMER=1 USER_TYPE_CONSUMER value
     * @property {number} USER_TYPE_PRO=2 USER_TYPE_PRO value
     * @property {number} USER_TYPE_AGENT=3 USER_TYPE_AGENT value
     * @property {number} USER_TYPE_VENDOR=4 USER_TYPE_VENDOR value
     * @property {number} USER_TYPE_CONTACT=5 USER_TYPE_CONTACT value
     */
    odcommonpb.UserType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_TYPE_NOT_SET"] = 0;
        values[valuesById[1] = "USER_TYPE_CONSUMER"] = 1;
        values[valuesById[2] = "USER_TYPE_PRO"] = 2;
        values[valuesById[3] = "USER_TYPE_AGENT"] = 3;
        values[valuesById[4] = "USER_TYPE_VENDOR"] = 4;
        values[valuesById[5] = "USER_TYPE_CONTACT"] = 5;
        return values;
    })();

    /**
     * Role enum.
     * @name odcommonpb.Role
     * @enum {number}
     * @property {number} ROLE_NOT_SET=0 ROLE_NOT_SET value
     * @property {number} GUEST=1 GUEST value
     * @property {number} PRO_TECHNICIAN=4 PRO_TECHNICIAN value
     * @property {number} PRO_DISPATCHER=5 PRO_DISPATCHER value
     * @property {number} PRO_BUSINESS_OWNER_NO_JOBS=6 PRO_BUSINESS_OWNER_NO_JOBS value
     * @property {number} PRO_BUSINESS_OWNER=7 PRO_BUSINESS_OWNER value
     * @property {number} PRO_TECHNICIAN_WITH_SCHEDULING=15 PRO_TECHNICIAN_WITH_SCHEDULING value
     */
    odcommonpb.Role = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ROLE_NOT_SET"] = 0;
        values[valuesById[1] = "GUEST"] = 1;
        values[valuesById[4] = "PRO_TECHNICIAN"] = 4;
        values[valuesById[5] = "PRO_DISPATCHER"] = 5;
        values[valuesById[6] = "PRO_BUSINESS_OWNER_NO_JOBS"] = 6;
        values[valuesById[7] = "PRO_BUSINESS_OWNER"] = 7;
        values[valuesById[15] = "PRO_TECHNICIAN_WITH_SCHEDULING"] = 15;
        return values;
    })();

    odcommonpb.User = (function() {

        /**
         * Properties of a User.
         * @memberof odcommonpb
         * @interface IUser
         * @property {odcommonpb.UserType|null} [userType] User userType
         * @property {string|null} [userId] User userId
         */

        /**
         * Constructs a new User.
         * @memberof odcommonpb
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {odcommonpb.IUser=} [properties] Properties to set
         */
        function User(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User userType.
         * @member {odcommonpb.UserType} userType
         * @memberof odcommonpb.User
         * @instance
         */
        User.prototype.userType = 0;

        /**
         * User userId.
         * @member {string} userId
         * @memberof odcommonpb.User
         * @instance
         */
        User.prototype.userId = "";

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof odcommonpb.User
         * @static
         * @param {odcommonpb.IUser=} [properties] Properties to set
         * @returns {odcommonpb.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link odcommonpb.User.verify|verify} messages.
         * @function encode
         * @memberof odcommonpb.User
         * @static
         * @param {odcommonpb.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.userType);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            return writer;
        };

        /**
         * Encodes the specified User message, length delimited. Does not implicitly {@link odcommonpb.User.verify|verify} messages.
         * @function encodeDelimited
         * @memberof odcommonpb.User
         * @static
         * @param {odcommonpb.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof odcommonpb.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {odcommonpb.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.odcommonpb.User();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userType = reader.int32();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a User message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof odcommonpb.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {odcommonpb.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a User message.
         * @function verify
         * @memberof odcommonpb.User
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        User.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userType != null && message.hasOwnProperty("userType"))
                switch (message.userType) {
                default:
                    return "userType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            return null;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof odcommonpb.User
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {odcommonpb.User} User
         */
        User.fromObject = function fromObject(object) {
            if (object instanceof $root.odcommonpb.User)
                return object;
            let message = new $root.odcommonpb.User();
            switch (object.userType) {
            case "USER_TYPE_NOT_SET":
            case 0:
                message.userType = 0;
                break;
            case "USER_TYPE_CONSUMER":
            case 1:
                message.userType = 1;
                break;
            case "USER_TYPE_PRO":
            case 2:
                message.userType = 2;
                break;
            case "USER_TYPE_AGENT":
            case 3:
                message.userType = 3;
                break;
            case "USER_TYPE_VENDOR":
            case 4:
                message.userType = 4;
                break;
            case "USER_TYPE_CONTACT":
            case 5:
                message.userType = 5;
                break;
            }
            if (object.userId != null)
                message.userId = String(object.userId);
            return message;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof odcommonpb.User
         * @static
         * @param {odcommonpb.User} message User
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userType = options.enums === String ? "USER_TYPE_NOT_SET" : 0;
                object.userId = "";
            }
            if (message.userType != null && message.hasOwnProperty("userType"))
                object.userType = options.enums === String ? $root.odcommonpb.UserType[message.userType] : message.userType;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            return object;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof odcommonpb.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return User;
    })();

    /**
     * SortOrder enum.
     * @name odcommonpb.SortOrder
     * @enum {number}
     * @property {number} DESCENDING=0 DESCENDING value
     * @property {number} ASCENDING=1 ASCENDING value
     */
    odcommonpb.SortOrder = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DESCENDING"] = 0;
        values[valuesById[1] = "ASCENDING"] = 1;
        return values;
    })();

    /**
     * CommunicationType enum.
     * @name odcommonpb.CommunicationType
     * @enum {number}
     * @property {number} NO_COMMUNICATION=0 NO_COMMUNICATION value
     * @property {number} USE_DEFAULT_COMMUNICATION_METHODS=1 USE_DEFAULT_COMMUNICATION_METHODS value
     */
    odcommonpb.CommunicationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NO_COMMUNICATION"] = 0;
        values[valuesById[1] = "USE_DEFAULT_COMMUNICATION_METHODS"] = 1;
        return values;
    })();

    return odcommonpb;
})();

export { $root as default };
