import { getMarket, getServiceType } from '../zesty';

export interface ITealiumBaseDataValue {
    funnel_design_type: string;
    proconnect_market_id: string;
    proconnect_service_type_id: string;
    customer_zip: string;
    page_targeted_location: string;
    page_targeted_service: string;
    proconnect_book_flow_type: string;
}

interface ITealiumBaseDataParams {
    serviceTypeId: string;
    marketId: string;
    zipCode: string;
    defaultZipCode: string;
}

export async function getTealiumBaseData({
    serviceTypeId,
    marketId,
    zipCode,
    defaultZipCode,
}: ITealiumBaseDataParams) {
    const serviceType = await getServiceType(serviceTypeId);
    const market = await getMarket(marketId);

    return {
        funnel_design_type: 'React',
        proconnect_market_id: marketId || '999',
        proconnect_service_type_id: serviceTypeId || '999',
        customer_zip: zipCode || 'None',
        page_targeted_location: market?.name || 'No Location Preselected',
        page_targeted_service: serviceType?.name || 'No Service Preselected',
        proconnect_book_flow_type: defaultZipCode
            ? 'Zip code preselected'
            : 'No Location Preselected',
    };
}
