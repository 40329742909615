import { GetGuestToken } from '@ftdr/crypto-js';
import { util, Writer } from 'protobufjs';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';

type Param = string | number | null;

interface IQueryParams {
    [key: string]: string | URLSearchParams | undefined;
}

interface IProtoRequest<TReq> {
    request: TReq;
    protoRequestModel: IProtoReqModel<TReq> | null | undefined;
}

interface IProtoReqModel<TReq> {
    verify: (payload: TReq) => string | null;
    create: (payload: TReq) => TReq;
    encode: (message: TReq, writer?: Writer) => Writer;
}

interface IProtoResponse<TRes> {
    response: ArrayBuffer;
    responseModel: IProtoResModel<TRes> | null;
}

interface IProtoResModel<TRes> {
    decode: (reader: Uint8Array, length?: number) => TRes;
}

interface IHttpReqOptions<TReq> {
    method: string;
    payload: TReq | null;
    header: Record<string, unknown>;
    useProto?: boolean;
    protoRequestModel?: IProtoReqModel<TReq> | null;
}

interface IBaseServiceFactory<TReq, TRes> {
    endpoint: string;
    method?: 'GET' | 'POST';
    protoRequestModel?: IProtoReqModel<TReq> | null;
    protoResponseModel?: IProtoResModel<TRes> | null;
    useProto?: boolean;
}

interface IBaseServiceParams<TReq> {
    payload?: TReq | null;
    query?: IQueryParams;
    param?: Param | null;
}

function getUrlQuery(query: IQueryParams) {
    if (Object.keys(query).length === 0) {
        return '';
    }
    return `?${new URLSearchParams(
        query as unknown as URLSearchParams,
    ).toString()}`;
}

function getUrlParam(param: Param) {
    return param ? `/${param}` : '';
}

function getToken() {
    return new Promise((resolve, reject) => {
        try {
            GetGuestToken({
                handler: (token) => resolve(token),
            });
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    offender: 'getToken',
                },
            });
            return reject(error);
        }
    });
}

async function getHeader(options = {}) {
    let token: string | unknown = await Cookies.get('x_auth');

    if (!token) {
        await getToken()
            .then((tokn) => {
                token = tokn;
                Cookies.set('x_auth', token as string, {
                    expires: new Date(new Date().getTime() + 178 * 60 * 1000), //2 minutes before 3hrs expiry
                });
            })
            .catch(() => {
                throw new Error('auth token not found!');
            });
    }

    return {
        Authorization: `Bearer ${token}`,
        ...options,
    };
}

export function getLegacyHeader(userLegacyId?: string) {
    const token = Cookies.get('x_leg_auth');
    const options = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    if (userLegacyId && token) {
        return {
            ...options,
            'Authorization': `${userLegacyId}:${token}`,
            'X-Sz-Usr': 'employee',
        };
    }
    return options;
}

function encodeProtoRequest<TReq>({
    request,
    protoRequestModel,
}: IProtoRequest<TReq>) {
    const errMsg = protoRequestModel?.verify(request);
    if (errMsg) {
        throw Error(errMsg);
    }

    if (protoRequestModel) {
        const message = protoRequestModel.create(request);
        return message && protoRequestModel.encode(message)?.finish();
    }
}

function decodeProtoResponse<TRes>({
    response,
    responseModel,
}: IProtoResponse<TRes>) {
    return responseModel?.decode(new Uint8Array(response));
}

function getProtoHeader() {
    return {
        'Content-Type': 'application/x-protobuf',
        'responseType': 'arraybuffer',
    };
}

function getDefaultCType() {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };
}

export function getHttpOptions<TReq>({
    method,
    payload,
    header,
    useProto,
    protoRequestModel,
}: IHttpReqOptions<TReq>) {
    return {
        method,
        headers: {
            ...(useProto ? getProtoHeader() : getDefaultCType()),
            ...header,
        },
        ...(payload && method !== 'GET'
            ? {
                  body: useProto
                      ? encodeProtoRequest({
                            request: payload,
                            protoRequestModel: protoRequestModel,
                        })
                      : JSON.stringify(payload),
              }
            : {}),
    };
}

export function getProtoBase64Value<TReq>(
    payload: TReq,
    protoRequestModel: IProtoReqModel<TReq>,
) {
    const arrayBuffer = encodeProtoRequest({
        request: payload,
        protoRequestModel: protoRequestModel,
    });
    return util.base64
        .encode(arrayBuffer as Uint8Array, 0, arrayBuffer?.length as number)
        .replace(/=+$/, '');
}

export async function makeHttpRequest<TRes, TReq>({
    endpoint,
    method = 'GET',
    protoRequestModel = null,
    protoResponseModel = null,
    payload = null,
    param = null,
    query = {},
    useProto,
}: IBaseServiceFactory<TReq, TRes> & IBaseServiceParams<TReq>) {
    useProto = useProto || (!!protoRequestModel && !!protoResponseModel);

    const url = `${endpoint}${getUrlParam(param)}${getUrlQuery(query)}`;
    const reqOptions = {
        method,
        payload,
        header: await getHeader(),
        useProto,
        protoRequestModel,
    };
    const response = await fetch(url, getHttpOptions({ ...reqOptions }));

    if (!response.ok) {
        // TODO: Add logging
        const error = useProto
            ? decodeProtoResponse({
                  response: await response.arrayBuffer(),
                  responseModel: protoResponseModel,
              })
            : response.statusText;

        throw error || '';
    }

    if (useProto) {
        return decodeProtoResponse({
            response: await response.arrayBuffer(),
            responseModel: protoResponseModel,
        });
    }
    const responseText = await response.text();
    const data = responseText ? JSON.parse(responseText) : {};
    return data as TRes;
}

export function getBaseServiceFactory<TRes, TReq = unknown>({
    endpoint,
    method = 'GET',
    protoRequestModel = null,
    protoResponseModel = null,
    useProto,
}: IBaseServiceFactory<TReq, TRes>) {
    if (!endpoint) {
        throw new Error('an endpoint must be provided!');
    }

    return async function ({
        payload = null,
        param = null,
        query = {},
    }: IBaseServiceParams<TReq> = {}) {
        return makeHttpRequest({
            endpoint,
            method,
            protoRequestModel,
            protoResponseModel,
            payload,
            param,
            query,
            useProto,
        });
    };
}
