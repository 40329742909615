import { getBaseServiceFactory } from '../base';
import { endpoints } from '../../shared/constants';
import * as Sentry from '@sentry/nextjs';

export const geoLocationBaseService = getBaseServiceFactory({
    method: 'GET',
    endpoint: endpoints.geoLocation,
});

export async function getGeoLocation() {
    return new Promise<any>((resolve, reject) => {
        geoLocationBaseService()
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    extra: {
                        offender: 'getGeoLocation',
                    },
                });
                return reject(err);
            });
    });
}
