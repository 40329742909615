import { GlobalFooterLinksModuleProps } from '@ftdr/blueprint-components-react';
import { IFooterLink } from '../../interfaces/footer';
import { privacyPolicyLink } from '../../shared/constants/legal';

export const FALLBACK_FOOTER_LINKS: IFooterLink[] = [
    {
        order: 1,
        linkText: 'How It Works',
        link: '/how-it-works-los-angeles/hw/1',
    },
    {
        order: 2,
        linkText: 'Proconnect Guarantee',
        link: '/proconnect-guarantee/',
    },
    {
        order: 3,
        linkText: 'FAQ',
        link: '/faq',
    },
    {
        order: 4,
        linkText: 'Membership Agreement',
        link: '/membership-agreement/',
    },
    {
        order: 5,
        linkText: 'Prices',
        link: '/prices',
    },
    {
        order: 6,
        linkText: 'Join Our Pro Network',
        link: '/apply',
    },
];

export const footerComplianceLinks: GlobalFooterLinksModuleProps['links'] = [
    {
        children: 'Privacy Policy',
        href: privacyPolicyLink,
        color: 'primary',
        underline: 'hover',
        external: true,
        rel: 'nofollow',
    },
    {
        children: 'Terms of Use',
        href: 'https://www.frontdoor.com/legal/terms/',
        color: 'primary',
        underline: 'hover',
        external: true,
        rel: 'nofollow',
    },
];
