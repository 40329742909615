import Head from 'next/head';
import { IHead } from '../../interfaces/shared-props';
import getConfig from 'next/config';

export function NextHead({
    pageTitle,
    pageDescription = 'American Home Shield ProConnect™ offers FLAT RATE services, including Plumbing, Air Conditioning, Electrical, Appliance Repair, Carpet Cleaning, Handyman, and more. We offer same day online appointments and our ProConnect Guarantee.  Now serving the Greater Los Angeles area.',
    isFTDRTheme,
    titleLeft = false,
    noHyphen,
    shouldIndexPage,
}: IHead) {
    const {
        publicRuntimeConfig: { NODE_ENV_VALUE },
    } = getConfig();

    return (
        <Head>
            {isFTDRTheme ? (
                <title>{pageTitle}</title>
            ) : titleLeft ? (
                <title>
                    {pageTitle}
                    {noHyphen ? ' ' : ' - '}Frontdoor Pro
                </title>
            ) : (
                <title>
                    Frontdoor Pro{noHyphen ? ' ' : ' - '}
                    {pageTitle}
                </title>
            )}

            <meta name="description" content={pageDescription} />
            <meta name="handheldfriendly" content="true" />
            <meta name="mobileoptimized" content="240" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
            />
            {(!shouldIndexPage || NODE_ENV_VALUE !== 'production') && (
                <meta name="robots" content="noindex,nofollow" />
            )}
            <link
                rel="icon"
                href={isFTDRTheme ? '/favicon_FTDR.ico' : '/favicon.ico'}
            />
        </Head>
    );
}
