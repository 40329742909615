export const FTDRLogo2 = ({ color }: { color: string }) => (
    <svg
        width="30px"
        height="43px"
        viewBox="0 0 30 43"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>1C37FE05-6C23-4B6D-9B70-125165004693@2x</title>
        <g
            id="Homepage"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="(New)"
                transform="translate(-265.000000, -3790.000000)"
                fill={color}
                fillRule="nonzero"
            >
                <g id="Group-7" transform="translate(0.000000, 3125.000000)">
                    <g
                        id="FD-pro-vertical-orange-rgb"
                        transform="translate(265.000000, 665.000000)"
                    >
                        <path
                            d="M21.8226467,39.1869031 C18.9481583,39.1869031 16.6603001,36.840382 16.6603001,34.0245566 C16.6603001,31.1500682 19.0068213,28.8622101 21.8226467,28.8622101 C24.6971351,28.8622101 26.9849932,31.2087312 26.9849932,34.0245566 C27.0436562,36.840382 24.6971351,39.1869031 21.8226467,39.1869031 Z M21.8226467,31.4433834 C20.414734,31.4433834 19.2414734,32.6166439 19.2414734,34.0245566 C19.2414734,35.4324693 20.414734,36.6057299 21.8226467,36.6057299 C23.2305593,36.6057299 24.4038199,35.4324693 24.4038199,34.0245566 C24.4038199,32.5579809 23.2892224,31.4433834 21.8226467,31.4433834 Z"
                            id="fullLogo_00000173139857951594255070000009196190034421189766_"
                        ></path>
                        <path
                            d="M13.9618008,38.952251 L11.3806276,38.952251 L11.3806276,34.0245566 C11.3806276,31.1500682 13.7271487,28.8622101 16.5429741,28.8622101 L16.5429741,31.4433834 C15.1350614,31.4433834 13.9618008,32.6166439 13.9618008,34.0245566 L13.9618008,38.952251 L13.9618008,38.952251 Z"
                            id="fullLogo_00000039851982260059472290000006682104789772458130_"
                        ></path>
                        <path
                            d="M0,43 L2.58117326,43 L2.58117326,38.4829468 C3.34379263,38.893588 4.22373806,39.1869031 5.16234652,39.1869031 C8.03683492,39.1869031 10.324693,36.840382 10.324693,34.0245566 C10.324693,31.1500682 7.9781719,28.8622101 5.16234652,28.8622101 C2.40518417,28.8622101 0.117326057,31.0327422 0,33.7899045 L0,33.7899045 L0,43 L0,43 Z M5.22100955,31.4433834 C6.62892224,31.4433834 7.80218281,32.6166439 7.80218281,34.0245566 C7.80218281,35.4324693 6.62892224,36.6057299 5.22100955,36.6057299 C3.81309686,36.6057299 2.63983629,35.4911323 2.63983629,34.0832196 L2.63983629,34.0245566 C2.63983629,32.5579809 3.81309686,31.4433834 5.22100955,31.4433834 Z"
                            id="fullLogo_00000106118525293530996930000000604455646574519476_"
                        ></path>
                        <g id="Group">
                            <path
                                d="M26.5156889,25.8703956 L19.8281037,25.8703956 L19.8281037,13.2578445 C19.8281037,9.6207367 16.8949523,6.68758527 13.2578445,6.68758527 C9.6207367,6.68758527 6.68758527,9.6207367 6.68758527,13.2578445 L6.68758527,25.8703956 L0,25.8703956 L0,13.2578445 C0,5.98362892 5.92496589,0 13.2578445,0 C20.53206,0 26.5156889,5.92496589 26.5156889,13.2578445 L26.5156889,25.8703956 Z"
                                id="fullLogo_8_"
                            ></path>
                            <path
                                d="M17.9508868,17.3055935 C17.9508868,18.478854 17.0122783,19.4174625 15.8390177,19.4174625 C14.6657572,19.4174625 13.7271487,18.478854 13.7271487,17.3055935 C13.7271487,16.1323329 14.6657572,15.1937244 15.8390177,15.1937244 C17.0122783,15.1937244 17.9508868,16.1323329 17.9508868,17.3055935 Z"
                                id="fullLogo_7_"
                            ></path>
                            <path
                                d="M27.9236016,25.2409902 L27.9236016,25.2409902 C27.9236016,24.7586184 28.3370632,24.3451569 28.819435,24.3451569 C29.3018068,24.3451569 29.7152683,24.7586184 29.7152683,25.2409902 L29.7152683,25.2409902 C29.7152683,25.723362 29.3018068,26.1368236 28.819435,26.1368236 C28.3370632,26.1368236 27.9236016,25.723362 27.9236016,25.2409902 Z M29.5774478,25.2409902 L29.5774478,25.2409902 C29.5774478,24.8275287 29.2328965,24.4829774 28.819435,24.4829774 C28.4059734,24.4829774 28.0614221,24.8275287 28.0614221,25.2409902 L28.0614221,25.2409902 C28.0614221,25.6544518 28.4059734,25.999003 28.819435,25.999003 C29.2328965,26.0679133 29.5774478,25.6544518 29.5774478,25.2409902 Z M28.4748837,24.8275287 L28.8883452,24.8275287 C29.095076,24.8275287 29.2328965,24.8964389 29.2328965,25.1031697 C29.2328965,25.2409902 29.1639863,25.3099005 29.0261657,25.3788107 L29.2328965,25.723362 L29.0261657,25.723362 L28.819435,25.447721 L28.6127042,25.447721 L28.6127042,25.723362 L28.4748837,25.723362 L28.4748837,24.8275287 Z M28.8883452,25.2409902 C29.0261657,25.2409902 29.095076,25.17208 29.095076,25.1031697 C29.095076,25.0342595 29.0261657,24.9653492 28.8883452,24.9653492 L28.6816145,24.9653492 L28.6816145,25.2409902 L28.8883452,25.2409902 L28.8883452,25.2409902 Z"
                                id="Shape"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
