import { endpoints, LOCAL_STORAGE_KEYS } from '../../shared/constants';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';

export async function authenticateHashClient(hash: string) {
    const payload = {
        jsonrpc: '2.0',
        method: 'authenticateHashLink',
        id: '1',
        params: [hash],
    };

    try {
        const response = await fetch(endpoints.auth.authenticateHash, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
        const responseData = await response.json();

        const data = responseData?.result?.data;
        const userId = data?.userObj?.id as string;
        localStorage.setItem(LOCAL_STORAGE_KEYS.legacyUserId, userId);

        const token = data?.userObj?.token as string;
        const tokenLifetime = parseInt(
            data?.hashLinkObj?.hashLinkTypeObj.tokenLifetime,
            10,
        );
        if (token && tokenLifetime) {
            Cookies.set('x_leg_auth', token, {
                expires: new Date(
                    new Date().getTime() + (tokenLifetime - 120) * 1000,
                ), //2 minutes before expiry
            });
        }

        return responseData;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'authenticateHash',
            },
        });
    }
}
