import { getBaseServiceFactory, getProtoBase64Value } from '../base';
import { addresspb } from '../../protobuf/models/addresspb';
import { endpoints } from '../../shared/constants';
import * as Sentry from '@sentry/nextjs';

export interface IAddressReqPayload {
    street1: string;
    unitValue: string;
    city: string;
    zip: string;
    countryIso3: string;
}

export const addressBaseService = getBaseServiceFactory({
    endpoint: endpoints.address,
    protoRequestModel: addresspb.AddressRequest,
    protoResponseModel: addresspb.AddressResponse,
});

export async function getAddress(payload: IAddressReqPayload) {
    const base64Params = getProtoBase64Value(payload, addresspb.AddressRequest);

    return new Promise<addresspb.IAddress | null | undefined>(
        (resolve, reject) => {
            addressBaseService({
                query: {
                    proto_body: base64Params,
                },
            })
                .then((response) => {
                    resolve(response?.address);
                })
                .catch((err) => {
                    Sentry.captureException(err, {
                        extra: {
                            offender: 'getAddress',
                        },
                    });
                    return reject(err);
                });
        },
    );
}
