import {
    BookingPropertiesType,
    IBookingError,
    IBookingStepErrorFields,
} from '../../interfaces/shared';
import { EMAIL_PATTERN, PHONE_PATTERN, ZIPCODE_PATTERN } from '../constants';

export interface IBookingFormField<T extends BookingPropertiesType> {
    name: keyof IBookingStepErrorFields<T>;
    value: string;
}

interface IValidationRule {
    isEmpty: boolean;
    isCorrectPattern: boolean;
}

export function getBookingFieldValidationError<T extends BookingPropertiesType>(
    field: IBookingFormField<T>,
): IBookingError | null {
    const { name, value } = field;
    const isEmpty = value.trim().length === 0;
    const isCorrectPattern = !!checkPatterns(field);

    const rules = getValidationRules({
        isEmpty,
        isCorrectPattern,
    });

    const fieldName = name as unknown as keyof typeof rules;
    const ruleSets = rules[fieldName];

    // If the field sent does not have a ruleSet defined in here
    if (!ruleSets) {
        return null;
    }

    for (const ruleSet of ruleSets) {
        if (ruleSet.rule) {
            return ruleSet.error;
        }
    }

    return null;
}

export function getMultipleFieldBookingError<T extends BookingPropertiesType>(
    errorFields: IBookingStepErrorFields<T>,
) {
    const multipleErrorsMsg = hasRequiredErrorsOnly(errorFields)
        ? 'Please complete all required fields.'
        : 'Please correct all required fields.';

    return getBookingError(multipleErrorsMsg, 'unspecified');
}

/**
 * Validates only against string and number properties of your model
 */
export const getBookingFieldsValidationResult = <
    T extends BookingPropertiesType,
>(
    model: T,
) => {
    const errorFields = {} as IBookingStepErrorFields<T>;

    for (const field in model) {
        if (!['string', 'number'].includes(typeof model[field])) {
            continue;
        }

        const fieldWithType = field as keyof T;
        const error = getBookingFieldValidationError({
            name: fieldWithType,
            value: String(model[fieldWithType]),
        });

        if (error) {
            errorFields[fieldWithType] = error;
        }
    }

    const hasMultipleErrors =
        errorFields && Object.keys(errorFields).length >= 2;
    if (hasMultipleErrors) {
        errorFields.globalError = getMultipleFieldBookingError(errorFields);
    }

    const hasErrors = Object.keys(errorFields).length >= 1;

    return {
        shouldProceed: !hasErrors,
        errorFields: hasErrors ? errorFields : null,
    };
};

export function hasRequiredErrorsOnly<T extends BookingPropertiesType>(
    errorFields: IBookingStepErrorFields<T>,
) {
    return !(
        errorFields &&
        Object.keys(errorFields).some(
            (key) =>
                errorFields[key as keyof IBookingStepErrorFields<T>] &&
                errorFields[key as keyof IBookingStepErrorFields<T>]
                    .errorType !== 'required',
        )
    );
}

function getValidationRules({ isEmpty, isCorrectPattern }: IValidationRule) {
    const rules = {
        // address
        streetAddress: [getRuleSet(isEmpty, 'Please enter a street address')],
        city: [getRuleSet(isEmpty, 'Please enter a city')],
        zipCode: [
            getRuleSet(isEmpty, 'Please enter a zip code'),
            getRuleSet(
                !isCorrectPattern,
                'Please enter a valid zip code',
                'unspecified',
            ),
        ],
        // contact info
        firstName: [getRuleSet(isEmpty, 'Please enter a first name')],
        lastName: [getRuleSet(isEmpty, 'Please enter a last name')],
        email: [
            getRuleSet(isEmpty, 'Please enter an email address'),
            getRuleSet(
                !isCorrectPattern,
                'Please enter a valid email address',
                'unspecified',
            ),
        ],
        phone: [
            getRuleSet(isEmpty, 'Please enter a phone number'),
            getRuleSet(
                !isCorrectPattern,
                'Please enter a valid phone number',
                'unspecified',
            ),
        ],
    };

    return rules;
}

function checkPatterns<T extends BookingPropertiesType>(
    field: IBookingFormField<T>,
) {
    const { name, value } = field;
    switch (name) {
        case 'email':
            EMAIL_PATTERN.lastIndex = 0;
            return EMAIL_PATTERN.test(value.trim());
        case 'phone':
            PHONE_PATTERN.lastIndex = 0;
            return PHONE_PATTERN.test(value.replace(/\D/g, ''));
        case 'zipCode':
            ZIPCODE_PATTERN.lastIndex = 0;
            return ZIPCODE_PATTERN.test(value.trim());
        default:
            break;
    }
}

function getBookingError(
    errorMsg: string,
    errorType: IBookingError['errorType'] = 'required',
) {
    return {
        errorType,
        errorMsg,
    } as IBookingError;
}

function getRuleSet(
    rule: boolean,
    message: string,
    errorType?: IBookingError['errorType'],
) {
    return {
        rule,
        error: getBookingError(message, errorType),
    };
}
