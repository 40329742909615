import { getDay, isSameDay, isWithinInterval, set, subSeconds } from 'date-fns';
import { IWorkingHours } from '../../interfaces/zesty';
import { DAYS_INDICES } from '../../shared/constants';
import { DayOfWeek } from '../../interfaces/shared';

export const isWithinWorkingHours = ({
    date,
    workingHours,
}: {
    date: Date;
    workingHours: IWorkingHours;
}) => {
    const { normalHours, specialHours } = workingHours;

    const specialDateHours = specialHours.find((x) =>
        isSameDay(new Date(`${x.date}T00:30:00`), date),
    );
    if (specialDateHours) {
        const isClosedAllDay =
            !specialDateHours.hours.trim() ||
            !specialDateHours.hours.includes('-');
        if (isClosedAllDay) {
            return false;
        }

        const specialHoursArray = specialDateHours.hours.split('-');
        const openTime = specialHoursArray[0];
        const closeTime = specialHoursArray[1];

        const closeDateTimeMinusASecond = subSeconds(
            new Date(`${specialDateHours.date}T${closeTime}`),
            1,
        );

        return isWithinInterval(date, {
            start: new Date(`${specialDateHours.date}T${openTime}`),
            end: closeDateTimeMinusASecond,
        });
    }

    const dayOfWeek = DAYS_INDICES[getDay(date)] as DayOfWeek;
    const openCloseTime = normalHours[dayOfWeek];

    const isClosedAllDay =
        !openCloseTime || !openCloseTime.trim() || !openCloseTime.includes('-');
    if (isClosedAllDay) {
        return false;
    }

    const openCloseTimeArray = openCloseTime.split('-');
    const openTime = openCloseTimeArray[0];
    const closeTime = openCloseTimeArray[1];

    const openTimeArray = openTime.split(':');
    const closeTimeArray = closeTime.split(':');
    const openDateTime = set(date, {
        hours: Number(openTimeArray[0]),
        minutes: Number(openTimeArray[1]),
        seconds: 0,
    });
    const closeDateTime = set(date, {
        hours: Number(closeTimeArray[0]),
        minutes: Number(closeTimeArray[1]),
        seconds: 0,
    });

    const closeDateTimeMinusASecond = subSeconds(closeDateTime, 1);

    return isWithinInterval(date, {
        start: openDateTime,
        end: closeDateTimeMinusASecond,
    });
};
